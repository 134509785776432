import React, {useEffect} from 'react';
import {useFirebase} from 'lincd-notify/lib/hooks/useFirebase';
import {useSnackbar} from '../../hooks/snackbar';
import {isIOS} from '../../utils/helper';
import {Container} from '../ui/forms/Container';

// import Navigation from './Navigation';

export function ContentContainer({children}: React.PropsWithChildren) {
  const {notification} = useFirebase();
  const snackbar = useSnackbar();

  /**
   * open snackbar toast when have a new notification
   * and also disable snackbar for IOS because conflict with the native notification
   */
  useEffect(() => {
    if (notification && !isIOS()) {
      snackbar.openSnackbar({
        title: notification.title,
        message: notification.body,
        type: 'info',
        position: 'top-center',
        duration: 10000,
        customStyle: 'gradient',
      });
    }
  }, [notification]);

  return (
    //<Container>
    <>
      <Container>{children}</Container>
    </>

    // <div className={style.ContentRoot}>
    //   <div className={`${style.SafeArea} ${style['top']}`}></div>
    //   <div className={style.Content + ' ' + pageClassFromCssModule}>
    //     <Container maxWidth={'sm'}>{children}</Container>
    //   </div>
    //   <div className={`${style.SafeArea} ${style['bottom']}`}></div>
    // </div>
  );
}
