import React, {useEffect, useRef, useState} from 'react';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {packageName} from '../package';
import style from './Coupons.scss.json';
import './Coupons.scss';
import {Offer} from 'lincd-schema/lib/shapes/Offer';
import {Button} from '../components/ui/forms/Button';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import {Order} from 'lincd-schema/lib/shapes/Order';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../routes/AppRoutes';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Person} from 'profile-plus/lib/shapes/Person';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {PaidAccountTier1} from 'lincd-dating/lib/shapes/PaidAccountTier1';

interface PlatformCounterState {
  platformCounts: {
    [platform: string]: {
      total: number;
      couponRedeemed: {
        [coupon: string]: number;
      };
    };
  };
  couponCounts: {
    [coupon: string]: number;
  };
}
export default function Coupon() {
  const [couponCode, setCouponCode] = useState<string>('');
  const [prefixCouponCode, setPrefixCouponCode] = useState<string>('');
  const [couponSelected, setCouponSelected] = useState('');
  const [promotionCode, setPromotionCode] = useState([]);
  const [platformSelected, setPlatformSelected] = useState('');
  const [allCoupons, setAllCoupons] = useState<any>([]);
  const [couponID, setCouponID] = useState<any>('');
  const [orderName, setOrderName] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // pagigation
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(promotionCode.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, promotionCode.length);
  const currentItems = promotionCode.slice(startIndex, endIndex);

  const navigate = useNavigate();
  const auth = useAuth<Person, FreeAccount | PaidAccountTier1>();
  const userAccount = auth.userAccount;

  // check the account email is ADMIN_EMAIL
  if (process.env.ADMIN_EMAIL !== userAccount?.email) {
    navigate(ROUTES.index.path);
  }

  const changePage = (page) => {
    setCurrentPage(page);
  };

  // const [platformCounter, setPlatformCounter] = useState({
  //   platformCounts: {
  //     web: {
  //       total: 0,
  //       couponRedeemed: {},
  //     },
  //     ios: {
  //       total: 0,
  //       couponRedeemed: {},
  //     },
  //     android: {
  //       total: 0,
  //       couponRedeemed: {},
  //     },
  //   },
  //   couponCounts: {},
  // });
  const [filterSelected, setFilterSelected] = useState('');

  useEffect(() => {
    Order.getAllOrders().then((orders) => {
      Server.call(packageName, 'getAllUserPurchase', orders).then((result) => {
        if (result) {
          setOrderName(result);
        }
      });
    });
  }, []);

  // fetch all promotion codes
  const listAllPromotionCodes = async () => {
    try {
      const result = await Server.call(packageName, 'listAllPromotionCodes');
      if (result) {
        // Update the state with the sorted result
        setPromotionCode(result);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  // retrieve all promotion codes
  useEffect(() => {
    listAllPromotionCodes();
  }, []);

  // trigger promotionCode state after adding a new promotion code
  // to make sure promotionCode state is updated and added to the table list
  // currently just log for now
  useEffect(() => {
    console.log('update promotion code', promotionCode.length);
  }, [promotionCode]);

  // Calculate coupon counts
  const couponCounts = countCouponOrders(orderName, promotionCode);

  // Calculate platform counts
  const platformCounter = countOrdersByPlatform(
    orderName,
    platformSelected,
    couponSelected,
  );

  // // Update platformCounter state
  // setPlatformCounter({
  //   platformCounts: platforms.platformCounts,
  //   couponCounts: platforms.couponCounts,
  // });
  function formatDate(dateString) {
    // Create a Date object from the provided dateString
    const date = new Date(dateString);

    // Define arrays for days and months
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    // Get the day, date, and month from the Date object
    const day = days[date.getDay()];
    const dateNum = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Get the hour and minute from the Date object
    const hour = date.getHours();
    const minute = date.getMinutes();

    // Format hour and minute to ensure they have leading zeros if needed
    const formattedHour = hour < 10 ? `0${hour}` : hour;
    const formattedMinute = minute < 10 ? `0${minute}` : minute;

    // Concatenate the formatted date string with time
    const formattedDate = `${day}, ${dateNum} ${month} ${year} ${formattedHour}:${formattedMinute}`;

    // Return the formatted date string
    return formattedDate;
  }

  function countCouponOrders(purchases, promotionCode) {
    const couponCounts = {};
    const redeemCounts = {};
    // Loop through each purchase object in the array
    purchases.forEach((purchase) => {
      // Loop through each order object in the purchase's order array
      purchase.order.forEach((item) => {
        // Get the couponID of the current order
        const couponID = item.couponID;
        if (promotionCode.some((promo) => promo.identifier === couponID)) {
          redeemCounts[couponID] = (redeemCounts[couponID] || 0) + 1;
        }
        if (couponCounts[couponID]) {
          couponCounts[couponID]++;
        } else {
          // If the couponID doesn't exist in the couponCounts object, initialize its count to 1
          couponCounts[couponID] = 1;
        }
      });
    });

    // Return the object containing the count of each coupon ID
    return {couponCounts, redeemCounts};
  }

  function countOrdersByPlatform(purchases, platformSelected, couponSelected) {
    const platformCounts = {
      web: {
        total: 0,
        couponRedeemed: {},
      },
      ios: {
        total: 0,
        couponRedeemed: {},
      },
      android: {
        total: 0,
        couponRedeemed: {},
      },
    };
    const couponCounts = {};

    // Loop through each purchase object in the array
    purchases.forEach((purchase) => {
      // Loop through each order object in the purchase's order array
      purchase.order.forEach((item) => {
        // Get the platform of the current order
        const platform = item.platform;
        // Increment the total count for the respective platform
        platformCounts[platform].total++;

        // Increment the count for the respective coupon redeemed
        const couponID = item.couponID || 'regular';
        platformCounts[platform].couponRedeemed[couponID] =
          (platformCounts[platform].couponRedeemed[couponID] || 0) + 1;

        // Increment the count for the respective coupon redeemed globally
        couponCounts[couponID] = (couponCounts[couponID] || 0) + 1;
      });
    });

    // Return the object containing the count of orders for each platform and coupon
    return {platformCounts, couponCounts};
  }

  const copyLink = (couponCode: string) => {
    navigator.clipboard.writeText(couponCode);
  };

  const saveCouponToDB = async (prefix: string) => {
    let code = new Offer();
    code.identifier = prefix;
    code.description = 'Promo Code ' + prefix;
    code.validThrough = new Date();
    code.save();

    return code;
  };

  const generateCoupon = async (prefix: string) => {
    setIsLoading(true);

    try {
      if (regex.test(prefix)) {
        console.log(`generate coupon code: ${prefix}`);
        setCouponCode(prefix);

        const newPromoCode = await saveCouponToDB(prefix);

        if (!newPromoCode) {
          console.error('Failed to save coupon to database', prefix);
          alert('Failed to save coupon to database');
        }

        // add new promo code to the promotionCode state
        const updatePromoCode = [newPromoCode, ...promotionCode];
        if (updatePromoCode) {
          setPromotionCode(updatePromoCode);
        }

        // TODO: disable for now because allCoupons is not used anywhere
        // setAllCoupons((prevCoupons) => [
        //   ...prevCoupons,
        //   {code: prefix, times_redeemed: 0},
        // ]);
        setSuccess(true);
        setPrefixCouponCode('');

        // remove success message after 5 seconds
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
      } else {
        console.error(
          'The coupon code can only contain letters and numbers. Please remove any special characters or spaces.',
        );
        alert(
          'The coupon code can only contain letters and numbers. Please remove any special characters or spaces.',
        );
      }
    } catch (error) {
      console.error('An error occurred:', error);
      alert('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const MailCode = (partnerName: string, uniqueCouponCode: string) => {
    const emailSubject =
      'Welcome to the Spiritual Playmates Affiliate Program!';
    const emailBody = `
        Dear ${partnerName},

        We are thrilled to welcome you to the Spiritual Playmates Affiliate Program! Your partnership is invaluable to us, and we are excited to embark on this journey together.

        To get started, we have assigned you an exclusive affiliate coupon code: ${uniqueCouponCode}. This code is special to you and can be shared with your audience. When new users sign up at sp.social/app using your coupon code they will receive their first month free. It's a wonderful opportunity to explore our spiritual community without any commitment.

        Here’s how it works:
        1. Share your unique coupon code with your audience.
        2. Direct them to download the app at: sp.social/app.
        3. Have them use your coupon code when signing up. 
        4. They will get one month free. 

        For every individual who subscribes using your coupon code, you will earn $5. These earnings are paid out quarterly, ensuring a steady return for your efforts.

        We believe that Spiritual Playmates can offer a unique and enriching experience to everyone, and your role in spreading this message is crucial. Please feel free to reach out if you have any questions or need further information.

        Thank you for joining us in our mission to create a more spiritually connected world.

        Warm regards,
        Spiritual Playmates Team`;

    const mailtoLink = `mailto:example@example.com?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
    window.open(mailtoLink);
  };

  const regex = /^[a-zA-Z0-9]+$/;

  function Capitalize(str) {
    // Check if str is not undefined and is a string
    if (typeof str === 'string') {
      if (str.length > 0) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      } else {
        return str; // Return the empty string as is
      }
    } else {
      return ''; // Return empty string if str is undefined or not a string
    }
  }

  return (
    <div className={style.CouponContainer}>
      <div className={style.headerDashboard}>
        <div className={style.headerLogo}>
          <img src={asset('/images/sp_logo.svg')} alt="logo" />
          <h2>Spiritual Playmates</h2>
        </div>
        <div className={style.menuHeader}>
          <h3 onClick={() => navigate(ROUTES.index.path)}>Home</h3>
          <h3 onClick={() => navigate(ROUTES.coupons.path)}>Coupon</h3>
        </div>
      </div>
      <div className={style.CouponInner}>
        <h2>Coupon Code Generator</h2>
        <div className={style.codeInput}>
          <TextField
            value={prefixCouponCode}
            placeholder="Prefix Coupon"
            helperText="Only letters uppercase and numbers are allowed. Example: SP2022, MAYOFF50"
            onChange={(e) => {
              // remove any special characters or spaces when generating a coupon code
              const value = e.target.value
                .replace(/[^a-zA-Z0-9]/g, '')
                .toUpperCase();
              setPrefixCouponCode(value);
            }}
          />
          {!isLoading ? (
            <Button onClick={() => generateCoupon(prefixCouponCode)}>
              Generate Coupon
            </Button>
          ) : (
            <Button>... Loading</Button>
          )}
        </div>
        {success ? (
          <div className={style.successCoupon}>
            <img src={asset('/images/checked.png')} />
            <p>Generate Coupon Success</p>
          </div>
        ) : null}

        <div className={style.listCoupon}>
          <h2>List Coupon Code</h2>
          <table>
            <thead>
              <tr>
                <th>Code</th>
                <th>Redemptions</th>
                <th>Created at</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((coupon, index) => (
                <tr
                  key={coupon.identifier}
                  className={index % 2 === 0 ? style.odd : ''}
                >
                  <td>{coupon.identifier}</td>
                  <td>{couponCounts.redeemCounts[coupon.identifier] || 0}</td>
                  <td>{formatDate(coupon.validThrough)}</td>
                  <td>
                    <Button
                      onClick={() => MailCode('denix', coupon.identifier)}
                    >
                      <img src={asset('/images/email.png')} width={24} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={style.pagination}>
            <Button
              onClick={() => changePage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <span>{`Page ${currentPage} of ${totalPages}`}</span>
            <Button
              onClick={() => changePage(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
        </div>
        <table>
          <h2>Coupon Code Redemption</h2>
          <div className={style.filtered}>
            <FormControl className={style.formTabel} fullWidth>
              <InputLabel>Filter</InputLabel>
              <Select
                value={filterSelected || ''} // Guard against null or undefined by providing a fallback value
                onChange={(e) => setFilterSelected(e.target.value)}
              >
                <MenuItem value={'platform'}>Platform</MenuItem>
                <MenuItem value={'coupon'}>Coupon</MenuItem>
              </Select>
            </FormControl>
            {filterSelected === 'platform' && platformSelected !== undefined ? (
              <>
                <FormControl className={style.formTabel} fullWidth>
                  <InputLabel>O/S</InputLabel>
                  <Select
                    autoWidth={true}
                    value={platformSelected}
                    onChange={(e) => setPlatformSelected(e.target.value)}
                  >
                    <MenuItem value={'android'}>Android</MenuItem>
                    <MenuItem value={'ios'}>iOS</MenuItem>
                    <MenuItem value={'web'}>Web</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={style.formTabel} fullWidth>
                  <InputLabel>Coupon</InputLabel>
                  <Select
                    autoWidth={true}
                    value={couponSelected}
                    onChange={(e) => setCouponSelected(e.target.value)}
                  >
                    {couponCounts && Object.keys(couponCounts).length > 0 ? (
                      // Render coupon ID options if couponCounts exists and has keys
                      Object.keys(couponCounts.couponCounts).map((couponID) => (
                        <MenuItem key={couponID} value={couponID}>
                          {couponID}
                        </MenuItem>
                      ))
                    ) : (
                      // Render a default option when couponCounts is not ready or empty
                      <MenuItem value="">No Coupons Available</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </>
            ) : (
              <FormControl className={style.formTabel} fullWidth>
                <InputLabel>Coupon</InputLabel>
                <Select
                  autoWidth={true}
                  value={couponSelected}
                  onChange={(e) => setCouponSelected(e.target.value)}
                >
                  {couponCounts &&
                  Object.keys(couponCounts.couponCounts).length > 0 ? (
                    // Render coupon ID options if couponCounts exists and has keys
                    Object.keys(couponCounts.couponCounts).map((couponID) => (
                      <MenuItem key={couponID} value={couponID}>
                        {couponID}
                      </MenuItem>
                    ))
                  ) : (
                    // Render a default option when couponCounts is not ready or empty
                    <MenuItem value="">No Coupons Available</MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
            {filterSelected && (
              <div className={style.resultFilter}>
                <div className={style.resultSelected}>
                  <h4>Total:</h4>
                  {filterSelected === 'platform' && couponSelected ? (
                    <h4>
                      {platformCounter?.platformCounts[platformSelected]
                        ?.couponRedeemed[couponSelected] || 0}
                    </h4>
                  ) : (
                    <h4>{couponCounts.couponCounts[couponSelected]}</h4>
                  )}
                </div>
              </div>
            )}
          </div>
          <tr>
            <th>Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Platform</th>
            <th>Coupon Code</th>
            <th>Date of Purchase</th>
          </tr>
          {orderName?.length > 0 ? (
            orderName.map((purchase, index) => (
              <React.Fragment key={index}>
                {purchase.order.map((order, subIndex) => (
                  <tr
                    key={subIndex}
                    className={index % 2 === 0 ? style.odd : ''}
                  >
                    <td>{Capitalize(order.customer?.givenName)}</td>
                    <td>{Capitalize(order.customer?.familyName)}</td>
                    <td>{purchase.user?.email}</td>
                    <td style={{textAlign: 'center'}}>
                      {order.platform === 'android' ? (
                        <img src={asset('/images/android.png')} alt="Android" />
                      ) : order.platform === 'web' ? (
                        <img src={asset('/images/web.png')} alt="Web" />
                      ) : order.platform === 'ios' ? (
                        <img src={asset('/images/apple-logo.png')} alt="iOS" />
                      ) : null}
                    </td>
                    <td>{order.couponID}</td>
                    <td>{formatDate(order.date)}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td style={{textAlign: 'center'}} colSpan={6}>
                No data
              </td>
            </tr>
          )}
        </table>
      </div>
    </div>
  );
}
