import {Geolocation} from '@capacitor/geolocation';
import {LocationUpdateAction} from 'lincd-capacitor/lib/shapes/LocationUpdateAction';

/**
 * request permission for get the latest position
 * and then update to the user location
 * TODO: later will be moved to lincd-capacitor
 */
export const getAndUpdateUserLocation = async () => {
  try {
    const coordinates = await Geolocation.getCurrentPosition();
    if (coordinates) {
      try {
        const {latitude, longitude} = coordinates.coords;
        //this method decidedly does not wait for the server response
        LocationUpdateAction.updateUserLocation(latitude, longitude);
        //so that it can return the coordinates immediately
        return coordinates;
      } catch (err) {
        console.error('update location error:', err);
        return false;
      }
    }
  } catch (err) {
    console.warn('request permission error:', err);
    return false;
  }
};

//NOTE: this method throws an error when used on web, but the method above does request permissions on web
// export const requestLocationPermission = async () => {
//   return Geolocation.requestPermissions();
// };
