import React, {useEffect, useState} from 'react';
import {Select} from './Select';
import {months, timeOfDay} from '../../../constants';
import {QuestionSubheader} from '../../QuestionSubheader';
import './DateTimeBirthSelect.scss';
import {default as style} from './DateTimeBirthSelect.scss.json';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {FormControlLabel} from '@mui/material';
import {addTimezone, removeTimezone} from '../../../utils/DateTime';
import CustomizedSwitch from './CustomizedSwitch';

interface DateTimeBirthSelectProps {
  of?: Person | FreeAccount;
  property?: string[];
  maxYear?: number;
  onChange?: () => void;
}

let dateStart = 1;
let dateEnd = 31;
let dateArr = [];
let currentYear = new Date().getFullYear();
let hourStart = 1;
let hourEnd = 12;
let hourArr = [];
let minStart = 0;
let minEnd = 59;
let minArr = [];

while (dateStart < dateEnd + 1) {
  dateArr.push(dateStart++);
}
while (hourStart < hourEnd + 1) {
  hourArr.push(hourStart++);
}
while (minStart < minEnd + 1) {
  minArr.push(minStart++);
}
const doubleDigit = (number) => {
  return (number < 10 ? '0' : '') + number;
};
hourArr = hourArr.map(doubleDigit);
minArr = minArr.map(doubleDigit);

const DateTimeBirthSelect = ({
  of,
  property,
  maxYear = new Date().getFullYear(),
  onChange,
}: DateTimeBirthSelectProps) => {
  let currentValue = of[property[0]];
  if (currentValue) {
    currentValue = addTimezone(currentValue);
  }
  const [birthDate, setBirthDate] = useState({
    date: currentValue?.getDate(),
    month: currentValue ? months[currentValue.getMonth()] : undefined,
    year: currentValue?.getFullYear(),
  });

  // let yearStart = maxYear;
  let yearArr = [];
  for (let i = maxYear; i >= 1900; i--) {
    yearArr.push(i);
  }
  // while (yearStart < currentYear + 1) {
  //   yearArr.push(yearStart++);
  // }
  // yearArr.sort(function (a, b) {
  //   return b - a;
  // });

  const currentValueNotKnowTime = of[property[1]];
  let [doesNotKnowTime, setDoesNotKnowTime] = useState(currentValueNotKnowTime);

  const [birthTime, setBirthTime] = useState(
    currentValueNotKnowTime
      ? {hour: undefined, minute: undefined, timeOfDay: undefined}
      : {
          hour: currentValue
            ? currentValue.getHours() % 12 === 0
              ? '12'
              : doubleDigit(currentValue?.getHours() % 12)
            : undefined,
          minute: currentValue
            ? doubleDigit(currentValue.getMinutes())
            : undefined,
          timeOfDay: currentValue
            ? currentValue.getHours() > 12
              ? 'PM'
              : 'AM'
            : undefined,
        },
  );

  const onDoesNotKnowTime = (e) => {
    setDoesNotKnowTime(e.currentTarget.checked);
    of[property[1]] = e.currentTarget.checked;
    //if the user checks the box then we need to remove the time from the date in case it was already set.
    let dateValue = of[property[0]];
    if (e.currentTarget.checked && dateValue) {
      let dateOnly = dateValue.toISOString().split('T')[0];
      let withoutTime = new Date(dateOnly + 'T00:00+00:00');
      of[property[0]] = withoutTime;
      setBirthTime({
        hour: undefined,
        minute: undefined,
        timeOfDay: undefined,
      });
    }
  };

  useEffect(() => {
    handleDatetime();
  }, [birthDate, birthTime]);

  const handleDatetime = () => {
    const {date, month, year} = birthDate;
    const {minute, hour, timeOfDay} = birthTime;

    let newDate: Date;
    if (
      typeof date !== 'undefined' &&
      typeof month !== 'undefined' &&
      typeof year !== 'undefined'
    ) {
      if (
        typeof hour !== 'undefined' &&
        typeof minute !== 'undefined' &&
        typeof timeOfDay !== 'undefined'
      ) {
        newDate = new Date(
          `${date} ${month} ${year} ${hour}:${minute} ${timeOfDay}`,
        );
      } else {
        newDate = new Date(`${date} ${month} ${year}`);
      }
      //if the date was valid
      if (newDate instanceof Date && !isNaN(newDate as any)) {
        //if the value changed
        if (
          !currentValue ||
          currentValue.toISOString() !== newDate.toISOString()
        ) {
          //update it
          let withoutTimezone = removeTimezone(newDate);
          of[property[0]] = withoutTimezone;
          // of[property[0]] = newDate;
        }
      }
    }
  };

  const handleBirthDate = (value, name) => {
    const data = {
      ...birthDate,
      [name]: value,
    };
    setBirthDate(data);
  };

  const handleBirthTime = (value, name) => {
    const data = {
      ...birthTime,
      [name]: value,
    };
    setBirthTime(data);
  };

  return (
    <div>
      <div className={style.MultiSelectContainer}>
        <div>
          <QuestionSubheader>Date</QuestionSubheader>
          <Select
            options={dateArr}
            value={birthDate.date}
            onChange={(value) => handleBirthDate(value, 'date')}
          />
        </div>
        <div>
          <QuestionSubheader>Month</QuestionSubheader>
          <Select
            options={months}
            value={birthDate.month}
            onChange={(value) => handleBirthDate(value, 'month')}
          />
        </div>
        <div>
          <QuestionSubheader>Year</QuestionSubheader>
          <Select
            options={yearArr}
            value={birthDate.year}
            onChange={(value) => handleBirthDate(value, 'year')}
          />
        </div>
        <div>
          <QuestionSubheader>Hours</QuestionSubheader>
          <Select
            options={hourArr}
            value={birthTime.hour}
            onChange={(value) => handleBirthTime(value, 'hour')}
            disabled={doesNotKnowTime}
          />
        </div>
        <div>
          <QuestionSubheader>Minutes</QuestionSubheader>
          <Select
            options={minArr}
            value={birthTime.minute}
            onChange={(value) => handleBirthTime(value, 'minute')}
            disabled={doesNotKnowTime}
          />
        </div>
        <div>
          <QuestionSubheader>AM/PM</QuestionSubheader>
          <Select
            options={timeOfDay}
            value={birthTime.timeOfDay}
            onChange={(value) => handleBirthTime(value, 'timeOfDay')}
            disabled={doesNotKnowTime}
          />
        </div>
      </div>
      <FormControlLabel
        control={
          <CustomizedSwitch
            onChange={onDoesNotKnowTime}
            checked={doesNotKnowTime}
          />
        }
        label="I do not know the time"
      />
    </div>
  );
};

export default DateTimeBirthSelect;
