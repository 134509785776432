/**
 * Table Account Component, mostly currently using on Admin Page
 */
import React, {useEffect, useState} from 'react';
import './TableAccount.scss';
import style from './TableAccount.scss.json';
import {generatePath, Link} from 'react-router-dom';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {cl} from 'lincd/lib/utils/ClassNames';
import {ROUTES} from '../routes/AppRoutes';
import {packageName} from '../package';
import {Avatar} from 'profile-plus/lib/components/Avatar';
import {getAccountSlug} from '../utils/helper';
import {Icons} from './ui/Icons';

interface TableAccountProps {
  data: any[];
  onSelectionChange?: (accounts: any[]) => void;
  onClearSelection?: () => void; // clear all selected accounts
}
export const TableAccount = ({
  data,
  onSelectionChange,
  onClearSelection,
}: TableAccountProps) => {
  const [selectedAccounts, setSelectedAccounts] = useState<any[]>([]);

  useEffect(() => {
    if (onClearSelection) {
      setSelectedAccounts([]);
    }
  }, [onClearSelection]);

  // go to user detail page with new tab
  const onUserDetail = async (accountUri: string) => {
    const userPath = generatePath(ROUTES.user.path, {accountUri});
    window.open(userPath, '_blank');
  };

  const onDeleteUser = async (user) => {
    const {id, teams} = user;
    const teamIds = teams.map((team) => team.identifier);

    try {
      await Server.call(packageName, 'deleteUser', id, teamIds);
      window.location.reload();
    } catch (error) {
      console.error('failed to delete user:', error);
    }
  };

  const onSelectAccount = (account: any) => {
    // if no onSelectionChange callback, return
    // for the table can't selected
    if (!onSelectionChange) {
      return;
    }

    // toggle selected account
    setSelectedAccounts((prev) => {
      let updateAccount: any[] = [];
      if (prev.includes(account)) {
        updateAccount = prev.filter((item) => item.id !== account.id);
      } else {
        if (prev.length >= 2) {
          alert('You can only select 2 accounts');
          return prev;
        }
        updateAccount = [...prev, account];
      }

      // send selected accounts to parent component
      onSelectionChange(updateAccount);

      return updateAccount;
    });
  };

  return (
    <table className={style.usersTable}>
      <thead>
        <tr>
          <th>Account ID</th>
          <th>Account URI</th>
          <th>Person ID</th>
          {/* <th>Person URI</th> */}
          <th>Given Name</th>
          <th>Family Name</th>
          <th>Email</th>
          <th>Paid Account</th>
          <th>Complete Profile</th>
          <th>Likes</th>
          <th>Dislike</th>
          <th>Matches</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data?.length === 0 && (
          <tr>
            <td colSpan={10}>No data</td>
          </tr>
        )}
        {data?.map((user: any, i) => {
          const isSelected = selectedAccounts.includes(user);
          const selectedIndex = selectedAccounts.indexOf(user);
          const topOffset = isSelected
            ? `${(selectedIndex + 1) * 41}px`
            : 'auto';

          // uri data
          const data = 'https://app.spiritualplaymates.com/data/';
          const getSlug = getAccountSlug(user.accountUri);

          // generate user detail page link
          let link: string;
          if (user.id) {
            link = generatePath(ROUTES.user.path, {
              accountUri: getSlug,
            });
          }

          return (
            <tr
              key={i}
              className={cl(
                selectedAccounts.includes(user) ? style.selected : '',
                onSelectionChange ? style.select : '',
              )}
              onClick={() => onSelectAccount(user)}
              style={{
                top: topOffset,
              }}
            >
              <td>
                <Link to={link ? link : '#'} target="_blank">
                  {user.id}
                </Link>
              </td>
              <td>{user.accountUri?.replace(data, '')}</td>
              <td>{user.identifier}</td>
              {/* <td>{user.personUri?.replace(data, '')}</td> */}
              <td>{user.givenName}</td>
              <td>{user.familyName}</td>
              <td>{user.email}</td>
              <td>{user.isPaidAccount ? 'Yes' : ''}</td>
              <td>{user.isCompletedProfile ? 'Yes' : ''}</td>
              <td>{user.likes !== 0 ? user.likes : ''}</td>
              <td>{user.dislike !== 0 ? user.dislike : ''}</td>
              <td>{user.matches !== 0 ? user.matches : ''}</td>
              <td>
                <div className={style.btnGroup}>
                  <button
                    aria-label="Detail"
                    onClick={() => {
                      onUserDetail(getSlug);
                    }}
                  >
                    <Icons.Eye />
                  </button>
                  {/* <button
                    disabled
                    className={style.btnDelete}
                    aria-label="Delete"
                    onClick={() => onDeleteUser(user)}
                  >
                    <Icons.Trash />
                  </button> */}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
