import React from 'react';
import {useNavigate} from 'react-router-dom';
import RadioChip from '../components/RadioChip';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Person} from 'lincd-dating/lib/shapes/Person';
import './Q6_LookingFor.scss';
import {default as style} from './Q6_LookingFor.scss.json';
import ContinueButton from '../components/ContinueButton';
import {ROUTES} from '../routes/AppRoutes';
import {QuestionContainer} from '../components/QuestionContainer';
import {QuestionHeader} from '../components/QuestionHeader';
import {QuestionSubheader} from '../components/QuestionSubheader';
import {interestedInGenderValues} from '../constants';
import {AgeSlider} from '../components/AgeSlider';

/**
 * LookingFor page is for mode dating
 */
const Q6_LookingFor = () => {
  const navigate = useNavigate();

  let auth = useAuth<Person, FreeAccount>();
  let user = auth.user;

  //interested-in is same like gender preference
  const setGenderPreference = (genderPref: string) => {
    user.interestedInGenderDating = genderPref;
  };

  const onContinue = () => {
    if (user.relationshipType === 'both') {
      navigate(ROUTES.q6_looking_for_friendship.path);
    } else {
      navigate(ROUTES.q7_relationship_goal.path);
    }
  };

  return (
    //all of these options belong to dating shapes
    <QuestionContainer>
      <QuestionHeader>
        Who are you interested in meeting for a date?
      </QuestionHeader>
      {/*<FormControlLabel */}
      {/*    control={<CustomizedSwitch checked={isChecked} onChange={()=>setChecked(!isChecked)} className={style.lookingForContainer}*/}
      {/*  />}*/}
      {/*    label={`Interested in meeting everyone`} */}
      {/*  />*/}
      <div>
        <QuestionSubheader>Gender</QuestionSubheader>
        <RadioChip
          currentValue={user.interestedInGenderDating}
          values={interestedInGenderValues}
          onChange={setGenderPreference}
        />
      </div>
      <div>
        <QuestionSubheader>Ages</QuestionSubheader>
        <AgeSlider className={style.sliderContainer} mode={'dating'} />
      </div>
      <ContinueButton
        // withBackground={true}
        // text={'You can always update this later. We got you.'}
        onClick={onContinue}
      />
    </QuestionContainer>
  );
};

export default Q6_LookingFor;
