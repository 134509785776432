import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
// import './Q11_PhoneNumber.scss';
import {QuestionContainer} from '../components/QuestionContainer';
import {QuestionHeader} from '../components/QuestionHeader';
import {QuestionSubheader} from '../components/QuestionSubheader';
import {useWatchProperty} from 'lincd/lib/utils/Hooks';
import {ROUTES} from '../routes/AppRoutes';
import {Button} from '../components/ui/forms/Button';
import {Stack} from '../components/ui/forms/Stack';
import {FacebookEvents} from 'capacitor-facebook-events';

const SetupComplete = () => {
  //const [phone, setPhone] = React.useState('')

  const navigate = useNavigate();
  const auth = useAuth<Person, FreeAccount>();
  const user = auth.user;

  const onFinish = () => {
    onEventLogFacebook();

    auth.userAccount.profileSetupCompleted = true;
    navigate(ROUTES.index.path);
  };
  const onMoreQuestions = () => {
    onEventLogFacebook();

    auth.userAccount.profileSetupCompleted = true;
    navigate(ROUTES.edit_profile.path + '#values-and-beliefs');
  };

  // log event when user complete profile setup
  const onEventLogFacebook = () => {
    FacebookEvents.logEvent({
      event: 'complete_registration',
      params: {
        profile_setup_complete: 1,
      },
    });
  };

  useWatchProperty(user, 'homeLocation');
  return (
    <>
      <QuestionContainer>
        <QuestionHeader>Basic profile complete!</QuestionHeader>
        <QuestionSubheader>
          We now have enough information to get started, but you can fill in
          more details for better matches.
        </QuestionSubheader>
        <br />
        <Stack gap={1}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={onMoreQuestions}
          >
            Fill in more nuanced questions
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={onFinish}
          >
            Start matching
          </Button>
        </Stack>
      </QuestionContainer>
    </>
  );
};

export default SetupComplete;
