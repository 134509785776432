import {Button} from '../components/ui/forms/Button';
import {Stack} from '../components/ui/forms/Stack';
import {Typography} from '../components/ui/forms/Typography';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Link, useNavigate} from 'react-router-dom';
import PageLayout from '../components/layout/PageLayout';
import React, {useEffect, useState} from 'react';
import './Signin.scss';
import style from './Signin.scss.json';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '../components/Divider';
import {ROUTES} from '../routes/AppRoutes';
import {Capacitor} from '@capacitor/core';
import {
  SignInWithAppleOptions,
  SignInWithAppleResponse,
} from '@capacitor-community/apple-sign-in';
import {SigninWithPasswordForm} from 'lincd-auth/lib/components/SigninWithPasswordForm';
import {SigninWithGoogleButton} from 'lincd-capacitor/lib/components/SigninWithGoogleButton';
import {SigninWithFacebookButton} from 'lincd-capacitor/lib/components/SigninWithFacebookButton';
import {FacebookEvents} from 'capacitor-facebook-events';

const Signin = () => {
  const navigate = useNavigate();
  const [loadingPage, setLoadingPage] = useState(false);
  const [appleSignInModule, setAppleSignInModule] = useState(null);
  const [loggingIn, setLoggingIn] = useState<boolean>(false);

  const auth = useAuth();

  // check if user is already logged in
  // and redirect to index page
  useEffect(() => {
    const validateToken = async () => {
      setLoadingPage(true);
      const isValid = await auth.validateToken();

      if (isValid) {
        navigate(ROUTES.index.path);
      } else {
        navigate(ROUTES.signin.path);
        setLoadingPage(false);
      }
    };

    if (auth.userAccount) {
      navigate(ROUTES.index.path);
    } else {
      validateToken();
    }
  }, []);

  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      // Dynamically import the module inside useEffect
      import('@capacitor-community/apple-sign-in')
        .then((module) => {
          setAppleSignInModule(module);
        })
        .catch((error) => {
          console.error('Error importing module:', error);
        });
    }
  }, []);

  const signInApple = () => {
    if (appleSignInModule) {
      const {SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse} =
        appleSignInModule;

      const options: SignInWithAppleOptions = {
        clientId: process.env.APP_ID,
        redirectURI: '',
        scopes: 'name, email',
        // state: '12345',
        nonce: 'nonce',
      };

      SignInWithApple.authorize(options)
        .then((result: SignInWithAppleResponse) => {
          // Handle user information
          if (result && result.response && result.response.identityToken) {
            setLoggingIn(true);
            auth
              .signinOAuth('apple', result.response)
              .then((response) => {
                FacebookEvents.logEvent({
                  event: 'complete_registration',
                  params: {
                    registration_method: 'apple',
                  },
                });
                navigate(ROUTES.index.path);
              })
              .catch((err) => {
                console.log('error signing in with apple in signinOauth:', err);
              })
              .finally(() => {
                setLoggingIn(false);
              });
          }
          // Validate token with server and create new session
        })
        .catch((error) => {
          // Handle error
          console.log('apple login error', error);
        });
    }
  };

  const onLoggedIn = () => {
    navigate(ROUTES.index.path);
  };

  const onForgotPassword = () => {
    console.log('forgot password clicked');
  };

  // if (auth.userAccount) {
  //   return null;
  // }

  return (
    <>
      <PageLayout>
        <div className={style.signinBox}>
          {loadingPage ? (
            <CircularProgress />
          ) : (
            <>
              <div className={style.signinBox2}>
                <div className={style.signinHead}>
                  <div className={style.splogo} />
                  <Typography variant="h6" className={style.titleText}>
                    Spiritual Playmates
                  </Typography>
                </div>
                <div className={style.oathss}>
                  <Typography variant="h5" className={style.subtitleText}>
                    Let&apos;s log you in
                  </Typography>
                  <SigninWithPasswordForm
                    onCreateAccount={() => navigate(ROUTES.signup.path)}
                    onLoggedIn={() => {
                      FacebookEvents.logEvent({
                        event: 'complete_registration',
                        params: {
                          registration_method: 'password',
                        },
                      });
                      onLoggedIn();
                    }}
                    onForgotPassword={onForgotPassword}
                    showEmailIcon={false}
                    buttonClassName={style.emailButton}
                  />

                  <Divider text="OR" className={style.divider} />
                  <Stack className={style.buttonGroup}>
                    <SigninWithGoogleButton
                      label="Login with Google"
                      className={style.oauthButton}
                      onCallback={() => {
                        FacebookEvents.logEvent({
                          event: 'complete_registration',
                          params: {
                            registration_method: 'google',
                          },
                        });
                        onLoggedIn();
                      }}
                    />
                    <SigninWithFacebookButton
                      label={'Login with Facebook'}
                      className={style.oauthButton}
                      onCallback={() => {
                        FacebookEvents.logEvent({
                          event: 'complete_registration',
                          params: {
                            registration_method: 'facebook',
                          },
                        });
                        onLoggedIn();
                      }}
                    />
                    {Capacitor.getPlatform() === 'ios' && (
                      <Button
                        variant="outlined"
                        onClick={signInApple}
                        startIcon={
                          <div
                            className={style.oauthLogo + ' ' + style.logoApple}
                          />
                        }
                      >
                        Login with Apple
                      </Button>
                    )}
                  </Stack>
                  <div className={style.bottomText}>
                    By logging in, you agree to our{' '}
                    <Link to={'/terms'}>Terms of Service</Link> and{' '}
                    <Link to={'/privacy-policy'}>Privacy policy</Link>.<br />
                    <Link to={'/contact'}>
                      <div className={style.contactLink}>
                        <div>Contact support</div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </PageLayout>
    </>
  );
};

export default Signin;
