import React from 'react';
import {default as MuiChip} from '@mui/material/Chip';
import './Chip.scss';
import {default as style} from './Chip.scss.json';
import {useStyles} from 'lincd/lib/utils/Hooks';

interface ChipProps extends React.ComponentProps<typeof MuiChip> {
  noHoverEffect?: boolean;
}

export function Chip({noHoverEffect, ...props}: ChipProps) {
  props = useStyles(props, [noHoverEffect && style.no_hover_effect]);
  return <MuiChip {...props}></MuiChip>;
}
