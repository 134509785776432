import React, {useCallback, useEffect, useRef, useState} from 'react';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {AdministrativeArea} from 'lincd-schema/lib/shapes/AdministrativeArea';
import {PlacePreview} from '../../../PlacePreview';
import {useWatchProperty} from 'lincd/lib/utils/Hooks';
import {ShapeValuesSet} from 'lincd/lib/collections/ShapeValuesSet';
import {TextField} from '../TextField';
import {List} from '../List';
import {ListItem} from '../ListItem';
import {ListItemText} from '../ListItemText';
import './LocationInput.scss';
import style from './LocationInput.scss.json';

const OPENSTREETMAP_API = 'https://nominatim.openstreetmap.org/search';

interface SearchResult {
  place_id: string;
  display_name: string;
  latitude?: string;
  longitude?: string;
}

interface LocationInputProps {
  of: Person | FreeAccount;
  property: string;
  multiple?: boolean;
  maxCount?: number;
}

const LocationInput = ({
  of,
  property,
  multiple,
  maxCount,
}: LocationInputProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debounceSearch, setDebounceSearch] = useState('');
  const [results, setResults] = useState<SearchResult[]>([]);
  const [showResults, setShowResults] = useState(false);

  let listRef = useRef(null);
  let inputRef = useRef(null);
  const [showNewLocationInput, setShowNewLocationInput] = useState(true);

  let currentValue: AdministrativeArea;
  let currentValues: ShapeValuesSet<AdministrativeArea>;
  if (multiple) {
    currentValues = of[property] as ShapeValuesSet<AdministrativeArea>;
  } else {
    currentValue = of[property] as AdministrativeArea;
  }

  //temporary trick to force update based on changed internal graph state
  useWatchProperty(of, property);

  const onClickWindow = (e) => {
    //if the user did not click on the list or the inputfield, close the list of results
    if (
      (!listRef.current || !listRef.current.contains(e.target)) &&
      inputRef.current &&
      !inputRef.current.contains(e.target)
    ) {
      setShowResults(false);
    }
  };
  useEffect(() => {
    window.addEventListener('click', onClickWindow);
    return () => {
      window.removeEventListener('click', onClickWindow);
    };
  }, []);

  useEffect(() => {
    // add debounce time for delaying users typing for better performances
    const timeout = setTimeout(() => {
      setDebounceSearch(searchTerm);
    }, 800);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchTerm]);

  useEffect(() => {
    // fetch the Open Street Map API
    const fetchResults = async () => {
      if (debounceSearch.length >= 3) {
        const response = await fetch(
          `${OPENSTREETMAP_API}?q=${debounceSearch}&format=json`,
        );
        const data = await response.json();
        setResults(data);
      } else {
        setResults([]);
      }
    };

    // if the user is editing, it will fetch the search results from the OpenStreetMap API
    if (showResults) {
      fetchResults();
    }
  }, [debounceSearch, showResults]);

  // set value from input field
  const handleSearchTermChange = useCallback((event) => {
    const val = event.target.value;
    setSearchTerm(val);
  }, []);

  // handle user when choose the search result from Open Street Map
  const handleSelect = useCallback((result) => {
    const {place_id, display_name, lat, lon} = result;

    // save place
    let place = AdministrativeArea.getFromURI(
      `${process.env.DATA_ROOT}/place/${place_id}`,
    );
    place.name = display_name;
    place.identifier = place_id.toString();
    place.latitude = lat;
    place.longitude = lon;
    place.save();

    //update the property value by adding a value or overwriting the value
    if (multiple) {
      currentValues?.add(place);
    } else {
      of[property] = place;
    }

    // clear input and result data after select options
    setSearchTerm('');
    setResults([]);
  }, []);

  const handleRemoveLocation = (place: AdministrativeArea) => {
    if (multiple) {
      currentValues?.delete(place);
    } else {
      of[property] = null;
    }
  };

  // set isEdit to true, when user focus on input field
  const handleFocus = useCallback(() => {
    setShowResults(true);
  }, []);

  return (
    <div className={style.LocationInput}>
      <Stack>
        {showNewLocationInput && (multiple || !currentValue) && (
          <FormControl fullWidth ref={inputRef}>
            <TextField
              placeholder={'City, State/Province'}
              value={searchTerm}
              onChange={handleSearchTermChange}
              onFocus={handleFocus}
              disabled={
                multiple &&
                typeof maxCount !== 'undefined' &&
                currentValues?.size >= maxCount
              }
            />
            {showResults && results.length > 0 && (
              <div className={style.locationResult} ref={listRef}>
                <List dense={true}>
                  {results.slice(0, 5).map((result: SearchResult) => (
                    <ListItem
                      key={result.place_id}
                      className={style.listItem}
                      onClick={() => handleSelect(result)}
                    >
                      <ListItemText>{result.display_name}</ListItemText>
                    </ListItem>
                  ))}
                </List>
              </div>
            )}
          </FormControl>
        )}

        {/* show icon to add a new location when multiple values are allowed and selectedLocations value is not null or empty and the number of values does not exceed the maximum */}
        {multiple &&
          !showNewLocationInput &&
          currentValues?.size > 0 &&
          typeof maxCount !== 'undefined' &&
          currentValues?.size < maxCount && (
            <div className={style.addIconContainer}>
              <IconButton onClick={() => setShowNewLocationInput(true)}>
                <AddCircleOutline />
              </IconButton>
            </div>
          )}

        {/* list location from based from user select */}

        <List dense className={style.list}>
          {multiple &&
            currentValues?.map((location: AdministrativeArea) => {
              return (
                <PlacePreview
                  key={location.toString()}
                  of={location}
                  onRemove={handleRemoveLocation}
                />
              );
            })}
          {!multiple && currentValue && (
            <PlacePreview
              key={currentValue.toString()}
              of={currentValue}
              onRemove={handleRemoveLocation}
            />
          )}
        </List>
      </Stack>
    </div>
  );
};

export default LocationInput;
