import React from 'react';
import './TextField.scss';
import {default as style} from './TextField.scss.json';
// import { default as MuiTextField } from '@mui/material/TextField/TextField';
import {useStyles} from 'lincd/lib/utils/Hooks';
import {Input as InputUnstyled} from '@mui/base/Input';

export const TextField = React.forwardRef(function (
  {
    error,
    helperText,
    ...props
  }: React.ComponentPropsWithoutRef<typeof InputUnstyled> & {
    error?: boolean;
    helperText?: string;
  },
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  props = useStyles(props, [style.TextField, error ? style.error : '']);
  return (
    <>
      <InputUnstyled {...props} ref={ref}></InputUnstyled>
      {helperText && <p className={style.helperText}>{helperText}</p>}
    </>
  );
});
