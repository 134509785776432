import React, {ReactNode, useEffect, useState} from 'react';
import './CountdownTimer.scss';
import style from './CountdownTimer.scss.json';
import {cl} from 'lincd/lib/utils/ClassNames';

interface CountdownProps {
  startIcon?: ReactNode;
  deadline: Date;
  className?: string;
  onFinish?: () => void;
}

const CountdownTimer = ({
  deadline,
  startIcon,
  className,
  onFinish,
}: CountdownProps) => {
  const countDownDate = new Date(deadline).getTime();
  const [countdown, setCountdown] = useState(
    countDownDate - new Date().getTime(),
  );

  let hours = Math.floor(
    (countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  let minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = Math.floor((countdown % (1000 * 60)) / 1000);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(countDownDate - new Date().getTime());
    }, 1000);

    // trigger callback
    if (hours + minutes + seconds <= 0 && onFinish) {
      onFinish();
    }

    return () => {
      clearInterval(interval);
    };
  }, [countdown]);

  const addLeadingZero = (value) => {
    return value < 10 ? '0' + value : value;
  };

  return (
    <>
      {/* {countdown > 0 && ( */}
      <div className={cl(style.container, className)}>
        {startIcon}
        {addLeadingZero(hours)}:{addLeadingZero(minutes)}:
        {addLeadingZero(seconds)}
      </div>
      {/* )} */}
    </>
  );
};

export default CountdownTimer;
