import * as React from 'react';
import './Button.scss';
import {default as style} from './Button.scss.json';
import {ButtonProps as BaseButtonProps} from '@mui/base/Button';
import {useStyles} from 'lincd/lib/utils/Hooks';
import {useButton} from '@mui/base/useButton';
import {cl} from 'lincd/lib/utils/ClassNames';
import Spinner from '../../Spinner';

interface ButtonProps extends BaseButtonProps {
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'primary' | 'secondary'; // adjust the type of color if needed.
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  id?;
}

export const Button = React.forwardRef(function CustomButton(
  props: ButtonProps,
  ref: React.ForwardedRef<any>,
) {
  props = useStyles(props, style.ButtonRoot);

  const {
    children,
    disabled,
    variant = 'outlined',
    color,
    fullWidth = false,
    startIcon,
    endIcon,
    id,
  } = props;
  const {active, focusVisible, getRootProps} = useButton({
    ...props,
    rootRef: ref,
  });

  return (
    <button
      {...getRootProps()}
      id={id}
      className={cl(
        props.className,
        style[variant],
        color === 'primary' && style['primary'],
        color === 'secondary' && style['secondary'],
        disabled && style['disabled'],
        active && style['active'],
        focusVisible && style['focusVisible'],
        fullWidth && style['fullwidth'],
      )}
    >
      {startIcon && startIcon}
      {children}
      {endIcon && endIcon}
    </button>
  );
});
