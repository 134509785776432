import {useNavigate} from 'react-router-dom';
import React, {useEffect} from 'react';
import {FormControl} from '../components/ui/forms/FormControl';
import {FormControlLabel} from '../components/ui/forms/FormControlLabel';
import {CheckboxChip} from '../components/ui/forms/CheckboxChip';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {sexualOrientation} from '../constants';
import './Q_SexualOrientation.scss';
import style from './Q_SexualOrientation.scss.json';
import {QuestionContainer} from '../components/QuestionContainer';
import {QuestionHeader} from '../components/QuestionHeader';
import {QuestionSubheader} from '../components/QuestionSubheader';
import {useWatchProperty} from 'lincd/lib/utils/Hooks';
import {dating} from 'lincd-dating/lib/ontologies/dating';
import CustomizedSwitch from '../components/ui/forms/CustomizedSwitch';

const Q6_SexualOrientation = () => {
  const navigate = useNavigate();
  const auth = useAuth<Person, FreeAccount>();
  const user = auth.user;
  const userAccount = auth.userAccount;

  //TODO: move the checkbox + watch + useEffect into its own component. And probably replace with a slider
  useEffect(() => {
    if (!userAccount.hasProperty(dating.displaySexualOrientation)) {
      userAccount.displaySexualOrientation = true;
    }
  }, []);
  useWatchProperty(userAccount, 'displaySexualOrientation');

  const onDisplaySexualOrientation = () => {
    userAccount.displaySexualOrientation =
      !userAccount.displaySexualOrientation;
  };

  console.log(
    'userAccount.displaySexualOrientation',
    userAccount.displaySexualOrientation,
  );

  return (
    <QuestionContainer>
      <FormControl className={style.itemsbox}>
        <QuestionHeader>My sexual orientation is</QuestionHeader>
        <QuestionSubheader>Select up to 3</QuestionSubheader>
        <CheckboxChip
          values={sexualOrientation}
          currentValues={user.sexualOrientation}
          maxCount={3}
        />
        <br />
        <FormControlLabel
          control={
            <CustomizedSwitch
              checked={
                userAccount.hasProperty(dating.displaySexualOrientation)
                  ? userAccount.displaySexualOrientation
                  : true
              }
              onChange={onDisplaySexualOrientation}
            />
          }
          label="Show my orientation on my profile."
        />
      </FormControl>
      {/* <ContinueButton
        text="You can always update this later. We got you."
        onClick={() => navigate(ROUTES.q7_lifestyle.path)}
      /> */}
    </QuestionContainer>
  );
};

export default Q6_SexualOrientation;
