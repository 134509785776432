import React, {ReactNode} from 'react';
import {Stack} from '../ui/forms/Stack';
import {Typography} from '../ui/forms/Typography';
import {default as style} from './ProfileSection.scss.json';
import './ProfileSection.scss';
import {URI} from 'lincd/lib/utils/URI';

interface ProfileSectionProps {
  title: string;
  children: ReactNode;
}

const ProfileSection = ({title, children}: ProfileSectionProps) => {
  return (
    <div id={URI.sanitize(title)} className={style.ProfileSectionRoot}>
      <Typography variant="body1" component="p" className={style.SectionTitle}>
        {title}
      </Typography>
      <Stack>{children}</Stack>
    </div>
  );
};

export default ProfileSection;
