import React, {useEffect, useState} from 'react';
import HeaderNavigation from '../components/layout/HeaderNavigation';
import PageLayout from '../components/layout/PageLayout';
import './Profile.scss';
import {default as style} from './Profile.scss.json';
import {Button} from '../components/ui/forms/Button';
import {Link, useNavigate} from 'react-router-dom';
import {ROUTES} from '../routes/AppRoutes';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Avatar} from 'profile-plus/lib/components/Avatar';
import FooterMenu from '../components/FooterMenu';
import {UpgradeAccountAction} from '../components/UpgradeAccountAction';
import {PaidAccountTier1} from 'lincd-dating/lib/shapes/PaidAccountTier1';
import {FeatureCounter} from 'lincd-dating/lib/shapes/FeatureCounter';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';
import {UpgradeAccountSuccess} from '../components/UpgradeAccountSuccess';
import {usePurchase} from '../hooks/purchase';

interface BenefitCardProps {
  likeCounts?: number | string;
  image: string;
  text: string;
  subText?: string;
  onAction?: (value: boolean) => void;
}

const BenefitCard = ({
  likeCounts,
  image,
  text,
  subText,
  onAction,
}: BenefitCardProps) => {
  const onHandleAction = () => {
    if (onAction) {
      onAction(true);
    }
  };

  return (
    <>
      <div className={style.benefitCard} onClick={onHandleAction}>
        <img src={asset(`/images/${image}.svg`)} alt={text} />
        <div>
          <p>
            {likeCounts && <span>{likeCounts}</span>} {text}
          </p>
          {subText && <p className={style.subText}>{subText}</p>}
        </div>
        {/* <img
        src="images/add.svg"
        alt="add icon"
        className={style.addPremiumFeatureIcon}
      /> */}
      </div>
    </>
  );
};

interface ProfileProps {
  currentValue?;
}
const Profile = ({currentValue}: ProfileProps) => {
  let navigate = useNavigate();
  let auth = useAuth<Person, FreeAccount | PaidAccountTier1>();
  let user = auth.user;
  let userAccount = auth.userAccount;
  const [remainingBoosts, setRemainingBoosts] = useState(0);
  const [remainingSuperLikes, setRemainingSuperLikes] = useState(0);

  // upgrade account
  const [isPaidAccount, setIsPaidAccount] = useState<boolean>(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [upgradeAccountLoading, setUpgradeAccountLoading] =
    useState<boolean>(false);
  const [upgradeAccountSuccess, setUpgradeAccountSuccess] =
    useState<boolean>(false);

  const purchase = usePurchase();

  // check isPaidAccount
  const checkIsPaidAccount = async () => {
    const paidAccount = await purchase.checkIsPaidAccount();

    if (paidAccount) {
      setIsPaidAccount(true);
    }
  };

  useEffect(() => {
    checkIsPaidAccount();
  }, [upgradeAccountSuccess]);

  useEffect(() => {
    if (isPaidAccount) {
      userAccount.getFeatureCounters().then((featureCounter) => {
        setRemainingBoosts(featureCounter.remainingBoosts);
        setRemainingSuperLikes(featureCounter.remainingSuperLikes);
      });
    }
  }, [isPaidAccount, userAccount]);

  let {givenName, age} = user;

  return (
    <PageLayout className={style.root}>
      {/* show upgrade account to premium when success  */}
      {upgradeAccountSuccess ? (
        <UpgradeAccountSuccess
          className={style.upgradeSuccess}
          onAction={() => {
            setShowUpgradeModal(false);
            setUpgradeAccountSuccess(false);
            navigate(ROUTES.profile.path);
          }}
        />
      ) : (
        <>
          <HeaderNavigation pageTitle="Profile" />
          <div
            className={style.mainProfilePictureContainer}
            onClick={() => navigate(ROUTES.edit_profile.path)}
          >
            <div className={style.profilePictureContainer}>
              <Avatar of={user} size="large" />
              <div className={style.editIconContainer}>
                <img src={asset('/images/edit_icon.svg')} alt="edit icon" />
                <div className={style.notification}></div>
              </div>
            </div>
            <div className={style.usernameContainer}>
              {/* if there is no age, then just render name, else age then render both name and age with comma */}
              {givenName && !age ? (
                <p>{givenName}</p>
              ) : (
                <p>
                  {givenName}, {age}
                </p>
              )}
              {/* <img src="images/badge.svg" alt="certification badge" /> */}
              <Link to={ROUTES.edit_profile.path} className={style.editProfile}>
                Edit Profile
              </Link>
            </div>
          </div>

          <div className={style.premiumContent}>
            <div className={style.cardContainer}>
              {isPaidAccount ? (
                <>
                  <BenefitCard
                    image="atom_colorful"
                    text="Quantum likes"
                    likeCounts={remainingSuperLikes}
                    subText="LEFT THIS WEEK"
                  />
                  <BenefitCard
                    image="boost_no_outline"
                    text="Profile boosts"
                    likeCounts={remainingBoosts}
                    subText="LEFT THIS WEEK"
                  />
                  <BenefitCard
                    image="rewind_icon"
                    text="Rewinds"
                    likeCounts="∞"
                  />
                </>
              ) : (
                <>
                  <BenefitCard
                    image="atom_colorful"
                    text="Quantum likes"
                    subText="GET PREMIUM"
                    onAction={setShowUpgradeModal}
                  />
                  <BenefitCard
                    image="boost_no_outline"
                    text="Profile boosts"
                    subText="GET PREMIUM"
                    onAction={setShowUpgradeModal}
                  />
                  <BenefitCard
                    image="rewind_icon"
                    text="Rewinds"
                    subText="GET PREMIUM"
                    onAction={setShowUpgradeModal}
                  />
                </>
              )}
            </div>
            <div className={style.banner}>
              <div className={style.title}>
                <img
                  src={asset('/images/sp_premium.svg')}
                  alt="sp premium icon"
                />
                <p>Spiritual Premium™️</p>
              </div>

              {!isPaidAccount && (
                <>
                  <p className={style.seeLikes}>See Who Likes You & More!</p>
                  <Button
                    className={style.upgradeButton}
                    fullWidth={true}
                    onClick={() => setShowUpgradeModal(true)}
                  >
                    Get Spiritual Premium™️
                  </Button>
                </>
              )}
            </div>
          </div>

          <FooterMenu />
        </>
      )}

      <UpgradeAccountAction
        isOpen={showUpgradeModal}
        onUpgraded={(success) => {
          setUpgradeAccountSuccess(true);
          setShowUpgradeModal(false);
        }}
        isLoading={(loading) => {
          setUpgradeAccountLoading(loading);
        }}
        onClose={() => setShowUpgradeModal(false)}
      />
    </PageLayout>
  );
};

export default Profile;
