import {useNavigate} from 'react-router-dom';
import {requestPermissions} from 'lincd-notify/lib/utils/helper';
import {useFirebase} from 'lincd-notify/lib/hooks/useFirebase';
import './Q11_Telepathy.scss';
import {QuestionContainer} from '../components/QuestionContainer';
import {QuestionHeader} from '../components/QuestionHeader';
import ContinueButton from '../components/ContinueButton';
import {ROUTES} from '../routes/AppRoutes';
import {QuestionSubheader} from '../components/QuestionSubheader';
import React, {useState} from 'react';
import Spinner from '../components/Spinner';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';

const Q11_Telepathy = () => {
  const navigate = useNavigate();
  const {updateToken} = useFirebase();
  let auth = useAuth<Person, FreeAccount>();

  const [loading, setLoading] = useState(false);

  const onFinish = async () => {
    // request permission and update the firebase token
    try {
      const permission = await requestPermissions();
      setLoading(true);
      if (permission === 'granted') {
        await updateToken();
        auth.userAccount.enabledNotifications = true;
      } else {
        auth.userAccount.enabledNotifications = false;
      }
    } catch (error) {
      console.warn(error);
      // Handle the error, e.g., display an error message or redirect to an error page.
    }
    //either way, continue to navigate to phone page
    navigate(ROUTES.q12_locationenabler.path);
  };

  return (
    <QuestionContainer>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <QuestionHeader>Turn on Telepathy Mode</QuestionHeader>
          <QuestionSubheader>
            Turn on notifications to sense when you get a new match
          </QuestionSubheader>
          <ContinueButton text="Continue" onClick={onFinish} />
        </>
      )}
    </QuestionContainer>
  );
};

export default Q11_Telepathy;
