import CheckIcon from '@mui/icons-material/Check';

import {RadioGroup} from '../components/ui/forms/RadioGroup';
import {Typography} from '../components/ui/forms/Typography';
import {useState} from 'react';
import './RadioChip.scss';
import style from './RadioChip.scss.json';
import {InputChip} from './ui/forms/InputChip';
import {useWatchProperty} from 'lincd/lib/utils/Hooks';
import {cl} from 'lincd/lib/utils/ClassNames';

interface RadioChipProps {
  label?;
  values;
  of?;
  onChange?;
  valueProperty?;
  currentValue?;
  className?;
}

export default function RadioChip({
  className,
  label,
  values,
  of,
  valueProperty,
  onChange,
  currentValue,
}: RadioChipProps) {
  const [selectedValue, setSelectedValue] = useState(
    currentValue || (of ? of[valueProperty] : null),
  );
  //add 1 more property for currentValue and the empty string will be changed to currentValue
  if (of && valueProperty) useWatchProperty(of, valueProperty);

  const onClick = (value) => {
    setSelectedValue(value);
    if (of && valueProperty) {
      of[valueProperty] = value;
    } else if (onChange) {
      onChange(value);
    }
  };
  return (
    <div className={style.RadioChipRoot}>
      {label && (
        <Typography level="h2" fontSize="lg" id={label} mb={2}>
          {label}
        </Typography>
      )}
      <RadioGroup
        className={`${style.radioChipContainer}`}
        name={label}
        aria-labelledby={label}
        orientation="horizontal"
      >
        {values.map((el, i) => {
          const isObject = typeof values[0] == 'object';
          const value = isObject ? el.value : el;
          const optionIsChecked = selectedValue === value;

          return (
            <InputChip
              className={cl(
                className,
                style['radiochip_chip'],
                optionIsChecked && style['selected'],
              )}
              checked={optionIsChecked}
              key={isObject ? el.value : el}
              // clickable={false} // just disable for now because showing an error on console log. If after disable this componenet not working well, just enable or find another way to fix it.
              label={
                isObject ? (
                  <div dangerouslySetInnerHTML={{__html: el.label}}></div>
                ) : (
                  el
                )
              }
              onClick={onClick.bind(this, value)}
              icon={
                optionIsChecked ? <CheckIcon className={style.checked} /> : null
              }
            />
          );
        })}
      </RadioGroup>
    </div>
  );
}
