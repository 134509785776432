import React, {useState, forwardRef, HTMLAttributes} from 'react';
import {default as style} from './SubscriptionCard.scss.json';
import './SubscriptionCard.scss';
import {cl} from 'lincd/lib/utils/ClassNames';
import {Icons} from './ui/Icons';

interface SubscriptionCardProps {
  review?: string;
  duration: string;
  monthlyPrice: number;
  totalPrice: number;
  discount?: number;
  isSelected: boolean;
  onClick;
}

export const SubscriptionCard = forwardRef<
  HTMLDivElement,
  SubscriptionCardProps
>(
  (
    {
      review,
      duration,
      monthlyPrice,
      totalPrice,
      discount,
      isSelected,
      onClick,
      ...restProps
    }: SubscriptionCardProps,
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={cl(style.card, isSelected && style.selected)}
        onClick={onClick}
        {...restProps}
      >
        {isSelected && <Icons.CheckmarkGold className={style.checkmark} />}
        {review && <p className={style.review}>{review}</p>}
        <div className={style.text}>
          <p className={style.priceTitle}>{duration}</p>
          <div className={style.priceContainer}>
            <p className={style.monthlyPrice}>${monthlyPrice}/mo</p>
            <p className={style.totalPrice}>${totalPrice} total</p>
          </div>
          {discount !== 0 && <p className={style.discount}>Save {discount}%</p>}
        </div>
      </div>
    );
  },
);

export default SubscriptionCard;
