import React from 'react';
import {default as style} from './Contact.scss.json';
import './Contact.scss';
import ProfileLayout from '../components/layout/ProfileLayout';
import {useNavigate} from 'react-router-dom';

const Contact = () => {
  let navigate = useNavigate();

  return (
    <ProfileLayout
      pageTitle="Contact"
      onBack={() => navigate(-1)}
      showFooter={false}
    >
      <div>
        <iframe
          className={style.iframe}
          src="https://embed.wotnot.io/6yraDLPodMoq0621384426165jH5Qmhz/bot/5KW9jtAhavMJ062205947117YWmTauYL?display_header=false&history_retention=false"
          frameBorder="0"
        />
      </div>
    </ProfileLayout>
  );
};

export default Contact;
