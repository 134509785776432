import React from 'react';
import './Signup.scss';
import {default as style} from './Signup.scss.json';
import {CreateAccountForm} from 'lincd-auth/lib/components/CreateAccountForm';
import {ROUTES} from '../routes/AppRoutes';
import {useNavigate} from 'react-router-dom';
// import {useSnackbar} from '../hooks/snackbar';

const Signup = () => {
  const navigate = useNavigate();
  // const snackbar = useSnackbar();

  const onAccountCreated = () => {
    navigate(ROUTES.welcome.path);
    // snackbar.openSnackbar({
    //   message:
    //     'Account is successfully created! To get started, please sign in',
    //   type: 'success',
    //   position: 'bottom-center',
    //   duration: 5000,
    // });
  };

  return (
    <div className={style.Root}>
      <CreateAccountForm onAccountCreated={onAccountCreated} />
    </div>
  );
};

export default Signup;
