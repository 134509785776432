import React from 'react';
import './Events.scss';
import {default as style} from './Events.scss.json';
import PageLayout from '../components/layout/PageLayout';
import HeaderNavigation from '../components/layout/HeaderNavigation';
import {Event} from 'lincd-schema/lib/shapes/Event';
import {ImageObject} from 'lincd-schema/lib/shapes/ImageObject';
import {Place} from 'lincd-schema/lib/shapes/Place';

const Events = () => {
  // let event1 = Event.create({
  //   name: "RegenWeek '24",
  //   startDate: new Date('26 March 2024'),
  //   endDate: new Date('31st March 2024'),
  //   location: Place.create({
  //     name: 'Tulum, Mexico',
  //   }),
  //   description:
  //     'Spiritual Playmates is doing a launch mixer at Regen Week between March 26th - 31st 2024. Free 2 month access to the app for anyone who attends. Join us for matchmaking with the app and speed dating for finding friends or partners.',
  //   url: 'https://regenweek.com/',
  // });
  // let event2 = Event.create({
  //   name: 'Spiritual Playmates Mixer',
  //   startDate: new Date('8 June 2024 09:00:00'),
  //   endDate: new Date('9 June 2024 12:00:00'),
  //   location: Place.create({
  //     name: 'Boulder, Colorado',
  //   }),
  //   description:
  //     'Join us for the Spiritual Playmates Mixer in Boulder, Colorado on June 8th from 9am to Midnight. We will have amazing workshops and activities from start to finish for you to choose from and tailor your experience to fit your unique self. Come for the whole thing or just a part of it. We will have workshops, music, dancing, shared meals, fun matchmaking activities for friends or even partners and experience a soulful connection with likeminded people. Click here to RSVP and learn more information.',
  //   url: null,
  // });
  // let events = [event1, event2];
  // // console.log(events);

  // const dateFormat = (value) => {
  //   const dateOnly = value.getDate();
  //   const monthOnly = value.getMonth() + 1;
  //   const yearOnly = value.getFullYear();

  //   return `${dateOnly} ${monthOnly} ${yearOnly}`;
  // };

  return (
    <PageLayout className={style.root}>
      <HeaderNavigation pageTitle={'Events'} />
      <div className={style.wrapper}>
        <iframe
          src="https://spiritualplaymates.com/event-relay/"
          title="Event Relay"
        />
        {/* <iframe src="#" title="Spiritual Playmates Mixer" /> */}
      </div>
      {/* 
        <div className={style.wrapper}>
            {events.length == 0 ? (
              <p>No Events</p>
            ) : (
              events.map((event) => {
                let {name, startDate, endDate, description, url} = event;
                return (
                  <div>
                    <div className={style.eventContainer}>
                      <p>Title</p>
                      <p>{name}</p>
                    </div>
                    <div className={style.eventContainer}>
                      <p>Start Date</p>
                       <p>{dateFormat(startDate)}</p> 
                    </div>
                    <div className={style.eventContainer}>
                      <p>End Date</p>
                       <p>{dateFormat(endDate)}</p> 
                    </div>
                    <div className={style.eventContainer}>
                      <p>Description</p>
                      <p>{description}</p>
                    </div>
                    <div className={style.eventContainer}>
                      <p>External link</p>
                      <a href={url}>{url}</a>
                    </div>
                  </div>
                );
              })
            )}
          </div> 
        */}
    </PageLayout>
  );
};

export default Events;
