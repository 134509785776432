import React from 'react';
import AppBar from '@mui/material/AppBar';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import {Container} from '../ui/forms/Container';
import IconButton from '@mui/material/IconButton';
import {ToolBar} from '../ui/forms/ToolBar';
import {Typography} from '../ui/forms/Typography';
import './AppHeader.scss';
import {default as style} from './AppHeader.scss.json';

interface AppHeaderProps {
  onBack?: () => void;
  title: string;
  variant?: string;
}

const AppHeader = React.forwardRef<HTMLDivElement, AppHeaderProps>(
  ({onBack, title, variant}: AppHeaderProps, ref) => {
    return (
      <AppBar
        ref={ref}
        component={'nav'}
        position="fixed"
        className={style.AppHeaderRoot}
      >
        <Container>
          <ToolBar className={style.AppHeaderWrapper}>
            {onBack ? (
              <div className={style.backColor}>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="back"
                  onClick={onBack}
                >
                  <KeyboardArrowLeft />
                </IconButton>
              </div>
            ) : (
              <div>&nbsp;</div>
            )}
            <Typography className={style.headline}>{title}</Typography>
            <div>&nbsp;</div>
          </ToolBar>
        </Container>
      </AppBar>
    );
  },
);

export default AppHeader;
