import RadioGroup from '@mui/material/RadioGroup';
import Slider from '@mui/material/Slider';
import CustomizedSwitch from '../components/ui/forms/CustomizedSwitch';
import {Radio} from '../components/ui/forms/Radio';
import MenuItem from '@mui/material/MenuItem';
import {FormControl} from '../components/ui/forms/FormControl';
import {FormControlLabel} from '../components/ui/forms/FormControlLabel';
import Select from '@mui/material/Select';
import {Stack} from '../components/ui/forms/Stack';
import {TextField} from '../components/ui/forms/TextField';
import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {getCategories} from '../data/profile';
import {slugify, unslugify} from '../utils/helper';

import {CheckboxChip} from '../components/ui/forms/CheckboxChip';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import LocationInput from '../components/ui/forms/LocationInput/LocationInput';
import RadioChip from '../components/RadioChip';
import MultiSearchChip from '../components/ui/forms/MultiSearchChip';
import {QuestionSubheader} from '../components/QuestionSubheader';
import {QuestionHeader} from '../components/QuestionHeader';
import {default as style} from './EditProfileDetail.scss.json';
import './EditProfileDetail.scss';
import DateTimeBirthSelect from '../components/ui/forms/DateTimeBirthSelect';
import BackButton from '../components/BackButton';
import {getAndUpdateUserLocation} from '../utils/permission';
import {requestPermissions} from 'lincd-notify/lib/utils/helper';
import {useFirebase} from 'lincd-notify/lib/hooks/useFirebase';
import {Dialog} from '@capacitor/dialog';
import PageLayout from '../components/layout/PageLayout';

const EditProfileDetail = () => {
  //temporary trick to force update based on changed internal graph state
  const [bool, setBool] = useState(true);
  const forceUpdate = () => {
    setBool(!bool);
  };

  const {slug} = useParams();
  const {updateToken, disableNotification} = useFirebase();

  let auth = useAuth<Person, FreeAccount>();
  let user = auth.user;
  let userAccount = auth.userAccount;

  const categories = getCategories();
  // example url: /profile/birth?section=birthdate
  // slug: birth
  const categoryBySlug = categories
    .get(unslugify(slug))
    ?.filter((item) => item.answers);

  const handleTextField = (e, property: string) => {
    const value = e.currentTarget.value;
    user[property] = value;
  };

  const handleSwitch = async (e, property) => {
    const checked = e.target.checked;

    const updateValue = () => {
      //for any other property, update the userAccount
      auth.userAccount[property] = checked;
      forceUpdate();
    };

    // request permissions and update user location if checked is true
    if (property === 'enabledLocationServices') {
      if (checked) {
        // request location permissions, if granted update the users location and set the switch to true
        getAndUpdateUserLocation().then((res) => {
          if (res) {
            updateValue();
          } else {
            Dialog.alert({
              title: 'Location permissions were blocked',
              message:
                'Location permissions were blocked. Please reset the permissions in your app or browser first and then try again.',
            });
          }
        });
      } else {
        updateValue();
      }
    } else if (property === 'enabledNotifications') {
      if (checked) {
        try {
          // request notification permission, if granted update the token and set the switch to true
          const permission = await requestPermissions();
          if (permission === 'granted') {
            //first update the switch
            updateValue();
            //then do slow things like updating notification token
            await updateToken();
          } else {
            Dialog.alert({
              title: 'Notifications permissions were blocked',
              message:
                'Notifications permissions were blocked. Please reset the permissions in your app or browser first and then try again.',
            });
          }
        } catch (error) {
          throw new Error('request permission failed');
        }
      } else {
        // remove all data related notification
        await disableNotification();
        updateValue();
      }
    } else {
      updateValue();
    }
  };

  const handleSelect = (e, property: string) => {
    const value = e.target.value;
    user[property] = value;
  };

  const handleRadioButton = (e, property: string) => {
    const value = e.target.value;
    user[property] = value;
  };

  return (
    // <ProfileLayout pageTitle={unslugify(slug)} onBack={handleBack}>
    <PageLayout className={style.EditProfileDetailContainer}>
      <BackButton
        relative={true}
        text={<QuestionHeader>{unslugify(slug)}</QuestionHeader>}
      />
      {/*<QuestionHeader>{unslugify(slug)}</QuestionHeader>*/}
      <Stack spacing={1} pb={8}>
        {categoryBySlug &&
          categoryBySlug.map(
            ({
              name,
              property,
              answers: {
                component,
                value,
                hideQuestionName,
                options,
                marks,
                maxCount,
                multiple,
              },
              showPublic,
            }) => {
              //check if the get-method exists at all
              // let Component:React.FunctionComponent<any> = component as React.FunctionComponent;
              let Component: any = component as any;
              if (
                process.env.NODE_ENV === 'development' &&
                !((property as string) in user)
              ) {
                console.warn(
                  'This accessor is not implemented yet: ' + property,
                );
              }

              let currentValue;

              // check if property is array (for age range for now)
              if (Array.isArray(property)) {
                const propertyArray = [];
                property.map((p) => {
                  // get value of minAge and maxAge and then push them to properties
                  // check the property is defined on user or userAccount
                  let propertyValue;
                  if (user[p]) {
                    propertyValue = user[p];
                  } else if (userAccount[p]) {
                    propertyValue = userAccount[p];
                  } else {
                    propertyValue = null;
                  }
                  propertyArray.push(propertyValue);
                });
                currentValue = propertyArray;
              } else {
                currentValue = user[property];
              }

              if (showPublic) {
                console.log('showPublic.property', name, showPublic);
              }

              return (
                <React.Fragment key={name}>
                  {name && !hideQuestionName && (
                    <QuestionSubheader>{name}</QuestionSubheader>
                  )}
                  {/* TODO: separate this to dynamic form when needed */}
                  {component === 'TextField' && (
                    <TextField
                      name={slugify(name)}
                      defaultValue={currentValue}
                      onBlur={(e) => handleTextField(e, property as string)}
                    />
                  )}
                  {component === 'Textarea' && (
                    <TextField
                      name={slugify(name)}
                      minRows={4}
                      maxRows={6}
                      multiline
                      defaultValue={currentValue}
                      onBlur={(e) => handleTextField(e, property as string)}
                    />
                  )}
                  {component === 'CheckboxChip' && (
                    <FormControl>
                      <CheckboxChip
                        currentValues={currentValue}
                        values={options}
                        maxCount={maxCount}
                      />
                    </FormControl>
                  )}
                  {component === 'RadioChip' && (
                    <FormControl>
                      <RadioChip
                        currentValue={currentValue}
                        of={user}
                        valueProperty={property}
                        values={options}
                        onChange={(e) =>
                          handleRadioButton(e, property as string)
                        }
                      />
                    </FormControl>
                  )}
                  {component === 'Slider' && (
                    <FormControl>
                      <Slider
                        name={slugify(name)}
                        valueLabelDisplay="auto"
                        min={1}
                        max={10}
                        step={1}
                        marks={marks}
                      />
                    </FormControl>
                  )}
                  {component === 'RangeSlider' && (
                    <FormControl>
                      {/*TODO: if we need age slider here, we need to know the mode */}
                      {/*<AgeSlider className={style.rangeSlider} />*/}
                    </FormControl>
                  )}
                  {component === 'Select' && (
                    <FormControl size="small">
                      <select
                        defaultValue={currentValue || '--select--'}
                        onChange={(e) => handleSelect(e, property as string)}
                      >
                        {options?.map((opt, i) => (
                          <option key={i} value={opt}>
                            {opt}
                          </option>
                        ))}
                      </select>
                    </FormControl>
                  )}
                  {component === 'MultiSelect' && (
                    <FormControl size="small">
                      <Select
                        defaultValue={currentValue}
                        onChange={(e) => handleSelect(e, property as string)}
                      >
                        {options?.map((opt, i) => (
                          <MenuItem key={i} value={opt}>
                            {opt}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {component === 'Radio' && (
                    <FormControl>
                      <RadioGroup
                        name={slugify(name)}
                        row={true}
                        defaultValue={currentValue}
                        onChange={(e) =>
                          handleRadioButton(e, property as string)
                        }
                      >
                        {options?.map((opt, i) => (
                          <FormControlLabel
                            key={i}
                            value={opt}
                            control={<Radio />}
                            label={opt}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                  {component === 'LocationInput' && (
                    <LocationInput
                      of={user}
                      property={property as string}
                      multiple={multiple}
                      maxCount={maxCount}
                    />
                  )}
                  {component === 'DateTimeBirth' && (
                    <DateTimeBirthSelect
                      of={user}
                      property={['birthDate', 'doesNotKnowTime']}
                    />
                  )}
                  {showPublic && (
                    <FormControlLabel
                      control={
                        <CustomizedSwitch
                          checked={auth.userAccount[showPublic.property]}
                          onChange={(e) => handleSwitch(e, showPublic.property)}
                        />
                      }
                      label={`Show my ${name.toLowerCase()} on my profile?`}
                    />
                  )}
                  {component == 'MultiSearchChip' && (
                    <MultiSearchChip
                      of={user}
                      property={property as string}
                      values={options}
                      maxCount={maxCount}
                    />
                  )}
                  {component == 'Switch' && (
                    <CustomizedSwitch
                      checked={userAccount[property as string]}
                      onChange={(e) => handleSwitch(e, property)}
                    />
                  )}
                  {typeof component === 'function' && (
                    <Component variant={'inline'}></Component>
                  )}
                </React.Fragment>
              );
            },
          )}
      </Stack>
    </PageLayout>
  );
};

export default EditProfileDetail;
