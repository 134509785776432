import React, {useState} from 'react';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Literal} from 'lincd/lib/models';
import './MultiSearchChip.scss';
import {default as style} from './MultiSearchChip.scss.json';
import {Paper} from './Paper';
import {List} from './List';
import {ListItem} from './ListItem';
import {ListItemText} from './ListItemText';
import {TextField} from './TextField';
import {Stack} from './Stack';
import {Typography} from './Typography';
import {default as InputChip} from '@mui/material/Chip';

// import { InputChip } from './InputChip';

interface MultiSearchChipProps {
  values: string[];
  of: Person | FreeAccount;
  property: string;
  maxCount?: number;
}

const MultiSearchChip = ({
  values,
  of,
  property,
  maxCount,
}: MultiSearchChipProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isMaxItems, setIsMaxItems] = useState(false);
  let [bool, setBool] = useState(false);
  let forceUpdate = () => setBool(!bool);

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
  };

  const onSelect = (item) => {
    const itemsSize = of[property].size;
    //prevent to insert more than maxCount items
    if (itemsSize > maxCount - 1) {
      setIsMaxItems(true);
      return;
    }

    of[property].add(new Literal(item));
    setSearchQuery('');
    forceUpdate();
  };

  const onDelete = (item) => {
    of[property].delete(item);
    forceUpdate();

    if (isMaxItems) {
      setIsMaxItems(false);
    }
  };

  const filterData = (query, data) => {
    if (!query) {
      return data;
    } else {
      return data.filter((d) => d.toLowerCase().includes(query.toLowerCase()));
    }
  };

  //do not show items that are already selected
  let sortOptions = values.sort((a, b) => (a > b ? 1 : -1));
  const optionFilter = filterData(searchQuery, sortOptions).filter((item) => {
    return !of[property].some((literalNode) => literalNode.value === item);
  });

  //access the get method of the source node
  let currentValues = of[property];
  return (
    <Stack>
      <div className={style.currentValues}>
        {currentValues
          .sort((a, b) => {
            return a.value > b.value ? 1 : -1;
          })
          .map((literal, i) => (
            <InputChip
              key={`${literal.value}-${i}`}
              label={literal.value}
              onDelete={() => onDelete(literal)}
              className={style.inputChip}
              // noHoverEffect
            />
          ))}
      </div>
      <TextField
        className={style.search}
        placeholder={'Search'}
        value={searchQuery}
        onChange={handleSearch}
      />
      {isMaxItems && (
        <Typography color="red">
          You can only select {maxCount} items
        </Typography>
      )}
      <Paper elevation={1} className={style.options}>
        <List dense>
          {optionFilter?.map((option, i) => (
            <ListItem
              key={`${option}-${i}`}
              className={style.listItem}
              onClick={() => onSelect(option)}
            >
              <ListItemText>{option}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Stack>
  );
};

export default MultiSearchChip;
