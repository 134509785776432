import React from 'react';
import {default as MuiChip} from '@mui/material/Chip';
import './InputChip.scss';
import {default as style} from './InputChip.scss.json';
import {useStyles} from 'lincd/lib/utils/Hooks';

interface ChipProps extends React.ComponentProps<typeof MuiChip> {
  noHoverEffect?: boolean;
  checked?: boolean;
}

export function InputChip({noHoverEffect, checked, ...props}: ChipProps) {
  props = useStyles(props, [
    style.InputChip,
    checked && style.chip_selected,
    noHoverEffect && style.no_hover_effect,
  ]);
  return (
    <div {...props}>
      {props.label}
      {props.icon && <div className={style.icon}>{props.icon}</div>}
    </div>
  );
}
