import React, {useEffect, useState} from 'react';
import {ProfileQuestion, ProfileSection} from '../components/profile';
import {useNavigate} from 'react-router-dom';
import {getCategories} from '../data/profile';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {useCometChat} from 'lincd-cometchat/lib/hooks/useCometChat';
import FooterMenu from '../components/FooterMenu';
import './EditProfile.scss';
import {default as style} from './EditProfile.scss.json';
import {Button} from 'lincd-mui-base/lib/components/Button';
import {Dialog} from '@capacitor/dialog';

import {useLocation} from 'react-router-dom';
import HeaderNavigation from '../components/layout/HeaderNavigation';
import PageLayout from '../components/layout/PageLayout';
import {RemoveAccountButton} from 'lincd-auth/lib/components/RemoveAccountButton';

const EditProfile = () => {
  const navigate = useNavigate();
  const chat = useCometChat();
  let cometChat = useCometChat();
  const location = useLocation();
  let auth = useAuth<Person, FreeAccount>();
  let [chatWith, setChatWith] = React.useState<any>();
  let user = auth.user;
  let userAccount = auth.userAccount;

  // navigate to detail of the profile question
  const navigateTo = (val: string) => {
    navigate(val);
  };

  useEffect(() => {
    //scroll to the id that is in the url
    const id = window.location.hash.slice(1);
    if (id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, []);

  const onSignOut = () => {
    if (chat) {
      chat.logout();
    }
    auth.signout();
  };
  const categories = getCategories();
  if (!user) return null;

  return (
    <PageLayout className={style.root}>
      <HeaderNavigation
        pageTitle="Edit Profile"
        onBackButton={() => navigate(-1)}
      />
      <div className={style.EditProfile}>
        {Array.from(categories).map(([categoryName, category]) => {
          // skip dating category when relationshipType is friendship
          if (
            categoryName === 'Dating' &&
            user.relationshipType === 'friendship'
          ) {
            return null;
          }

          return (
            <ProfileSection key={categoryName} title={categoryName}>
              {category.map((item) => {
                const {name, icon, property, questions, showPublic} = item;

                //check if the get-method exists at all
                if (
                  process.env.NODE_ENV === 'development' &&
                  !((property as string) in user)
                ) {
                  console.warn(
                    'This accessor is not implemented yet: ' + property,
                  );
                }

                //call the get-method for the user (a Person object) to get the current value
                //the 'property' variable represents the name of the get method
                let currentValue;

                // check property is string or string[],if array save to a new variable
                if (Array.isArray(property)) {
                  const propertyArray = [];
                  property.map((p) => {
                    // get value minAge and maxAge and then push to properties
                    // TODO: check property on user or userAccount if needed
                    propertyArray.push(userAccount[p]);
                  });
                  currentValue = propertyArray;
                } else {
                  // if property is a string, check user and userAccount for the value
                  currentValue =
                    user[property] || userAccount[property] || null;
                }

                // check category has showPublic
                let publicValue = null;
                if (showPublic && showPublic.property) {
                  publicValue = user[showPublic.property];
                }

                return (
                  <>
                    <ProfileQuestion
                      key={name || categoryName + '-' + property}
                      category={categoryName}
                      question={name}
                      component={questions.component}
                      onDetail={navigateTo}
                      currentValue={currentValue}
                      showPublic={publicValue}
                      icon={icon}
                    />
                  </>
                );
              })}
            </ProfileSection>
          );
        })}
        <Button
          className={style.signOutButton}
          color="primary"
          variant="filled"
          fullWidth
          onClick={onSignOut}
        >
          Sign Out
        </Button>
        <RemoveAccountButton className={style.signOutButton} />

        <FooterMenu />
      </div>
    </PageLayout>
  );
};

export default EditProfile;
