import React from 'react';
import {Select as MuiSelect, SelectProps} from '@mui/base/Select';
import {Option as MuiOption} from '@mui/base/Option';
import {default as style} from './Select.scss.json';
import './Select.scss';

interface CustomSelectProps extends SelectProps<{}, boolean> {
  options?: string[];
  onChange?: (selectedValue: any) => void;
}

export const Select = React.forwardRef<HTMLButtonElement, CustomSelectProps>(
  (
    {options, onChange, value, defaultValue, ...props}: CustomSelectProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const handleChange = (e, newValue) => {
      onChange(newValue);
    };

    return (
      <MuiSelect
        className={style.Select}
        {...props}
        slotProps={{
          listbox: {className: style.CustomSelectListbox},
          popper: {className: style.CustomSelectPopper},
        }}
        onChange={handleChange}
        value={value}
        defaultValue={defaultValue}
        renderValue={renderValues}
        ref={ref}
      >
        {options.map((el) => {
          return (
            <MuiOption value={el} className={style.CustomSelectOption} key={el}>
              {el}
            </MuiOption>
          );
        })}
      </MuiSelect>
    );
  },
);

function renderValues(option) {
  if (option == null) {
    return <span className={style.placeholder}>-Select-</span>;
  }

  return <span>{option.label}</span>;
}
