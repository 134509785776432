import React from 'react';
import {
  FormControlLabelProps,
  default as MuiFormControlLabel,
} from '@mui/material/FormControlLabel';
import {default as style} from './FormControlLabel.scss.json';

export const FormControlLabel = React.forwardRef<
  HTMLInputElement,
  FormControlLabelProps
>(({control, label, ...props}, ref) => {
  return (
    <MuiFormControlLabel
      ref={ref}
      control={control}
      label={label}
      className={style.FormControlLabel}
      {...props}
    />
  );
});

FormControlLabel.displayName = 'FormControlLabel';
