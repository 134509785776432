import {Typography} from './Typography';
import {NodeValuesSet} from 'lincd/lib/collections/NodeValuesSet';
import {Literal} from 'lincd/lib/models';
import React, {useState} from 'react';
import CheckIcon from '@mui/icons-material/Check';
import './CheckboxChip.scss';
import {default as style} from './CheckboxChip.scss.json';
import {InputChip} from './InputChip';

interface CheckBoxChipProps {
  label?;
  values;
  currentValues: NodeValuesSet;
  disabled?;
  maxCount?: number;
}

export function CheckboxChip({
  values,
  label,
  currentValues,
  disabled,
  maxCount,
}: CheckBoxChipProps) {
  const [isMaxItems, setIsMaxItems] = useState(false);
  let [bool, setBool] = useState<boolean>(false);
  let forceUpdate = () => setBool(!bool);

  const onValueChange = (value, event) => {
    let currentValue = currentValues?.find((literalNode) => {
      return literalNode.value === value;
    });

    if (currentValue) {
      currentValues?.delete(currentValue);

      isMaxItems && setIsMaxItems(false);
    } else {
      if (currentValues.size > maxCount - 1) {
        setIsMaxItems(true);
        return;
      }
      currentValues?.add(new Literal(value));
    }
    forceUpdate();
  };
  return (
    <div className={style.CheckboxChip}>
      <div>
        {label && (
          <Typography fontWeight="md" fontSize="lg" id={label} mb={2}>
            {label}
          </Typography>
        )}
        {isMaxItems && (
          <Typography fontWeight="md" fontSize="lg" mb={2} color="red">
            You can only select {maxCount} items.
          </Typography>
        )}
        <div
          role="group"
          aria-labelledby="fav-movie"
          className={style.CheckboxChipContent}
        >
          {values.map((value, i) => {
            const checked = currentValues?.some((lit) => {
              return lit.value == value;
            });
            return (
              <InputChip
                key={`${value}-${i}}`}
                onClick={onValueChange.bind(null, value)}
                label={value}
                checked={checked}
                icon={checked ? <CheckIcon className={style.checked} /> : null}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
