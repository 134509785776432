import React from 'react';
import './Divider.scss';
import style from './Divider.scss.json';
import {cl} from 'lincd/lib/utils/ClassNames';

interface DividerProps {
  text?: string;
  className?;
}

const Divider = ({text, className}: DividerProps) => {
  return (
    <div className={cl(style.divider, className)}>
      <p>{text}</p>
    </div>
  );
};

export default Divider;
