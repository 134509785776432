import React from 'react';
import {default as MuiTypography} from '@mui/material/Typography';
import {default as style} from './Typography.scss.json';
import './Typography.scss';

export function Typography(props) {
  return (
    <MuiTypography className={style.Typography} {...props}></MuiTypography>
  );
}
