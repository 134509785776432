import {useEffect} from 'react';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {RangeSlider} from 'lincd-mui-base/lib/components/RangeSlider';
import {useLocation} from 'react-router-dom';
import {ROUTES} from '../routes/AppRoutes';

export const AgeSlider = ({
  className,
  mode,
}: {
  className?: string;
  mode: 'friendship' | 'dating';
}) => {
  let auth = useAuth<Person, FreeAccount>();
  let user = auth.user;
  let userAccount = auth.userAccount;

  // set default value property minAge and maxAge when is null based on relationshipType
  useEffect(() => {
    if (user.relationshipType === 'friendship') {
      userAccount.minAgeFriendships = userAccount.minAgeFriendships || 18;
      userAccount.maxAgeFriendships = userAccount.maxAgeFriendships || 100;
    } else if (user.relationshipType === 'dating') {
      userAccount.minAgeDating = userAccount.minAgeDating || 18;
      userAccount.maxAgeDating = userAccount.maxAgeDating || 100;
    } else {
      userAccount.minAgeFriendships = userAccount.minAgeFriendships || 18;
      userAccount.maxAgeFriendships = userAccount.maxAgeFriendships || 100;
      userAccount.minAgeDating = userAccount.minAgeDating || 18;
      userAccount.maxAgeDating = userAccount.maxAgeDating || 100;
    }
  }, []);

  // use property minAge and maxAge based from browse dates or friend page
  const minAndMaxAgeProperty =
    mode === 'friendship'
      ? ['minAgeFriendships', 'maxAgeFriendships']
      : ['minAgeDating', 'maxAgeDating'];

  return (
    <RangeSlider
      of={userAccount}
      minProperty={minAndMaxAgeProperty[0]}
      maxProperty={minAndMaxAgeProperty[1]}
      printValue={(currentMin, currentMax) =>
        `Between ${currentMin} and ${currentMax == 100 ? '∞' : currentMax}`
      }
      min={18}
      max={100}
      className={className}
      ariaLabel="Age range"
    ></RangeSlider>
  );
};
