import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Person} from 'lincd-dating/lib/shapes/Person';
import React, {Suspense, useCallback, useEffect, useRef} from 'react';
import useState from 'react-usestateref';
import {Button} from '../components/ui/forms/Button';
import ProfileCard from './ProfileCard';
import {default as styles} from './SwipeProfiles.scss.json';
import './SwipeProfiles.scss';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {packageName} from '../package';
import {NamedNode} from 'lincd/lib/models';
import NewMatchPopUp from '../components/NewMatchPopUp';
import HeaderNavigation from '../components/layout/HeaderNavigation';
import ProfileDetail from './ProfileDetail';
import PageLayout from '../components/layout/PageLayout';
import {CurrentPhotoContext} from 'profile-pics/lib/components/ProfilePicturesClickthrough';
import {PaidAccountTier1} from 'lincd-dating/lib/shapes/PaidAccountTier1';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {NodeSet} from 'lincd/lib/collections/NodeSet';
import CountdownTimer from '../components/CountdownTimer';
import {useWatchProperty} from 'lincd/lib/utils/Hooks';
import {cl} from 'lincd/lib/utils/ClassNames';
import {UpgradeAccountSuccess} from '../components/UpgradeAccountSuccess';
import {UpgradeAccountAction} from '../components/UpgradeAccountAction';
import {getAndUpdateUserLocation} from '../utils/permission';
import Spinner from '../components/Spinner';
import {ROUTES} from '../routes/AppRoutes';
import {LinkedStorage} from 'lincd/lib/utils/LinkedStorage';
import {FeatureCounter} from 'lincd-dating/lib/shapes/FeatureCounter';
import {useFirebase} from 'lincd-notify/lib/hooks/useFirebase';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';
import {useNavigate} from 'react-router-dom';
import {SwipeTourBasic} from '../components/SwipeTourBasic';
import {Icons} from '../components/ui/Icons';
import {usePurchase} from '../hooks/purchase';
import {Dialog} from '@capacitor/dialog';

let useDrag, useDrop, DndProvider, usePreview, TouchBackend;

const matchScoreMap = new Map<NamedNode, number>();

const SwipeProfiles = ({mode}: {mode?: 'dating' | 'friendship'}) => {
  let auth = useAuth<Person, FreeAccount | PaidAccountTier1>();
  let userAccount = auth.userAccount;
  const {updateToken} = useFirebase();

  const [showProfileDetails, setShowProfileDetails, showProfileDetailsRef] =
    useState(false);
  const [lastProfile, setLastProfile, lastProfileRef] = useState<Person>();
  const [productSelection, setProductSelection] = useState<any>({});
  const [noProfilesToShow, setNoProfilesToShow, noProfilesToShowRef] =
    useState<boolean>();
  const [currentProfile, setCurrentProfile, currentProfileRef] =
    useState<Person>();
  const [profilesWithSuperLike, setProfilesWithSuperLike] = useState<
    NodeSet<NamedNode>
  >(new NodeSet());
  const [sourceProfile, setSourceProfile, sourceProfileRef] =
    useState<ShapeSet<Person>>();
  const [profiles, setProfiles, profilesRef] = useState<ShapeSet<Person>>();
  const [dndLoaded, setDndLoaded] = useState<boolean>(false);
  const [pendingLike, setPendingLike] = useState<boolean>(false);
  const [pendingDetails, setPendingDetails] = useState<boolean>(false);
  const [pendingDislike, setPendingDislike] = useState<boolean>(false);
  const [mouseLeft, setMouseLeft] = useState<boolean>(false);
  const [loadingMoreProfiles, setLoadingMoreProfiles, loadingMoreProfilesRef] =
    useState<boolean>(false);
  const [endOfProfilesReached, setEndOfProfilesReached] =
    useState<boolean>(false);
  const [newMatch, setNewMatch] = useState<Person>(null);
  const [expireDate, setExpireDate] = useState<Date>(null);
  const [currentPhoto, setCurrentPhoto] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [disableRewind, setDisableRewind] = useState(false);
  const [toggleFab, setToggleFab] = useState(false);
  const [boostIsActive, setBoostIsActive] = useState(false);

  // remaining feature counter for super like and boost
  const [remainingFeatureCounter, setRemainingFeatureCounter] = useState({
    remainingSuperLikes: 0,
    remainingBoosts: 0,
  });

  // upgrade account
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [upgradeAccountSuccess, setUpgradeAccountSuccess] =
    useState<boolean>(false);

  // upgrade account
  const [toggleDetails, setToggleDetails] = useState<boolean>(false);
  const [upgradeAccount, setUpgradeAccount] = useState<boolean>(false);
  const [upgradeAccountLoading, setUpgradeAccountLoading] =
    useState<boolean>(false);

  // product tour / swipe tour
  const initSwipeTour = !userAccount.onboarded;
  const [runSwipeTour, setRunSwipeTour] = useState(initSwipeTour);

  const navigate = useNavigate();
  const purchase = usePurchase();

  const expireDateRef = useRef(expireDate);
  expireDateRef.current = expireDate;

  useEffect(() => {
    //dnd is ESM, so we have to import it dynamically unfortunately
    Promise.all([
      import('react-dnd'),
      import('react-dnd-touch-backend'),
      import('react-dnd-preview'),
    ]).then(([dnd, touchModule, dndPreview]) => {
      useDrag = dnd.useDrag;
      useDrop = dnd.useDrop;
      DndProvider = dnd.DndProvider;
      TouchBackend = touchModule.TouchBackend;
      usePreview = dndPreview.usePreview;
      setDndLoaded(true);
    });
  }, []);

  useEffect(() => {
    // hide the fab button when user move the mouse out of the element
    if (mouseLeft) {
      setToggleFab(false);
    }
  }, [mouseLeft]);

  const onReset = useCallback(async () => {
    //wait for the like/dislike that was just made to be stored first
    await LinkedStorage.promiseUpdated();
    //then clear all
    await auth.user.clearDislikes();
    setProfiles(null);
    setLastProfile(null);
    setEndOfProfilesReached(false);
    setLoadingMoreProfiles(true);
    setTimeout(() => {
      loadNewProfiles().then(() => {
        if (profilesRef.current.size === 0) {
          setNoProfilesToShow(true);
        }
      });
    }, 2000);
  }, [auth]);

  const loadNewProfiles = useCallback(() => {
    setLoadingMoreProfiles(true);
    let currentProfiles = profiles;
    let currentProfileURIs = currentProfiles
      ? currentProfiles.map((p) => p.uri)
      : [];

    return Server.call(
      packageName,
      {
        method: 'getProfiles',
        setLoaded: true,
      },
      currentProfileURIs,
      lastProfileRef.current,
      mode,
    ).then((newProfiles) => {
      if (newProfiles) {
        let {profiles, accounts, matchScores, hasSuperLike, featureCounter} =
          newProfiles;

        if (!profiles || profiles.size == 0) {
          setEndOfProfilesReached(true);

          // TODO: disable for now because always trigger
          // onReset();
        }
        //combine old results with new
        if (currentProfiles) {
          profiles = currentProfiles.concat(profiles);
        }
        //update the overview of matchScores with the new ones
        matchScores.forEach((matchScore, personNode) => {
          matchScoreMap.set(personNode, matchScore);
        });

        setLoadingMoreProfiles(false);
        setProfiles(profiles);
        setCurrentProfile(profiles.first());

        //add the incoming profiles with superlikes to the total list
        if (hasSuperLike) {
          setProfilesWithSuperLike(profilesWithSuperLike.concat(hasSuperLike));
        }

        fixDragIssue();
      } else {
        console.warn('No profiles returned from server');
      }
    });
  }, [profiles]);

  const fixDragIssue = () => {
    //for some reason, the first time the pending action state changes whilst dragging, the drag stops and the preview disappears
    //this is a little hack to fix that. We set pendingLike to true and immediately back to false
    //the user should not notice this, since it's unlikely they will be able to drag and drop in less than 5ms
    setPendingLike(true);
    setTimeout(() => {
      setPendingLike(false);
    }, 5);
  };

  useEffect(() => {
    //TODO: see how many profiles we have left
    if (
      (!profiles || profiles.size <= 3) &&
      !loadingMoreProfilesRef.current &&
      !endOfProfilesReached
    ) {
      //if we have less than 3, add more
      loadNewProfiles();
    } else if (
      endOfProfilesReached &&
      profiles.size === 0 &&
      !noProfilesToShow
    ) {
      onReset();
    }
  }, [profiles, endOfProfilesReached, noProfilesToShow]);

  useEffect(() => {
    if (showProfileDetails) {
      //scroll until almost the entire top of the profile has been scrolled out of view
      // !disable this code for keep detail profile from the top, not scroll to bottom
      // window.scrollTo(0, document.body.clientHeight * 0.75);
    } else {
      //scroll to the top of the page
      window.scrollTo(0, 0);
    }
  }, [showProfileDetails]);

  const onToggleProfileDetails = (): void => {
    setShowProfileDetails(!showProfileDetails);
  };

  const onDrop = (finalDistance): void => {
    let action = toSwipeAction(finalDistance);
    if (action === 'like') {
      onLike({
        mode: mode,
        matchingScore: currentMatchScore,
      });
    } else if (action === 'dislike') {
      onDislike();
    } else if (action === 'details') {
      onToggleProfileDetails();
    }
  };

  const onLike = useCallback(
    ({
      isSuperLike = false,
      mode = 'dating',
      matchingScore,
    }: {
      isSuperLike?: boolean;
      mode: 'dating' | 'friendship';
      matchingScore: number;
    }) => {
      let likedPerson = currentProfileRef.current;

      // set matches url based from mode
      const matchesUrl =
        mode === 'friendship'
          ? ROUTES.matches.path + `?q=matches`
          : ROUTES.matches_friend.path + `?q=matches`;

      auth.user
        .addLike(likedPerson, isSuperLike, mode, matchesUrl, matchingScore)
        .then((matchingLike) => {
          if (matchingLike) {
            setNewMatch(likedPerson);
          }

          // update the feature counter after user do quantum like
          if (isSuperLike) {
            getFeatureCounter();
          }
        });

      afterAssessment('onLike');
      setDisableRewind(false);
    },
    [currentProfile],
  );

  const onDislike = useCallback(() => {
    auth.user.addDislike(currentProfileRef.current);
    afterAssessment('onDislike');
    setDisableRewind(false);
  }, [currentProfile, profiles]);

  // handle on rewind for paid account
  const onRewind = async () => {
    const isPaidAccount = await purchase.checkIsPaidAccount();
    // check account is PaidAccount for can use rewind feature
    // if not show modal upgrade account
    if (isPaidAccount) {
      afterAssessment('onRewind');
      setIsLoading(false);
    } else {
      setShowUpgradeModal(true);
    }
  };

  const refreshResults = useCallback(() => {
    setProfiles(null);
    setLastProfile(null);
    setEndOfProfilesReached(false);
    loadNewProfiles();
  }, []);

  const afterAssessment = useCallback(
    (assesment: string) => {
      // assuming account is has been upgrade
      setShowUpgradeModal(false);
      if (assesment === 'onRewind') {
        if (sourceProfileRef) {
          let newProfiles = sourceProfileRef.current;

          // move the last profile to the first profile
          // to make sure to show the right profile after rewind
          // why the last profile? because the last profile is the profile that we remove from the list. More context: find `source.add(removedProfile);`
          let sourceArray = Array.from(newProfiles);
          let lastProfile = sourceArray.pop();
          if (lastProfile) {
            sourceArray.unshift(lastProfile);
          }

          // change source from array to ShapeSet
          newProfiles = new ShapeSet<Person>(sourceArray);
          setProfiles(newProfiles);

          let previousProfile = newProfiles?.first();
          setCurrentProfile(previousProfile);

          auth.user.rewind(previousProfile);
        }

        // disable rewind button after rewind
        setDisableRewind(true);
      } else {
        //reset current photo to the first photo for the next person
        setCurrentPhoto(1);
        if (showProfileDetailsRef.current === true) {
          //couldn't get animation to work yet, so we manually move to next slide by setting currentSlide, which changes the initialSlide prop of the slider
          setShowProfileDetails(false);
        }
        setLastProfile(currentProfileRef.current);

        //remove the first (currently visible) profile
        let removedProfile = profilesRef.current.first();

        let newProfiles = profilesRef.current.slice(1);
        setProfiles(newProfiles);
        setCurrentProfile(newProfiles.first());

        //add the removed profile to the begining of the list
        let source = profilesRef.current;

        // add the removed profile to the source profile
        // after add the removed profile will be on the last index in source profiles
        source.add(removedProfile);
        setSourceProfile(source);
      }
    },
    [profiles, showProfileDetails],
  );

  const handleFAB = () => {
    setToggleFab(!toggleFab);
  };

  //we want to know who we're currently showing, and who we're displaying under that (in case the user drags the profile around)
  // let profileArr = profiles ? [...profiles] : [];
  let currentlyVisiblePerson = profiles?.first();
  let nextPerson = profiles?.find((p) => p !== currentlyVisiblePerson);
  //remove these two lines and replace ownProfile with currentProfile
  // currentlyVisiblePerson = auth.user;
  // let ownProfile = auth.user;
  let currentMatchScore;
  if (currentProfile) {
    //scores currently tend to be low, so we give at least 33% match and 50% of max is already 100% score
    currentMatchScore = matchScoreMap.get(currentProfile.namedNode);
  }

  //did the currently visible person super like the user?
  let hasSuperLike = profilesWithSuperLike.has(
    currentlyVisiblePerson?.namedNode,
  );

  const scrollToTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  };

  // handle super like feature for paid account
  const onSuperLike = async () => {
    // check account is PaidAccount for can use the feature
    const isPaidAccount = await purchase.checkIsPaidAccount();
    // const permissions = await Glassfy.permissions();
    // and then check account is premium, trigger the super like
    // if not show modal upgrade account
    if (isPaidAccount) {
      onLike({
        isSuperLike: true,
        mode: mode,
        matchingScore: currentMatchScore,
      });
      setIsLoading(false);
    } else {
      setShowUpgradeModal(true);
    }

    // close toggle premium
    setToggleFab(false);
  };

  // check user permission is enabled
  // if enable, update the location with the latest one when user on swipe page
  // and then use the new location and calculate the distance on algorithm
  useEffect(() => {
    if (userAccount.enabledLocationServices) {
      getAndUpdateUserLocation();
    }
  }, []);

  const now = new Date();
  useEffect(() => {
    setBoostIsActive(userAccount.boostEndDate > now);
  }, []);

  // handle on boost feature for paid account
  const onBoost = async () => {
    // check account is PaidAccount for can use boost feature
    const isPaidAccount = await purchase.checkIsPaidAccount();
    if (isPaidAccount) {
      const account = userAccount as FreeAccount;
      if (!boostIsActive) {
        // request the account to be boosted
        account.boost().then(async (response) => {
          //then check if it was allowed
          if (response.boosted) {
            setBoostIsActive(true);

            // update the feature counter after boost
            await getFeatureCounter();
          } else {
            Dialog.alert({
              title: 'Boost not available',
              message: 'You have no more boosts left',
            });
          }
          //TODO: if not, we should show a user message
        });
        setIsLoading(false);
      } else {
        //when boost active cannot be clicked
        return;
      }
    } else {
      // if not show modal upgrade account
      setShowUpgradeModal(true);
    }

    // close more button features
    setToggleFab(false);
  };

  const resetBoost = () => {
    userAccount.boostEndDate = null;
    setBoostIsActive(false);
  };

  const renderBoostIcon = () => {
    // const now = new Date();
    // const isBoostActive = userAccount.boostEndDate > now;
    //the request also sent over the feature counter
    // let featureCounter = FeatureCounter.getOrCreateForAccount(auth.userAccount);
    // let disabled = featureCounter.remainingBoosts === 0;

    return boostIsActive ? <Icons.BoostActive /> : <Icons.Boost />;
  };

  useWatchProperty(userAccount, 'boostEndDate');

  // get feature counter for super like and boost
  useEffect(() => {
    getFeatureCounter();
  }, []);

  const getFeatureCounter = async () => {
    const featureCounter = await userAccount.getFeatureCounters();
    setRemainingFeatureCounter((prevState) => ({
      ...prevState,
      remainingSuperLikes: featureCounter.remainingSuperLikes,
      remainingBoosts: featureCounter.remainingBoosts,
    }));
  };

  // show swipe tour when user first time login
  const showSwipeTours = runSwipeTour && !isLoading;

  // handle swipe tour end
  const onSwipeTourEnd = () => {
    setRunSwipeTour(false);
  };

  return (
    <>
      {showSwipeTours && (
        <SwipeTourBasic
          runSwipeTour={runSwipeTour}
          onRunSwipeTour={onSwipeTourEnd}
        />
      )}

      <PageLayout className={styles.SwipeProfilesRoot} isFullscreen>
        <div
          className={`tourTapLeft ${cl(styles.swipeTap, styles.swipeTapLeft)}`}
        />
        <div
          className={`tourTapRight ${cl(styles.swipeTap, styles.swipeTapRight)}`}
        />
        {isLoading && (
          <div className={styles.upgradeAccountLoadingOverlay}>
            <Spinner />
          </div>
        )}
        {/* {runSwipeTour && <SwipeTour runSwipeTour={runSwipeTour} />} */}
        {(profiles?.size !== 0 || noProfilesToShow) && (
          <HeaderNavigation
            onFilterUpdated={refreshResults}
            showFilterMenu={true}
            moreVerticalSpace
          />
        )}
        {boostIsActive && userAccount.boostEndDate && (
          <CountdownTimer
            className={styles.countdown}
            startIcon={
              <img
                className={styles.countdownImage}
                src={asset('/images/boostColorsDarker.svg')}
              />
            }
            deadline={new Date(userAccount.boostEndDate)}
            onFinish={resetBoost}
          />
        )}

        {/* show upgrade account to premium when success  */}
        {upgradeAccountSuccess ? (
          <UpgradeAccountSuccess
            onAction={() => {
              setShowUpgradeModal(!showUpgradeModal);
              setUpgradeAccountSuccess(!upgradeAccountSuccess);
              navigate(ROUTES.index.path);
            }}
          />
        ) : (
          <CurrentPhotoContext.Provider value={{currentPhoto, setCurrentPhoto}}>
            <div className={cl(styles.SwipeProfiles)}>
              {showProfileDetails ? (
                <>
                  <div className={styles.currentProfileCardContainer}>
                    <ProfileCard
                      of={currentProfileRef.current}
                      pendingDislike={pendingDislike}
                      pendingLike={pendingLike}
                      hasSuperLike={hasSuperLike}
                      onClick={onToggleProfileDetails}
                    />
                  </div>
                  <ProfileDetail
                    of={currentProfile}
                    onToggleProfileDetails={onToggleProfileDetails}
                    onLike={() => {
                      onLike({mode: mode, matchingScore: currentMatchScore});
                    }}
                    matchScore={currentMatchScore}
                    onDislike={onDislike}
                    onClick={scrollToTop}
                  />
                </>
              ) : (
                <div className={styles.SliderContainer}>
                  {!profiles || !dndLoaded ? (
                    <Spinner />
                  ) : profiles.size > 0 ? (
                    <DndProvider
                      backend={TouchBackend}
                      options={{
                        enableMouseEvents: true,
                      }}
                    >
                      <Preview
                        of={currentlyVisiblePerson}
                        matchScore={currentMatchScore}
                        hasSuperLike={hasSuperLike}
                      />
                      {nextPerson && (
                        <DraggableProfileCard of={nextPerson} below={true} />
                      )}
                      <DropTarget
                        onDrop={onDrop}
                        onPendingLike={setPendingLike}
                        onPendingDislike={setPendingDislike}
                        onPendingDetails={setPendingDetails}
                        className={styles.DropTarget}
                      />
                      <DraggableProfileCard
                        of={currentlyVisiblePerson}
                        matchScore={currentMatchScore}
                        hasSuperLike={hasSuperLike}
                        onClick={onToggleProfileDetails}
                      />
                    </DndProvider>
                  ) : (
                    !noProfilesToShow && <Spinner />
                  )}
                  {noProfilesToShow && (
                    <div className={styles.outOfCards}>
                      <h2>No more playmates in this corner of the ocean</h2>
                      <p>
                        There is no more potential playmates for you at this
                        point in time for your current search.
                      </p>
                      <br />
                      <p>
                        Click on "..." in the top right to change who you are
                        looking for
                      </p>
                    </div>
                  )}
                </div>
              )}
              {!showProfileDetails && currentProfile && (
                <div className={styles.swipeMenu}>
                  <div className={styles.bigButtonsGroup}>
                    <Button
                      aria-label="rewind"
                      variant="text"
                      onClick={() => !disableRewind && onRewind()}
                    >
                      {disableRewind ? (
                        <Icons.DisableRewind className="tourRewind" />
                      ) : (
                        <Icons.OnRewind className="tourRewind" />
                      )}
                    </Button>
                    <Button
                      variant="text"
                      onClick={onDislike}
                      aria-label="dislike"
                      className={`${
                        pendingDislike ? styles.pendingDislike : ' '
                      } `}
                    >
                      <Icons.DislikeSwipe className="tourSwipeLeft" />
                    </Button>
                    <Button
                      id="swipeUp"
                      variant="text"
                      aria-label="show profile details"
                      onClick={onToggleProfileDetails}
                      className={`tourSwipeUp ${
                        pendingDetails ? styles.pendingDetails : ' '
                      }`}
                    >
                      <Icons.QuantumSwipe />
                    </Button>
                    <Button
                      variant="text"
                      aria-label="like"
                      onClick={() =>
                        onLike({mode: mode, matchingScore: currentMatchScore})
                      }
                      className={`${pendingLike ? styles.pendingLike : ' '}`}
                    >
                      <Icons.LikeSwipe className="tourSwipeRight" />
                    </Button>
                    <div
                      aria-label="more features"
                      className={`tourBoost ${styles.moreFeatureBtn}`}
                      onClick={handleFAB}
                      id={'boost'}
                    >
                      <Icons.MoreButton />
                      {toggleFab && (
                        <div
                          onMouseLeave={() => setMouseLeft(!mouseLeft)}
                          className={styles.smallButtonsGroup}
                        >
                          <Button
                            variant="text"
                            onClick={onSuperLike}
                            className={cl(
                              `${pendingDetails ? styles.pendingDetails : ' '}`,
                              styles.superLikeContainer,
                            )}
                            disabled={
                              remainingFeatureCounter?.remainingSuperLikes === 0
                            }
                          >
                            <img
                              src={asset('/images/union-white-circle.svg')}
                              alt="superlike"
                            />
                            <p className={styles.superLikeText}>
                              Quantum
                              <br />
                              like
                            </p>
                          </Button>
                          <Button
                            variant="text"
                            onClick={onBoost}
                            className={cl(
                              styles.boostContainer,
                              FeatureCounter.getOrCreateForAccount(
                                auth.userAccount,
                              )?.remainingBoosts === 0 &&
                                styles.featureDisabled,
                            )}
                            disabled={
                              remainingFeatureCounter?.remainingBoosts === 0
                            }
                          >
                            {renderBoostIcon()}
                            <p className={styles.boostText}>Boost</p>
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                  <Button
                    variant="text"
                    onClick={onToggleProfileDetails}
                    className={`${
                      pendingDetails ? `${styles.pendingDetails}` : ' '
                    }`}
                  >
                    <img
                      src={asset('/images/DownArrowSwipe.svg')}
                      alt="down-arrow-icon"
                      className={styles.offset}
                    />
                  </Button>
                </div>
              )}

              {/* upgrade account premium modal */}
              {upgradeAccountLoading && (
                <div className={styles.upgradeAccountLoadingOverlay}>
                  <Spinner />
                </div>
              )}

              <UpgradeAccountAction
                isOpen={showUpgradeModal}
                onUpgraded={(success) => {
                  setUpgradeAccountSuccess(success);
                  setShowUpgradeModal(false);
                }}
                isLoading={(loading) => {
                  setUpgradeAccountLoading(loading);
                  setIsLoading(loading);
                }}
                onSelected={(product) => setProductSelection(product)}
                onClose={() => {
                  setShowUpgradeModal(false);
                  setUpgradeAccountLoading(false);
                  setIsLoading(false);
                }}
              />

              {newMatch && (
                <NewMatchPopUp
                  isOpen={true}
                  matchingPerson={newMatch}
                  onClose={() => setNewMatch(null)}
                  mode={mode}
                />
              )}
            </div>
          </CurrentPhotoContext.Provider>
        )}
        <div className="checkout-container"></div>
      </PageLayout>
    </>
  );
};

const toSwipeAction = (moved): 'like' | 'dislike' | 'details' | '' => {
  if (moved) {
    //if the upwards swipe is more than 3 times the horizontal swipe
    let movedXAbs = Math.abs(moved.x);
    let minSwipe = 25;
    let minSwipeUp = 70;
    //if the card moved enough up, or left or right (but not down)
    if (-moved.y > minSwipe || movedXAbs > minSwipe) {
      //if the card moved more than 3 times up than left or right
      if (-moved.y > movedXAbs * 3 && -moved.y > minSwipeUp) {
        return 'details';
        // return {details:true};
      }
      //else, if we moved enough left or right
      else if (movedXAbs > minSwipe) {
        //if moved to the right
        if (moved.x > 0) {
          return 'like';
        }
        //else it moved to the left
        else {
          return 'dislike';
          // return {dislike: true};
        }
      }
    }
  }
  //not swiped enough yet for any action
  return '';
};

const DropTarget = ({
  onDrop,
  className,
  onPendingLike,
  onPendingDislike,
  onPendingDetails,
}: {
  onDrop;
  className;
  onPendingLike;
  onPendingDislike;
  onPendingDetails;
}) => {
  const [{pendingAction}, drop] = useDrop(
    () => ({
      accept: 'card',
      drop: (item, monitor) => {
        onDrop(monitor.getDifferenceFromInitialOffset());
      },
      collect: (monitor) => {
        return {
          pendingAction: toSwipeAction(
            monitor.getDifferenceFromInitialOffset(),
          ),
        };
      },
    }),
    [],
  );

  //we set the state based on what we collected from dnd in useEffect, because we cant do that during rendering
  useEffect(() => {
    onPendingDetails(false);
    onPendingLike(false);
    onPendingDislike(false);

    if (pendingAction === 'details') {
      onPendingDetails(true);
    } else if (pendingAction === 'like') {
      onPendingLike(true);
    } else if (pendingAction === 'dislike') {
      onPendingDislike(true);
    }
  }, [pendingAction]);

  return <div ref={drop} className={className} />;
};

const Preview = ({
  of,
  matchScore,
  hasSuperLike,
}: {
  of;
  matchScore?: number;
  hasSuperLike?: boolean;
}) => {
  //see https://github.com/LouisBrunner/dnd-multi-backend/tree/main/packages/react-dnd-multi-backend
  const preview = usePreview();
  if (!preview.display) {
    return null;
  }
  const pendingAction = toSwipeAction(
    preview.monitor.getDifferenceFromInitialOffset(),
  );

  const {itemType, item, style} = preview;
  return (
    <div style={style} className={styles.PreviewContainer}>
      <ProfileCard
        pendingLike={pendingAction === 'like'}
        pendingDislike={pendingAction === 'dislike'}
        pendingDetails={pendingAction === 'details'}
        of={of}
        matchScore={matchScore}
        hasSuperLike={hasSuperLike}
        className={styles.PreviewCard}
      />
    </div>
  );
};

const DraggableProfileCard = React.forwardRef(
  (
    {
      of,
      matchScore,
      hasSuperLike,
      clickThroughPhotos,
      below,
      onClick,
    }: {
      of;
      matchScore?: number;
      clickThroughPhotos?: boolean;
      hasSuperLike?: boolean;
      below?: boolean;
      onClick?: () => void;
    },
    ref,
  ) => {
    const [{isDragging}, drag] = useDrag(() => ({
      type: 'card',
      collect: (monitor) => {
        return {
          isDragging: !!monitor.isDragging(),
          isDropped: monitor.didDrop(),
          offset: monitor.getSourceClientOffset(),
        };
      },
    }));

    //we are now always using the Preview component to render the card whilst being dragged, so we need to hide the original card whilst its dragging
    if (isDragging) {
      return null;
    }

    return (
      <ProfileCard
        of={of}
        matchScore={matchScore}
        ref={drag}
        hasSuperLike={hasSuperLike}
        className={cl(styles.draggableProfile, below && styles.below)}
        textElevate={true}
        onClick={onClick}
      />
    );
  },
);
DraggableProfileCard.displayName = 'DraggableProfileCard';

export default SwipeProfiles;
