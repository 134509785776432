import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import {Typography} from './ui/forms/Typography';
import {default as style} from './NewMatchPopUp.scss.json';
import './NewMatchPopUp.scss';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../routes/AppRoutes';
import {Box} from './ui/forms/Box';
// import { Button } from './ui/forms/Button';
import {Button} from 'lincd-mui-base/lib/components/Button';
import ChevronRight from '@mui/icons-material/ChevronRight';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';

interface NewMatchPopUpProps {
  isOpen: boolean;
  onClose?: (value: boolean) => void;
  matchingPerson?: Person;
  mode?: string;
}

const NewMatchPopUp = ({
  isOpen,
  onClose,
  matchingPerson,
  mode,
}: NewMatchPopUpProps) => {
  const navigate = useNavigate();
  const auth = useAuth<Person, FreeAccount>();
  const user = auth.user;

  // go to the matches pages based on relationshipType
  const goToMatches = () => {
    if (mode === 'friendship') {
      navigate({
        pathname: ROUTES.matches_friend.path,
        search: '?q=matches',
      });
    } else {
      navigate({
        pathname: ROUTES.matches.path,
        search: '?q=matches',
      });
    }
  };

  return (
    <Backdrop
      className={style.PopupRoot}
      open={isOpen}
      onClick={() => onClose(false)}
    >
      <Box className={style.PopupWrapper}>
        <div>
          <img
            className={style.logo}
            src={asset('/images/WhiteQuantum.svg')}
            alt="quantum-icon"
            height="80px"
          />
          <Typography className={style.description}>
            There’s potential on a quantum scale.
          </Typography>
          <Typography className={style.subheading}>You have got a</Typography>
          <Typography className={style.heading}>New match!</Typography>
        </div>
        {/*<ProfileCard of={matchingPerson} className={style.profileCard} />*/}
        <Button
          className={style.button}
          onClick={goToMatches}
          color={'secondary'}
        >
          See your matches
          <ChevronRight fontSize="inherit" />
        </Button>
      </Box>
    </Backdrop>
  );
};

export default NewMatchPopUp;
