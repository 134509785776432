import React from 'react';
import './PlaceChip.scss';
import {default as style} from './PlaceChip.scss.json';
import {linkedComponent, registerPackageModule} from '../package';
import {Place} from 'lincd-schema/lib/shapes/Place';
import {default as PlaceIcon} from '@mui/icons-material/Place';
import {useStyles} from 'lincd/lib/utils/Hooks';
import {Chip} from './ui/forms/Chip';

interface PlaceChipProps extends React.ComponentProps<typeof Chip> {
  // variant?:'default'|'small'
}

export const PlaceChip = linkedComponent<Place, PlaceChipProps>(
  Place.request((place) => ({
    name: place.name,
  })),
  ({source, linkedData: {name}, children, ...props}) => {
    //TODO: get first and last item between commas
    let parts = name ? name.split(',') : [];
    let first = parts.shift();
    let last = parts.pop();
    props = useStyles(props, style.PlaceChip);
    return (
      <Chip
        label={first + (last ? ', ' + last : '')}
        icon={<PlaceIcon fontSize={'small'} />}
        variant={'outlined'}
        {...props}
      />
    );
  },
);

//register all components in this file
registerPackageModule(module);
