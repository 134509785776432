import React, {ReactNode} from 'react';
import './Container.scss';
import {default as style} from './Container.scss.json';
import {ROUTES} from '../../../routes/AppRoutes';
import {useLocation} from 'react-router-dom';
import {cl} from 'lincd/lib/utils/ClassNames';

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  className?: string;
}

export function Container({children, className, ...props}: ContainerProps) {
  const location = useLocation();

  // check home path to extend style
  const isResetPadding =
    location.pathname === ROUTES.index.path ||
    location.pathname === ROUTES.browse_dates.path ||
    location.pathname === ROUTES.browse_friends.path;

  return (
    <div
      className={cl(
        style.Container,
        isResetPadding && style.resetPadding,
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}
