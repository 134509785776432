import React, {ReactNode, useState} from 'react';
import FooterMenu from '../FooterMenu';
import AppHeader from '../layout/AppHeader';
import style from './ProfileLayout.scss.json';
import './ProfileLayout.scss';

interface ProfileLayoutProps {
  children: ReactNode;
  pageTitle?: string;
  showFooter?: boolean;
  onBack?: () => void;
}

/**
 * profile page layout
 */
const ProfileLayout = ({
  children,
  pageTitle,
  showFooter = true,
  onBack,
}: ProfileLayoutProps) => {
  const [appHeaderHeight, setAppHeaderHeight] = useState(0);
  // const appHeaderRef = useRef(null);

  // calculate and set the height of the AppHeader
  // useLayoutEffect(() => {
  //   const updateAppHeaderHeight = () => {
  //     if (appHeaderRef.current) {
  //       setAppHeaderHeight(appHeaderRef.current.clientHeight);
  //     }
  //   };
  //
  //   updateAppHeaderHeight();
  //   window.addEventListener('resize', updateAppHeaderHeight);
  //
  //   return () => {
  //     window.removeEventListener('resize', updateAppHeaderHeight);
  //   };
  // }, []);

  return (
    <React.Fragment>
      <AppHeader onBack={onBack} title={pageTitle} />
      <div className={style.layoutContainer}>{children}</div>
      {showFooter && <FooterMenu />}
    </React.Fragment>
  );
};

export default ProfileLayout;
