import React, {useEffect, useState} from 'react';
import './HeaderNavigation.scss';
import * as style from './HeaderNavigation.scss.json';
import SidebarNavigation from '../SidebarNavigation';
import SwipePreferenceFilter from '../SwipePreferenceFilter';
import {cl} from 'lincd/lib/utils/ClassNames';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';

type Props = {
  pageTitle?: string;
  onBack?: () => void;
  onFilterUpdated?: () => void; // reload profiles
  showFilterMenu?: boolean;
  moreVerticalSpace?: boolean; // more space for vertical padding top and bottom
  onBackButton?: () => void; // navigate back to previous page
};

function HeaderNavigation({
  pageTitle,
  onFilterUpdated,
  showFilterMenu,
  moreVerticalSpace,
  onBackButton,
}: Props) {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [filterMenuIsOpen, setFilterMenuIsOpen] =
    React.useState<boolean>(false);
  const [isHeaderActive, setIsHeaderActive] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      // check if the user has scrolled, and update the isHeaderActive state
      setIsHeaderActive(window.scrollY > 50);
    };

    // add scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  const toggleSearchFilter = () => {
    setFilterMenuIsOpen(!filterMenuIsOpen);
  };

  // if onBackButton is provided, navigate back to previous page
  const onBack = () => {
    if (onBackButton) {
      onBackButton();
    }
  };

  return (
    <div
      className={cl(
        style.headerBarContainer,
        moreVerticalSpace && style.moreVerticalSpace,
        isHeaderActive && style.active,
      )}
    >
      <div className={style.headerBar}>
        {onBackButton ? (
          <div
            onClick={onBack}
            className={cl(style.headerBarLeft, style.onBack)}
          >
            <img
              src={asset('/images/chevron-left.svg')}
              alt="navigation-back"
              className={style.logo}
            />
          </div>
        ) : (
          <div onClick={toggleMenu} className={style.headerBarLeft}>
            <img
              src={asset('/images/BurgerMenuIcon.svg')}
              alt="navigation-menu"
              className={style.logo}
            />
          </div>
        )}

        <div className={style.headerBarCenter}>
          {pageTitle && <h3>{pageTitle}</h3>}
        </div>
        <div>
          {showFilterMenu ? (
            <>
              <div
                className={`tourSearch ${style.headerBarRight}`}
                onClick={toggleSearchFilter}
                id="search"
              >
                <img
                  src={asset('/images/tripleDots.svg')}
                  alt="more-option"
                  className={style.tripleDots}
                />
              </div>
              <SwipePreferenceFilter
                open={filterMenuIsOpen}
                onDismiss={toggleSearchFilter}
                onApply={onFilterUpdated}
              />
            </>
          ) : (
            <div className={style.emptyIcon}>&nbsp;</div>
          )}
        </div>
        {menuIsOpen && (
          <SidebarNavigation onOpen={menuIsOpen} onDismiss={toggleMenu} />
        )}
      </div>
    </div>
  );
}

export default HeaderNavigation;
