import React from 'react';
import RadioChip from '../components/RadioChip';
import {familyPlans} from '../constants';
import {useNavigate} from 'react-router-dom';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import './Q8_FamilyPlans.scss';
import {QuestionContainer} from '../components/QuestionContainer';
import {QuestionHeader} from '../components/QuestionHeader';
import ContinueButton from '../components/ContinueButton';
import {ROUTES} from '../routes/AppRoutes';

const Q8_FamilyPlans = () => {
  const navigate = useNavigate();
  let auth = useAuth<Person, FreeAccount>();
  let user = auth.user;

  const setFamilyPlans = (val: string) => {
    user.familyPlans = val;
  };

  return (
    <QuestionContainer>
      <QuestionHeader>Family plans</QuestionHeader>
      <RadioChip
        label=""
        values={familyPlans}
        onChange={setFamilyPlans}
        currentValue={user.familyPlans}
      ></RadioChip>
      <ContinueButton onClick={() => navigate(ROUTES.q9_passions.path)} />
    </QuestionContainer>
  );
};

export default Q8_FamilyPlans;
