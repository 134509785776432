import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {ROUTES} from '../routes/AppRoutes';

const Home = () => {
  let auth = useAuth<Person, FreeAccount>();
  let navigate = useNavigate();

  useEffect(() => {
    // redirect to welcome page if user not complete profile setup
    if (!auth.userAccount || !auth.userAccount.profileSetupCompleted) {
      navigate(ROUTES.welcome.path);
    } else {
      // if user authenticated and has complete profile setup
      // and then redirect user to browse dating or friends based on user relationshipType
      if (auth.user.relationshipType === 'friendship') {
        navigate(ROUTES.browse_friends.path);
      } else {
        navigate(ROUTES.browse_dates.path);
      }
    }
  }, []);

  return null;
};

export default Home;
