import './UpgradeAccountAction.scss';
import {default as style} from './UpgradeAccountAction.scss.json';
import ActionSheet from './ui/forms/ActionSheet';
import {Button} from './ui/forms/Button';
import {packageName} from '../package';
import {Offer} from 'lincd-schema/lib/shapes/Offer';
import {Order} from 'lincd-schema/lib/shapes/Order';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import SubscriptionCard from './SubscriptionCard';
import Divider from './Divider';
import React, {useRef, useEffect, useState} from 'react';
import {Glassfy, GlassfyOfferings, GlassfySku} from 'capacitor-plugin-glassfy';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {PaidAccountTier1} from 'lincd-dating/lib/shapes/PaidAccountTier1';
import {Dialog} from '@capacitor/dialog';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {Capacitor} from '@capacitor/core';
import {Link, useLocation} from 'react-router-dom';
import Spinner from './Spinner';
import {ROUTES} from '../routes/AppRoutes';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';
import {TextField} from 'lincd-input/lib/components/TextField';
import {Modal} from 'lincd-mui-base/lib/components/Modal';
import {
  getOfferingByDiscountCode,
  getPermissionById,
  getProductType,
} from '../libs/glassfy';
import {FacebookEvents} from 'capacitor-facebook-events';
declare global {
  interface Window {
    Paddle: any; // Define the type of Paddle object here if possible
  }
}

let SUBSCRIPTION_DATA = [
  {
    id: 0,
    purchaseID: 'P1M',
    duration: '1 Month',
    monthlyPrice: 24.99,
    totalPrice: 24.99,
    discount: 0,
  },
  {
    id: 1,
    purchaseID: 'P6M',
    duration: '6 Month',
    monthlyPrice: 12.5,
    totalPrice: 69.99,
    discount: 50,
    review: 'Popular',
  },
  {
    id: 2,
    purchaseID: 'P1Y',
    duration: '1 Year',
    monthlyPrice: 8.33,
    totalPrice: 99.99,
    discount: 67,
    review: 'Best value',
  },
];

const GLASSFY_OFFERING = {
  premium: 'premium',
  discount: 'discounted_product',
};

interface UpgradeAccountActionProps {
  isOpen: boolean;
  onUpgraded?: (success: boolean) => void;
  onClose: () => void;
  onSelected?: (selected: any) => void;
  onCoupon?: (discountCode: string) => void;
  isLoading?: (loading: boolean) => void;
}
export const UpgradeAccountAction = ({
  isOpen,
  onClose,
  onUpgraded,
  isLoading,
  onCoupon,
  onSelected,
}: UpgradeAccountActionProps) => {
  //to select subscription card

  const [promoCode, setPromoCode] = useState<string>('');
  const [selectedCard, setSelectedCard] = useState(SUBSCRIPTION_DATA[0]);
  const [errorPromo, setErrorPromo] = useState<string>('');
  const [discountCode, setDiscountCode] = useState<string>('');
  const [dataSubscription, setDataSubscription] =
    useState<any>(SUBSCRIPTION_DATA);
  //state to horizontal scroll
  const sliderRef = useRef(null);

  const selectedCardRef = useRef(selectedCard);

  // upgrade account
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [productOffering, setProductOffering] = useState<any>();
  const [expireDate, setExpireDate] = useState<Date>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // download app modal
  const [openModal, setOpenModal] = useState<boolean>(false);

  const expireDateRef = useRef(expireDate);
  const auth = useAuth<Person, FreeAccount | PaidAccountTier1>();
  const account = auth.userAccount;
  const location = useLocation();

  expireDateRef.current = expireDate;
  let user = auth.user;

  //function that automatically focuses on selected card by horizontally scrolling it
  const horizontalScroll = () => {
    if (sliderRef.current) {
      const containerWidth = sliderRef.current.clientWidth;
      const cardWidth = sliderRef.current.children[0].clientWidth; // Assuming all cards have the same width

      const scrollOffset =
        selectedCardRef.current.id * cardWidth -
        containerWidth / 4 +
        cardWidth / 4;

      sliderRef.current.scrollTo(scrollOffset, 0);
    }
  };

  // get offering from Glassfy
  // only available on mobile
  async function getOffering() {
    if (Capacitor.getPlatform() !== 'web') {
      try {
        const offering = await getOfferingByDiscountCode(discountCode);

        offering?.skus.forEach((sku) => {
          if (!selectedCard) {
            // set the first card as default selected card
            if (sku.product.period === 'P1M') {
              setProductOffering(sku);
            }
          } else {
            // set the selected card
            if (selectedCard.purchaseID === sku.product.period) {
              setProductOffering(sku);
            }
          }
        });
      } catch (e) {
        console.log('error', e);
      }
    }
  }

  // Get Expire Date from Play Console and put it to our server
  async function getExpirySubscription() {
    try {
      let expireDate;
      const permissionId = getProductType(discountCode);
      const permission = await getPermissionById(permissionId);

      if (permission) {
        expireDate = parseInt(permission.expireDate) * 1000; // convert to epoch time
      }

      if (expireDate) {
        return expireDate;
      }
    } catch (error) {
      console.error('Error getting subscription data:', error);
      throw error;
    }
  }

  const onSelectSubscriptionCard = (data) => {
    setSelectedCard(data);
    selectedCardRef.current = data;
    horizontalScroll();
  };

  const onShowPurchasingAlert = async (text?: string) => {
    Dialog.alert({
      title: 'Sorry for your inconvenience',
      message:
        text === 'cancelPurchase'
          ? 'You have cancel your purchased.\nPlease try again!'
          : "Current version still doesn't support purchasing via website. Open this app via mobile to finish your purchase",
    });
  };

  // scroll to selected card when first render
  // and when the selected card is changed
  useEffect(() => {
    horizontalScroll();

    if (onSelected) {
      onSelected(selectedCard);
    }
  }, [selectedCard, isOpen]);

  // fetch offering from Glassfy when the discount code is applied
  // or when the selected card is changed
  // or when the action sheet is opened
  // and only available on mobile
  useEffect(() => {
    if (isOpen) {
      getOffering();
    }
  }, [isOpen, discountCode, selectedCard]);

  // upgrade account without payment, for testing purpose
  const upgradeAccountWithoutPayment = async () => {
    try {
      const platform = Capacitor.getPlatform();
      // now + 10 minutes
      const expireDate = new Date(Date.now() + 10 * 60 * 1000);
      const expireDateString = expireDate.toISOString();

      const result = await Server.call(
        packageName,
        'upgradeAccount',
        expireDateString,
        discountCode,
        platform,
      );
      console.log('upgradeAccountWithoutPayment', result);

      if (result.auth) {
        auth.updateAuth(result.auth);
        onUpgraded(true);
      }
    } catch (error) {
      console.error('Error upgrading account:', error);
    } finally {
      isLoading && isLoading(false);
      setLoading(false);
    }
  };

  async function upgradeAccountNow(promotionCode?: string) {
    const platform = Capacitor.getPlatform();
    try {
      isLoading(true);
      let expireDates = await getExpirySubscription();

      const result = await Server.call(
        packageName,
        'upgradeAccount',
        expireDates,
        promotionCode,
        platform,
      );

      if (result.auth) {
        auth.updateAuth(result.auth);
        onUpgraded(true);
        // Logged event for purchase premium account (sucessfull) - Facebook
        FacebookEvents.logEvent({
          event: 'add_payment_info',
          params: {
            success: 1,
          },
        });
      }
    } catch (error) {
      console.error('Error upgrading account:', error);
      // Logged event for purchase premium account (failed) - Facebook
      FacebookEvents.logEvent({
        event: 'add_payment_info',
        params: {
          success: 0,
        },
      });
    } finally {
      isLoading && isLoading(false);
    }
  }

  const checkPromoCode = async () => {
    setDiscountCode('');
    setErrorPromo('');
    try {
      const result = await Server.call(
        packageName,
        'applyPromoCode',
        promoCode,
        user,
      );

      if (result.error) {
        setErrorPromo(result.error); // Set error message from the error object
        // Restore original data
        setDataSubscription(SUBSCRIPTION_DATA);
      } else {
        const updatedData = dataSubscription.map((card) => {
          const discountPercent = 100;
          if (card.id === selectedCard.id) {
            // const discountedPrice =
            //   card.totalPrice - card.totalPrice * (discountPercent / 100);
            return {
              ...card,
              totalPrice: card.totalPrice,
              discount: discountPercent,
            };
          }
          return card;
        });

        setDiscountCode(result.promoCodeActive.code);
        setDataSubscription(updatedData);
      }
    } catch (error) {
      console.error('Invalid Promo Code:', error);
      setErrorPromo('Invalid Promo Code');
    }
  };

  const handleUpgradeAccount = async () => {
    isLoading && isLoading(true);
    setLoading(true);

    // upgrade account without payment, for testing purpose
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment) {
      upgradeAccountWithoutPayment();
      return;
    }

    // show modal for purchasing alert to inform user purchase via app
    if (Capacitor.getPlatform() === 'web') {
      setTimeout(() => {
        onClose();
        setOpenModal(true);
        setLoading(false);
      }, 800);

      return;
    }

    async function purchaseProduct() {
      try {
        const transaction = await Glassfy.purchaseSku({
          sku: productOffering,
        });

        if (transaction) {
          // log event for purchase subscription - Facebook
          FacebookEvents.logEvent({
            event: 'purchase',
            params: {
              content_id: productOffering.skuId,
              content_type: productOffering.productId,
              currency: productOffering.product.currencyCode,
              _valueToSum: productOffering.product.price,
            },
          });

          // Determine permission ID based on discount code
          // const permissionId = discountCode ? 'discounted_product' : 'premium';
          const permissionId = getProductType(discountCode);

          // Find the relevant permission in the transaction object
          const permission = transaction.permissions.all.find(
            (p) => p.permissionId === permissionId && p.isValid,
          );

          if (permission && permission.isValid) {
            // If permission is valid, proceed with account upgrade
            await upgradeAccountNow(discountCode);
          } else {
            // error handling for invalid permission
            Dialog.alert({
              title: 'Sorry for your inconvenience',
              message: 'Invalid permission. Please contact support for help.',
            });
          }
        }
      } catch (e) {
        console.log('purchaseProduct', e.code);
        if (e.code === 'UNIMPLEMENTED') {
          console.log('Cannot be used on web');
        } else {
          // Show purchasing alert for other errors
          onShowPurchasingAlert('cancelPurchase');
        }
      } finally {
        // Hide loading indicators
        isLoading && isLoading(false);
        setLoading(false);

        // close modal after all process completed
        onClose();
      }
    }
    // Call purchaseProduct function to initiate purchase process
    purchaseProduct();
  };

  useEffect(() => {
    if (onClose) {
      isLoading && isLoading(false);
    }
  }, [onClose]);

  return (
    <>
      <ActionSheet
        isOpen={isOpen}
        onClose={() => {
          onClose();
          isLoading && isLoading(false);
        }}
      >
        {success ? (
          <div className={style.successOverlay}>
            <div className={style.successOverlay__content}>
              <h3>Your account has been upgraded!</h3>
              <hr />
              <p>
                A confirmation email will be sent to {account.email}.<br /> If
                you have any questions, please reach out through the contact
                page.
              </p>
            </div>
          </div>
        ) : (
          <>
            {error && <p>{error}</p>}
            <div className={style.pageContainer}>
              <div className={style.header}>
                <div className={style.iconContainer}>
                  <img
                    src={asset('/images/sp-premium.svg')}
                    alt="sp premium icon"
                  />
                </div>
                <h1>Spiritual Premium</h1>
              </div>
              <div className={style.contentContainer}>
                <h2>
                  Gift yourself some extra juju to manifest that match with
                  Spiritual Premium™️.
                </h2>
                <div className={style.carouselContainer}>
                  <p>Select a plan</p>
                  <div className={style.cardCarousel} ref={sliderRef}>
                    {dataSubscription.map((data, i) => {
                      return (
                        <SubscriptionCard
                          key={data.id}
                          isSelected={selectedCard.id === i}
                          duration={data.duration}
                          monthlyPrice={data.monthlyPrice}
                          totalPrice={data.totalPrice}
                          discount={data.discount}
                          review={data.review}
                          onClick={() => onSelectSubscriptionCard(data)}
                        />
                      );
                    })}
                  </div>
                </div>
                {/* <Divider text="or" /> */}
                <div className={style.promoContainer}>
                  <div>
                    <TextField
                      className={style.inputPromo}
                      placeholder="Enter promo code"
                      value={promoCode.toUpperCase()}
                      onChange={(e) => {
                        setPromoCode(e.target.value.toUpperCase());
                        // if user delete the value using backspace reset the data
                        if (e.target.value === '') {
                          setDiscountCode('');
                          setErrorPromo('');
                          setDataSubscription(SUBSCRIPTION_DATA);
                        }
                      }}
                    />
                    {discountCode !== '' && (
                      <img
                        src={asset('/images/checked.png')}
                        alt="promo icon"
                      />
                    )}
                    {errorPromo && (
                      <img
                        onClick={() => {
                          setPromoCode('');
                          setErrorPromo('');
                          setDataSubscription(SUBSCRIPTION_DATA);
                        }}
                        src={asset('/images/CloseIcon.png')}
                        alt="promo icon"
                      />
                    )}
                  </div>
                  <button
                    disabled={
                      Capacitor.getPlatform() === 'web' || promoCode === ''
                    }
                    className={style.promoCodeBtn}
                    onClick={checkPromoCode}
                  >
                    Apply code
                  </button>
                </div>
                {errorPromo && <p className={style.errorPromo}>{errorPromo}</p>}
                {discountCode !== '' && (
                  <div className={style.promoSuccessApply}>
                    <p>One Free Month Applied</p>
                  </div>
                )}
                <div className={style.benefits}>
                  <h6>Included with Spiritual Premium™️</h6>
                  <ul>
                    <li>
                      <p>Unlimited Rewinds</p>
                      <p>Undo mistaken swipes by rewinding time</p>
                    </li>
                    <li>
                      <p>1 Free Boost per week</p>
                      <p>Tilt the quantum field in your favor</p>
                    </li>
                    <li>
                      <p>5 Free Quantum Likes per week</p>
                      <p>Use some extra juju for that one special person</p>
                    </li>
                  </ul>
                </div>
              </div>
              <footer className={style.footer}>
                <div className={style.footerWrapper}>
                  <p className={style.footerWrapper__top}>
                    By tapping Continue, you will be charged, your subscription
                    will auto-renew for the same price and package length until
                    you cancel via App Store settings, and you agree to our{' '}
                    <Link to={ROUTES.terms_of_use.path}>Terms</Link>.
                  </p>
                  {/* fullwidth */}
                  {/* <Button fullWidth={true} className={style.continueBtn}>
            Continue
          </Button> */}
                  <div className={style.footerWrapper__bottom}>
                    <div className={style.footerWrapper__left}>
                      <img
                        src={asset('/images/sp-premium.svg')}
                        alt="sp premium icon"
                      />
                      <div className={style.text}>
                        <p>{selectedCard.duration}</p>
                        <p>${selectedCard.totalPrice} total</p>
                      </div>
                    </div>
                    <Button
                      className={style.footerWrapper__right}
                      onClick={handleUpgradeAccount}
                      disabled={loading || errorPromo !== ''}
                    >
                      <span>{loading ? <Spinner /> : 'Continue'}</span>
                    </Button>
                  </div>
                </div>
              </footer>
            </div>
          </>
        )}
      </ActionSheet>
      <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
        <div className={style.downloadAppModal}>
          <h3>Please upgrade through the app</h3>
          <p>
            Open the Spiritual Playmates app on your phone to upgrade your
            account
          </p>
          <div className={style.downloadAppGroup}>
            <a href="https://apps.apple.com/app/6450890433" target="_blank">
              <img
                src={asset('/images/download-app-store.png')}
                alt="download on the app store"
              />
            </a>
            <a
              href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.spiritualplaymates.app"
              target="_blank"
            >
              <img
                src={asset('/images/download-google-play.png')}
                alt="get it on google play"
              />
            </a>
          </div>
        </div>
      </Modal>
    </>
  );
};
