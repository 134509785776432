import React, {lazy, Suspense} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {RequireAuth} from 'lincd-auth/lib/components/RequireAuth';
import Spinner from '../components/Spinner';
import {useSnackbar} from '../hooks/snackbar';

//In React 18 you can use 'lazy' to import pages only when you need them.
//This will cause webpack to create multiple bundles, and the right one is automatically loaded

export const ROUTES = {
  index: {
    path: '/',
    component: lazy(() => import('../pages/Home' /* webpackPrefetch: true */)),
    requireAuth: true,
  },
  signin: {
    path: '/signin',
    component: lazy(
      () => import('../pages/Signin' /* webpackPrefetch: true */),
    ),
  },
  signup: {
    path: '/signup',
    component: lazy(
      () => import('../pages/Signup' /* webpackPrefetch: true */),
    ),
  },
  coupons: {
    path: '/admin/coupons',
    component: lazy(
      () => import('../pages/Coupons' /* webpackPrefetch: true */),
    ),
  },
  welcome: {
    path: '/welcome',
    component: lazy(() => import('../pages/Welcome')),
    requireAuth: true,
  },
  house_rules: {
    path: '/house-rules',
    component: lazy(() => import('../pages/HouseRules')),
    requireAuth: true,
  },
  q1_name: {
    path: '/name',
    component: lazy(
      () => import('../pages/Q1_Name' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  q2_photo: {
    path: '/photo',
    component: lazy(
      () => import('../pages/Q2_Photo' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  q2_photos: {
    path: '/photos',
    component: lazy(
      () => import('../pages/Q2_Photos' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  q3_birth: {
    path: '/birth',
    component: lazy(
      () => import('../pages/Q3_Birth' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  q4_gender: {
    path: '/gender',
    component: lazy(
      () => import('../pages/Q4_Gender' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  q5_relationship_style: {
    path: '/relationship-style',
    component: lazy(
      () => import('../pages/Q5_RelationshipStyle' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  q6_looking_for: {
    path: '/looking-for',
    component: lazy(
      () => import('../pages/Q6_LookingFor' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  q6_looking_for_friendship: {
    path: '/looking-for-friendship',
    component: lazy(
      () =>
        import('../pages/Q6_LookingForFriendship' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  q7_relationship_goal: {
    path: '/relationship-goal',
    component: lazy(
      () => import('../pages/Q7_RelationshipGoal' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  q8_family_plans: {
    path: '/family-plans',
    component: lazy(
      () => import('../pages/Q8_FamilyPlans' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  q9_passions: {
    path: '/passions',
    component: lazy(
      () => import('../pages/Q9_Passions' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  q10_based_in: {
    path: '/based-in',
    component: lazy(
      () => import('../pages/Q10_BasedIn' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  q11_notifications: {
    path: '/telepathy',
    component: lazy(
      () => import('../pages/Q11_Telepathy' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  q12_locationenabler: {
    path: '/location-enabler',
    component: lazy(
      () => import('../pages/Q12_LocationEnabler' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  q_sexual_orientation: {
    path: '/sexual-orientation',
    component: lazy(
      () => import('../pages/Q_SexualOrientation' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  setup_complete: {
    path: '/setup-complete',
    component: lazy(
      () => import('../pages/SetupComplete' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },

  profile: {
    path: '/profile',
    component: lazy(
      () => import('../pages/Profile' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  edit_profile: {
    path: '/edit-profile',
    component: lazy(
      () => import('../pages/EditProfile' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  edit_profile_detail: {
    path: '/edit-profile/:slug',
    component: lazy(
      () => import('../pages/EditProfileDetail' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  terms_of_use: {
    path: '/terms',
    component: lazy(
      () => import('../pages/TermsOfUse' /* webpackPrefetch: true */),
    ),
  },
  privacy_policy: {
    path: '/privacy-policy',
    component: lazy(
      () => import('../pages/PrivacyPolicy' /* webpackPrefetch: true */),
    ),
  },
  admin: {
    path: '/admin',
    requireAuth: true,
    component: lazy(() => import('../pages/Admin' /* webpackPrefetch: true */)),
  },
  matches: {
    path: '/matches-and-likes',
    component: lazy(
      () => import('../pages/Matches' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  matches_friend: {
    path: '/friends-matches-likes',
    component: lazy(
      () => import('../pages/MatchesFriends' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  chat: {
    path: '/chat',
    component: lazy(() => import('../pages/Chat' /* webpackPrefetch: true */)),
    requireAuth: true,
  },
  community: {
    path: '/community',
    component: lazy(
      () => import('../pages/Community' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  contact: {
    path: '/contact',
    component: lazy(
      () => import('../pages/Contact' /* webpackPrefetch: true */),
    ),
  },
  magic_login_callback: {
    path: '/auth/magiclogin/verify',
    component: lazy(
      () => import('../pages/MagicLoginCallback' /* webpackPrefetch: true */),
    ),
  },
  reset_password_callback: {
    path: '/auth/reset-password',
    component: lazy(
      () =>
        import(
          'lincd-auth/lib/components/ForgotPasswordCallback' /* webpackPrefetch: true */
        ),
    ),
  },
  browse_dates: {
    path: '/browse-dates',
    component: lazy(() => import('../pages/BrowseDates')),
    requireAuth: true,
  },
  browse_friends: {
    path: '/browse-friends',
    component: lazy(() => import('../pages/BrowseFriends')),
    requireAuth: true,
  },
  events: {
    path: '/events',
    component: lazy(() => import('../pages/Events')),
    requireAuth: true,
  },
  users: {
    path: '/admin/users',
    component: lazy(() => import('../pages/Users')),
    requireAuth: true,
  },
  user: {
    path: '/admin/users/:accountUri',
    component: lazy(() => import('../pages/User')),
    requireAuth: true,
  },
};

export default function AppRoutes() {
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const onPasswordIsReset = () => {
    navigate(ROUTES.signin.path);
    snackbar.openSnackbar({
      message: 'Password is successfully reset! You are now logged in.',
      type: 'success',
      position: 'bottom-center',
      duration: 5000,
    });
  };

  return (
    <Routes>
      {Object.keys(ROUTES).map((routeName) => {
        let route = ROUTES[routeName];
        let Wrapper = route.requireAuth ? RequireAuth : React.Fragment;
        let Component = route.component;
        return (
          <Route
            key={route.path}
            path={route.path}
            element={
              <Wrapper>
                <Suspense fallback={<Spinner />}>
                  <Component
                    {...(routeName === 'reset_password_callback' && {
                      onPasswordIsReset: onPasswordIsReset,
                    })}
                  />
                </Suspense>
              </Wrapper>
            }
          />
        );
      })}
    </Routes>
  );
}
