import React, {HTMLAttributes, Ref} from 'react';
import {Link, useMatch} from 'react-router-dom';
import './FooterMenu.scss';
import style from './FooterMenu.scss.json';
import {Container} from './ui/forms/Container';
import {useFirebase} from 'lincd-notify/lib/hooks/useFirebase';
import {cl} from 'lincd/lib/utils/ClassNames';
import Notification from './ui/forms/Notification';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {ROUTES} from '../routes/AppRoutes';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';

const FOOTER_MENU = [
  {
    name: 'contact',
    url: '/contact',
    icon: '/images/FooterSupport.svg',
    width: '32px',
    color: 'pink',
  },
  {
    name: 'profile icon',
    url: '/profile',
    icon: '/images/UserFooter.svg',
    width: '30px',
    color: 'pink',
  },
  {
    name: 'home',
    url: '/',
    exact: true,
    icon: '/images/SPFooter.svg',
    width: '32px',
    color: 'blue',
  },
  {
    name: 'matches',
    url: '/matches-and-likes',
    icon: '/images/HeartFooter.svg',
    width: '32px',
    color: 'blue',
  },
  {
    name: 'chat',
    url: '/chat',
    icon: '/images/ChatFooter.svg',
    width: '32px',
  },
];

const FooterMenu = React.forwardRef<HTMLDivElement>((props, ref) => {
  const auth = useAuth<Person>();
  const user = auth.user;

  const isFriendshipMode = user?.relationshipType === 'friendship';

  return (
    <div className={style.FooterMenuRoot} ref={ref}>
      <Container className={style.wrapper}>
        <div className={style.FooterMenu}>
          {FOOTER_MENU.map((menu, i) => {
            // change the button like url based on mode
            let url;
            if (menu.name === 'matches') {
              if (isFriendshipMode) {
                url = `${ROUTES.matches_friend.path}?q=likes`;
              } else {
                url = `${ROUTES.matches.path}?q=likes`;
              }
            } else {
              url = menu.url; // default URL for not matches url
            }

            return (
              <FooterMenuNav
                key={i}
                name={menu.name}
                exact={menu.exact}
                url={url}
                icon={asset(menu.icon)}
                width={menu.width}
                color={menu.color}
              />
            );
          })}
        </div>
      </Container>
    </div>
  );
});

export default FooterMenu;

FooterMenu.displayName = 'FooterMenu';

interface FooterMenuNavProps {
  name: string;
  url: string;
  icon: string;
  exact?: boolean;
  width?: string;
  color?: string;
}

const FooterMenuNav = ({
  name,
  url,
  icon,
  width,
  exact,
  color,
}: FooterMenuNavProps) => {
  const {badgeCount, setBadgeCount} = useFirebase();
  const active = useMatch({
    path: url,
    end: exact,
  });

  /**
   * reset count to 0 after user open the matches page
   */
  const handleOpenBadge = () => {
    setBadgeCount(0);
  };

  if (name === 'matches') {
    return (
      <>
        {/*{badgeCount > 0 && <Notification isActive={active} />}*/}
        <Link
          to={url}
          className={cl(
            style.container,
            active ? style.active : style.inactive,
          )}
          onClick={handleOpenBadge}
        >
          {badgeCount > 0 && <Notification isActive={active} />}
          <img src={icon} alt={name} width={width ? width : 'auto'} />
        </Link>
      </>
    );
  }

  return (
    <Link
      to={url}
      className={cl(
        style.container,
        active ? style.active : style.inactive,
        color && style[color],
      )}
    >
      <img src={icon} alt={name} width={width ? width : 'auto'} />
    </Link>
  );
};
