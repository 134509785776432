import './ContinueButton.scss';
import style from './ContinueButton.scss.json';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {useEffect} from 'react';
import {cl} from 'lincd/lib/utils/ClassNames';

interface ContinueButtonProps {
  text?: string;
  onClick?: () => void;
  withBackground?: boolean;
}

const ContinueButton = ({
  text,
  onClick,
  withBackground,
}: ContinueButtonProps) => {
  useEffect(() => {
    if (withBackground && text) {
      //restart body animation
      var el = document.body;
      el.style.animation = 'none';
      el.offsetHeight; /* trigger reflow */
      el.style.animation = null;
    }
  }, [withBackground, text]);

  return (
    <div
      className={cl(
        style.ContinueButton,
        text && withBackground && style.withBackground,
      )}
    >
      {text && <Typography variant="body1">{text}</Typography>}
      <IconButton
        className={style.chevronNext}
        aria-label="ChevronRight"
        size="large"
        onClick={onClick}
      >
        <ChevronRight fontSize="inherit" />
      </IconButton>
    </div>
  );
};

export default ContinueButton;
