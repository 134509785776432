import React from 'react';
import {Typography} from './ui/forms/Typography';
import './QuestionSubheader.scss';
import {default as style} from './QuestionSubheader.scss.json';

export function QuestionSubheader(props) {
  return (
    <Typography className={style.QuestionSubheader} variant="h6" {...props} />
  );
}
