export const beliefs = [
  'Adonai',
  'Allah',
  'Creative Source (female)',
  'Creative Source (male)',
  'Creative Source (male/female)',
  'El-Shaddai',
  'Elohim',
  'God',
  'Goodness',
  'Infinite',
  'Jah',
  'Jehovah',
  'Jehovah-shalom',
  'Love',
  'Omnipotent',
  'Universal Energy Center',
  'Yahweh',
  'Other',
];

export const systemIdeology = [
  'African Religion',
  'Anatolian Religion',
  'Ancient Iranian Religion',
  'Arabian Religion',
  'Bahai',
  'Baltic Religion',
  'Buddhism',
  'Calvinism',
  'Catholicism',
  'Celtic Religion',
  'Christianity',
  'Confucianism',
  'Eastern Orthodoxy',
  'Eastern Rite Church',
  'Egyptian Religion',
  'Finno - Ugric Religion',
  'Germanic Religion and Mythology',
  'Greek Religion',
  'Hellenistic Religion',
  'Hindu',
  'Hinduism',
  'Humanism',
  'Islam',
  'Islamic',
  'Jainism',
  'Judaism',
  'Mesopotamian Religion',
  'Middle Eastern Religion',
  'Mormon',
  'Native American Religions',
  'Nature',
  'Neo - Paganism',
  'New Age',
  'Non Duality',
  'Old Catholic Church',
  'Orphic Religion',
  'Other Planetary',
  'Pagan',
  'Protestant Heritage',
  'Protestantism',
  'Roman Catholicism',
  'Roman religion',
  'Shintō; Sikhism',
  'Slavic Religion',
  'Spiritual but not Religious',
  'Syrian and Palestinian Religion',
  'Unitarian',
  'Unity',
  'Urantia Book Teachings',
  'Vedic Religion',
  'Wicca',
  'Wiccan',
  'Zoroastrianism',
  'Agnosticism',
  'Humanism',
  'Monotheism',
  'Mystery Religion',
  'New Religious Movement',
  'Pantheism',
  'Polytheism',
  'Prehistoric Religion',
  'Theism',
];
export const passions = [
  'Healthy eating',
  'Walking, hiking, or trekking',
  'Running',
  'Biking',
  'Swimming',
  'Self-defense',
  'Sports',
  'Resistance training (using weights or bodyweight)',
  'Yoga',
  'Meditation',
  'Self-care (massage, grooming, personal hygiene, etc.)',
  'Researching or experimenting',
  'Reading self-help books or taking online courses',
  'Learning new skills',
  'Practicing',
  'Journaling',
  'Learning how to learn',
  'Knowledge',
  'Wisdom',
  'Personal finance',
  'Business and entrepreneurship',
  'Work performance and productivity',
  'Leadership',
  'Coaching',
  'Training or teaching',
  'Donating',
  'Volunteering',
  'Writing',
  'Speaking',
  'Painting, sketching, or crafting',
  'Knitting and Crocheting',
  'Designing',
  'Creating or Editing',
  'Building or repairing',
  'Collecting or organizing',
  'Decluttering or cleaning',
  'Playing an instrument',
  'Singing',
  'Dancing',
  'Comedy, act or entertainment',
  'Reading fiction',
  'Watching (movies, TV series, documentaries)',
  'Listening (to music, podcasts, audiobooks)',
  'Reviewing',
  'Making and composing',
  'Photography',
  'Event hosting or celebration',
  'Programming',
  'Playing or gaming',
  'Cooking',
  'Gardening',
  'Animals',
  'Nature',
  'Travel',
  'Adventure sports',
];

export const associateSameBeliefs = ['Yes', 'No', 'Flexible'];

export const coreValues = [
  'Appropriate',
  'Balanced',
  'Beauty',
  'Capable',
  'Centered',
  'Cheerful',
  'Civil',
  'Common Sense',
  'Communicative',
  'Compassionate',
  'Confident',
  'Considerate',
  'Consistent',
  'Cooperative',
  'Courageous',
  'Creative',
  'Curious',
  'Decisive',
  'Dedicated',
  'Dutiful',
  'Efficient',
  'Encouraging',
  'Fair',
  'Faith',
  'Farsighted',
  'Finesse',
  'Flexible',
  'Focused',
  'Forgiving',
  'Free',
  'Friendly',
  'Funny',
  'Generous',
  'Gentle',
  'Goodness',
  'Gracious',
  'Grateful',
  'Growing',
  'Happy',
  'Harmonious',
  'Healthy',
  'Honest',
  'Honorable',
  'Hopeful',
  'Humble',
  'Imaginative',
  'Independent',
  'Integrity',
  'Intuitive',
  'Joyful',
  'Judicious',
  'Kind',
  'Knowledgeable',
  'Lighthearted',
  'Logical',
  'Love',
  'Loyal',
  'Merciful',
  'Methodical',
  'Noble',
  'Nurturing',
  'Objective',
  'Open',
  'Optimistic',
  'Patient',
  'Peaceful',
  'Persevering',
  'Playful',
  'Poised',
  'Powerful',
  'Practical',
  'Precise',
  'Prepared',
  'Productive',
  'Prompt',
  'Reflective',
  'Reliable',
  'Resilient',
  'Respectable',
  'Responsible',
  'Responsive',
  'Rugged',
  'Selfless',
  'Sensible',
  'Sensitive',
  'Service Minded',
  'Sharing',
  'Sincere',
  'Spiritual',
  'Stable',
  'Striving',
  'Strong',
  'Tactful',
  'Temperate',
  'Thoughtful',
  'Thriving',
  'Tolerant',
  'Trustworthy',
  'Truth',
  'Understanding',
  'Valuable',
  'Versatile',
  'Volitional',
  'Whimsical',
  'Whole',
  'Wholehearted',
  'Wise',
  'Wonder',
];

export const spiritualPractice = [
  'Alignment/ Centering Practices',
  'Art of Spiritual Living Studies',
  'Art',
  'Ascension studies',
  'Astral Travel',
  'Attending church service',
  'Be in nature',
  'Being in the Power of NOW',
  'Centering prayer (Thomas Keating Teaching)',
  'Chakra opening, clearing, work',
  'Channeling',
  'Communion',
  'Crystal gridwork',
  'Dance',
  'Doing readings',
  'Eat a healthy diet',
  'Ecstatic dance',
  'Element work',
  'Energy Work',
  'Entheogens/plant meds/psychedelics',
  'Healing',
  'Hike to tops of mountains',
  'Keep an altar',
  'Magic practices/ Majik',
  'Make vision boards',
  'Manifestation',
  'Meditation',
  'Nature',
  'Remote Viewing',
  'Spiritual Progress and Growth',
  'Tai Chi',
  'Tantra',
  'Transformational work',
  'Yoga',
  'Practice muscle testing or kinesiology',
  'Prayer',
  'Serve in community',
  'Swim in ocean',
  'Use dowsing or pendulum',
  'Use other divination tools',
  'Use runes',
  'Use tarot or oracle cards',
  'Worship',
];

export const sacredTexts = [
  'Bhagavad Gita',
  'Bible',
  'Buddhist Sutras',
  'Egyptian Book of the Dead',
  'Hafiz Poetry',
  'Other',
  'Poetry of Rumi',
  'Quran',
  'Tao Te Ching',
  'The Alchemist',
  'The Gnostic Gospels',
  'The Power of Now',
  'The Secret',
  'Torah',
  'Upanishads',
  'Urantia Book',
  'Vedas',
  'Way of the Spiritual Warrior',
];

export const gender = [
  'Woman',
  'Man',
  'Non-binary',
  'A couple',
  'Trans',
  'Other',
  'Prefer not to say',
];
export const smokingHabits = [
  'Non-smoker',
  'Social smoker',
  'Smoker when drinking',
  'Smoker',
  'Trying to quit',
];

export const sexuality = [
  'queer',
  'pansexual',
  'lesbian',
  'homosexual',
  'heterosexual',
  'gay',
  'bisexual',
  'asexual',
  'prefer not to say',
];
export const drinkingFrequencies = [
  'Not for me',
  'Sober',
  'Sober curious',
  'On special Occasions',
  'Socially on weekends',
  'Most nights',
];

export const fourTwentyFriendlyValues = [
  'Yes',
  'Occasionally',
  'Socially',
  'Never',
];

export const workoutFrequencies = ['Everyday', 'Often', 'Sometimes', 'Never'];

export const relationshipStyleValues = [
  'Friends and community',
  'Non-romantic',
  'Monogamous',
  'Polyamorous',
];
export const relationshipGoalValues = [
  'Relationship',
  'Something casual',
  "Don't know yet",
  'Spiritual bond',
  'Marriage',
];

export const interestedInGenderValues = ['Woman', 'Man', 'Anyone'];

export const personalityTypes = [
  'ENTJ',
  'ESFJ',
  'ENFJ',
  'ISTJ',
  'ISFJ',
  'INTJ',
  'INFJ',
  'ESTP',
  'ESFP',
  'ISTP',
  'ENTP',
  'ENFP',
  'ISFP',
  'INTP & INFP',
];

export const loveLanguages = [
  'Words of Affirmation',
  'Acts of Service',
  'Gift Giving',
  'Quality Time',
  'Physical Touch',
];

export const discoveryPaths = [
  '1-2 years',
  '3-5 years',
  '6-10 years',
  '10+',
  '20+',
  '40+',
];

export const sexualOrientation = [
  'Straight',
  'Gay',
  'Lesbian',
  'Bisexual',
  'Asexual',
  'Demisexual',
  'Pansexual',
  'Queer',
  'Questioning',
];

export const familyPlans = [
  'I have children and want more',
  'I want children',
  "I don't want children",
  "I have children and don't want more",
  'Not sure yet',
];

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const location = ['Bandung'];
export const notification = ['On', 'Off'];

export const timeOfDay = ['AM', 'PM'];

export const relationshipStyle = [
  {
    value: 'date',
    label: `<p><h3>Date</h3></p><p>Find that spark in an empowered community</p>`,
  },
  {
    value: 'friendship',
    label: `<p><h3>Friendship</h3></p><p>Make new friends at every stage of your life</p>`,
  },
  {
    value: 'both',
    label: `<p><h3>Both</h3></p><p>Let’s keep it interesting, I’m ready for serendipity</p>`,
  },
];
