import React from 'react';
import {Avatar} from '../ui/forms/Avatar';
import {Button} from '../ui/forms/Button';
import {Typography} from '../ui/forms/Typography';
import ChevronRight from '@mui/icons-material/ChevronRight';
import {slugify} from '../../utils/helper';
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';
import {CoreSet} from 'lincd/lib/collections/CoreSet';
import style from './ProfileQuestion.scss.json';
import './ProfileQuestion.scss';
import {NodeSet} from 'lincd/lib/collections/NodeSet';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {Place} from 'lincd-schema/lib/shapes/Place';
import {PlaceChip} from '../PlaceChip';
import {addTimezone} from '../../utils/DateTime';
import EditProfilePictures from '../EditProfilePictures';
import {RangeSlider} from 'lincd-mui-base/lib/components/RangeSlider';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';

interface ProfileQuestionProps {
  category: string;
  icon?: string;
  question: string;
  onDetail?: (val: string) => void;
  component: string;
  currentValue?: any;
  showPublic?: any;
}

const MAX_LENGTH_VALUE = 20;
const maxNumValues = 6;
const ProfileQuestion = ({
  category,
  icon,
  question,
  onDetail,
  component,
  currentValue,
}: ProfileQuestionProps) => {
  const handleDetail = () => {
    // convert to url. example: basic-info/?section=name
    const slug = `${slugify(category)}?section=${slugify(question)}`;
    onDetail(slug);
  };

  let displayValue = currentValue || null;

  if (displayValue instanceof NodeSet || displayValue instanceof ShapeSet) {
    //sort by label or value
    displayValue = displayValue.sort((a, b) => {
      if (a.label) {
        return a.label < b.label ? -1 : 1;
      }
      return a.value.localeCompare(b.value);
    });
  }

  return (
    <div className={style.ProfileQuestionContainer}>
      <div className={style.QuestionContainer}>
        <div className={style.Question}>
          {icon && (
            <img
              src={asset(`/images/${icon}.svg`)}
              alt="icon"
              width="20px"
              height="20px"
            />
          )}
          <Typography className={style.QuestionText}>{question}</Typography>
          {component === 'Uploader' && (
            <div>
              <EditProfilePictures />
            </div>
          )}
        </div>
        {component !== 'Uploader' && (
          <Button
            endIcon={<ChevronRight />}
            variant="text"
            onClick={() => handleDetail()}
            className={style.QuestionDetail}
          >
            {displayValue && (
              <>
                {component === 'Avatar' && (
                  <Avatar
                    variant="rounded"
                    src={displayValue?.cropped?.contentUrl}
                    className={style.AnswerAvatar}
                  />
                )}
                {component === 'Typography' && (
                  <Typography>
                    {displayValue.length > MAX_LENGTH_VALUE
                      ? displayValue.substring(0, MAX_LENGTH_VALUE) + '..'
                      : displayValue}
                  </Typography>
                )}
                {component === 'TypographyRange' && (
                  <Typography>
                    {displayValue[1] == 100
                      ? displayValue.toString().split(',')[0] + ' - ∞'
                      : displayValue.toString().split(',').join(' - ')}
                  </Typography>
                )}

                {component === 'Date' && (
                  <Typography>
                    {currentValue
                      ? dayjs(addTimezone(currentValue)).format(
                          'DD MMM YYYY HH:mm A',
                        )
                      : ''}
                  </Typography>
                )}
                {(component === 'Chip' ||
                  component === 'List' ||
                  component === 'SingleList') && <Typography>Edit</Typography>}
              </>
            )}
            {!displayValue && component !== 'TypographyBoolean' && (
              <Typography>Add</Typography>
            )}
            {component === 'TypographyBoolean' && (
              <Typography>{displayValue ? 'Active' : 'Inactive'}</Typography>
            )}
          </Button>
        )}
      </div>

      {component === 'Chip' &&
        displayValue instanceof CoreSet &&
        displayValue.size > 0 && (
          <div className={style.valuesContainer}>
            {displayValue.slice(0, maxNumValues).map((singleLiteralValue) => (
              <Chip
                key={singleLiteralValue.toString()}
                label={singleLiteralValue.value}
                size="small"
                variant="filled"
              />
            ))}
            {displayValue.size > maxNumValues && (
              <Chip label="..." size="small" variant="outlined" />
            )}
          </div>
        )}
      {component === 'List' &&
        displayValue instanceof CoreSet &&
        displayValue.size > 0 && (
          <div className={style.valuesContainer}>
            {displayValue &&
              displayValue.map((singleValue: any) => {
                if (singleValue instanceof Place) {
                  return (
                    <PlaceChip
                      of={singleValue}
                      key={singleValue.toString()}
                      variant="filled"
                      size="small"
                    />
                  );
                }
                console.warn(
                  'Not a Place. Implementation needs to be improved',
                  singleValue.toString(),
                );
                return (
                  <span key={singleValue.toString()}>{singleValue.label}</span>
                );
              })}
          </div>
        )}
      {component === 'SingleList' && displayValue && (
        <div className={style.valuesContainer}>
          <PlaceChip
            of={displayValue}
            key={displayValue.toString()}
            variant="filled"
            size="small"
          />
        </div>
      )}
      {component === 'RangeSlider' && displayValue && (
        <div className={style.valuesContainer}>
          <RangeSlider
            defaultMin={25} // default min value of thumb
            defaultMax={40} // default max value of thumb
            of={displayValue}
            minProperty={displayValue[0]}
            maxProperty={displayValue[1]}
            min={18} // min value of rail
            max={100} // max value of rail
            step={1}
          />
        </div>
      )}
    </div>
  );
};

export default ProfileQuestion;
