import React, {useState} from 'react';
import RadioChip from '../components/RadioChip';
import {relationshipStyle, relationshipStyleValues} from '../constants';
import {default as style} from './Q5_RelationshipStyle.scss.json';
import './Q5_RelationshipStyle.scss';
import {useNavigate} from 'react-router-dom';
import ContinueButton from '../components/ContinueButton';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {QuestionContainer} from '../components/QuestionContainer';
import {QuestionHeader} from '../components/QuestionHeader';
import {QuestionSubheader} from '../components/QuestionSubheader';
import {ROUTES} from '../routes/AppRoutes';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {CheckboxChip} from '../components/ui/forms/CheckboxChip';

const Q5_RelationshipStyle = () => {
  const navigate = useNavigate();
  let auth = useAuth<Person, FreeAccount>();
  let user = auth.user;

  // state for showing relationship style
  const [showRelationshipStyle, setShowRelationshipStyle] = useState<boolean>(
    user.relationshipType && user.relationshipType !== 'friendship',
  );
  // state for helper text
  const [helperTextMode, setHelperTextMode] = useState<string>('');
  const [helperText, setHelperText] = useState<string>('');

  const [bool, setBool] = useState<boolean>(false);
  const forceUpdate = () => setBool(!bool);

  const setMode = function (value: string) {
    // after changing "mode" clear the previously chosen relationship style
    if (user.relationshipType !== value) {
      user.relationshipStyle?.map((node) =>
        user.relationshipStyle?.delete(node),
      );
      forceUpdate();
    }

    user.relationshipType = value;

    // if user selects friendship, hide relationship style
    setShowRelationshipStyle(value !== 'friendship');

    // clear helper text
    setHelperText('');
    setHelperTextMode('');
  };

  const onChooseMode = () => {
    // show helper text if user not selected mode
    if (!user.relationshipType) {
      setHelperTextMode('Please choose a mode');
      return;
    }

    // show helper text if relationship style is not selected
    if (showRelationshipStyle && user.relationshipStyle.size === 0) {
      setHelperText('Please choose relationship style');
      return;
    }

    if (user.relationshipType == 'friendship') {
      navigate(ROUTES.q6_looking_for_friendship.path);
    } else {
      navigate(ROUTES.q6_looking_for.path);
    }
  };

  return (
    <div>
      <QuestionContainer>
        <QuestionHeader>Choose a mode to get started</QuestionHeader>
        <QuestionSubheader>
          Spiritual playmates is for making all kinds of connections! You’ll be
          able to switch modes once you’re all set up.
        </QuestionSubheader>
        <RadioChip
          values={relationshipStyle}
          onChange={setMode}
          className={style.Container}
          currentValue={user.relationshipType}
        />
        {helperTextMode && <p className={style.helpertext}>{helperTextMode}</p>}
        {showRelationshipStyle && (
          <div className={style.block}>
            <QuestionSubheader>Relationship Style</QuestionSubheader>
            <CheckboxChip
              values={relationshipStyleValues}
              currentValues={user.relationshipStyle}
            />
            {helperText && <p className={style.helpertext}>{helperText}</p>}
          </div>
        )}
      </QuestionContainer>

      <ContinueButton
        text={'You’ll only be shown to others in the same mode.'}
        onClick={onChooseMode}
      />
    </div>
  );
};

export default Q5_RelationshipStyle;
