import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import './Q12_LocationEnabler.scss';
import {QuestionContainer} from '../components/QuestionContainer';
import {QuestionHeader} from '../components/QuestionHeader';
import ContinueButton from '../components/ContinueButton';
import {ROUTES} from '../routes/AppRoutes';
import {QuestionSubheader} from '../components/QuestionSubheader';
import Spinner from '../components/Spinner';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {getAndUpdateUserLocation} from '../utils/permission';

const Q12_LocationEnabler = () => {
  const navigate = useNavigate();
  const auth = useAuth<Person, FreeAccount>();
  const userAccount = auth.userAccount;

  const [loading, setLoading] = useState(false);

  const onFinish = async () => {
    // trigger get location and update user data
    setLoading(true);
    getAndUpdateUserLocation().then((position) => {
      setLoading(false);
      if (position) {
        userAccount.enabledLocationServices = true;
      } else {
        userAccount.enabledLocationServices = false;
      }
      navigate(ROUTES.setup_complete.path);
    });
  };

  return (
    <QuestionContainer>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <QuestionHeader>Enable location</QuestionHeader>
          <QuestionSubheader>
            To find potential matches nearby, enable location services.
          </QuestionSubheader>
          <ContinueButton text="Continue" onClick={onFinish} />
        </>
      )}
    </QuestionContainer>
  );
};

export default Q12_LocationEnabler;
