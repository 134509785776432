import React from 'react';
import {default as MuiRadioGroup} from '@mui/material/RadioGroup';
import {default as style} from './RadioGroup.scss.json';
import './RadioGroup.scss';

export function RadioGroup(props) {
  return (
    <MuiRadioGroup className={style.RadioGroup} {...props}></MuiRadioGroup>
  );
}
