import React, {useRef} from 'react';
import './SidebarNavigation.scss';
import style from './SidebarNavigation.scss.json';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {useFirebase} from 'lincd-notify/lib/hooks/useFirebase';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import Notification from './ui/forms/Notification';
import {useCometChat} from 'lincd-cometchat/lib/hooks/useCometChat';
import {cl} from 'lincd/lib/utils/ClassNames';
import {Button} from './ui/forms/Button';
import {Avatar} from 'profile-plus/lib/components/Avatar';
import {ROUTES} from '../routes/AppRoutes';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';

type Props = {
  onDismiss: () => void;
  onOpen: boolean;
};

type MenuItem = {
  title: string;
  path: string;
  state?: {[key: string]: any};
  isActive?: boolean;
  notificationCount?: number;
  mode?: string;
  children?: MenuItem[];
};

const MENUS: MenuItem[] = [
  {
    title: 'Matching',
    path: '',
    mode: 'dating',
    children: [
      {
        title: 'Browse',
        path: '/browse-dates',
      },
      {
        title: 'Likes',
        path: '/matches-and-likes?q=likes',
      },
      {
        title: 'Matches',
        path: '/matches-and-likes?q=matches',
      },
    ],
  },
  {
    title: 'Friendship',
    path: '',
    mode: 'friendship',
    children: [
      {
        title: 'Browse',
        path: '/browse-friends',
      },
      {
        title: 'Likes',
        path: '/friends-matches-likes?q=likes',
      },
      {
        title: 'Matches',
        path: '/friends-matches-likes?q=matches',
      },
    ],
  },
  {
    title: 'Chat',
    path: '/chat',
  },
  {
    title: 'Profile',
    path: '/profile',
  },
  {
    title: 'Community',
    path: '/community',
  },
  {
    title: 'Events',
    path: '/events',
  },
  {
    title: 'Contact Support',
    path: '/contact',
  },
];

const SidebarNavigation = ({onDismiss, onOpen}: Props) => {
  // const [expanded, setExpanded] = React.useState<string | false>('');
  const sidebarRef = useRef<HTMLDivElement>(null);
  const {badgeCount, setBadgeCount, notification} = useFirebase();
  const chat = useCometChat();
  let auth = useAuth<Person, FreeAccount>();
  let user = auth.user;
  const [slideAnimation, setSlideAnimation] = React.useState<boolean>(false);
  const location = useLocation();
  let [searchParams] = useSearchParams();
  let navigate = useNavigate();

  // const handleChange =
  //   (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //     setExpanded(newExpanded ? panel : false);
  //   };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target as Node)
    ) {
      onDismiss();
    }
  };

  const onSignOut = () => {
    if (chat) {
      chat.logout();
    }
    auth.signout();
  };

  React.useEffect(() => {
    if (onOpen) {
      setSlideAnimation(true);
    } else {
      setSlideAnimation(false);
    }

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onOpen]);

  const showDatingNavigation =
    user?.relationshipType === 'date' || user?.relationshipType === 'both';

  const showFriendshipNavigation =
    user?.relationshipType === 'friendship' ||
    user?.relationshipType === 'both';

  return (
    <div className={style.ContainerSidebar}>
      <div className={style.overLay} onClick={onDismiss}></div>
      <div
        className={
          slideAnimation
            ? `${style.sidebarNavigation} ${style.open}`
            : style.sidebarNavigation
        }
        ref={sidebarRef}
      >
        <Button className={style.closeBtn} onClick={onDismiss}>
          <img src={asset('/images/CloseIcon.png')} alt="close button" />
        </Button>
        <div
          className={style.topSidebar}
          onClick={() => navigate(ROUTES.profile.path)}
        >
          <Avatar of={user} />
          <h3>
            {user?.givenName} {user?.familyName}
          </h3>
        </div>

        <div className={style.centerSidebar}>
          <div className={style.accordionSummary}>
            <ul className={style.listAccordName}>
              {MENUS.filter((menu: MenuItem) => {
                // check if the menu should be shown based on relationship style
                const shouldShowMenu =
                  (menu.title === 'Matching' && showDatingNavigation) ||
                  (menu.title === 'Friendship' && showFriendshipNavigation) ||
                  (menu.title !== 'Matching' && menu.title !== 'Friendship');
                return shouldShowMenu;
              }).map((menu: MenuItem, i: number) => {
                const isNavActive = location.pathname === menu.path;
                const panel = `panel${i}`;

                if (menu.children) {
                  return (
                    <MuiAccordion
                      key={i}
                      expanded={true}
                      defaultExpanded={true}
                      className={style.RootAccordion}
                      // onChange={handleChange(panel)}
                    >
                      <MuiAccordionSummary
                        aria-controls={`${panel}d-content`}
                        id={`${panel}d-header`}
                        className={style.accordionHeading}
                      >
                        <div className={style.flexRow}>
                          <p className={style.MenuHeading}>{menu.title}</p>
                          {badgeCount > 0 &&
                            notification?.data &&
                            menu.mode === notification.data.mode && (
                              <Notification />
                            )}
                        </div>
                      </MuiAccordionSummary>
                      <MuiAccordionDetails>
                        <ul className={style.listAccordName}>
                          {menu.children.map((nav: MenuItem, x: number) => {
                            const isActive =
                              // check the current location pathname matches the menu item path
                              location.pathname === nav.path ||
                              // or check the current location pathname with search query params matches the menu item path
                              // and with menu title matches with param value is searh query for menu
                              // this use on matches-likes page has tab
                              (location.pathname + location.search ===
                                nav.path &&
                                nav.title.toLowerCase() ===
                                  searchParams.get('q'));

                            return (
                              <li key={x}>
                                <NavItem
                                  title={nav.title}
                                  path={nav.path}
                                  state={nav.state}
                                  isActive={isActive}
                                  onDismiss={onDismiss}
                                />
                              </li>
                            );
                          })}
                        </ul>
                      </MuiAccordionDetails>
                    </MuiAccordion>
                  );
                } else {
                  return (
                    <li key={i}>
                      <NavItem
                        title={menu.title}
                        path={menu.path}
                        state={menu.state}
                        isActive={isNavActive}
                      />
                    </li>
                  );
                }
              })}
            </ul>
            <div className={style.flexSide} onClick={onSignOut}>
              <img src={asset('/images/logout.png')} alt="Signout" />
              <p>Log Out</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarNavigation;

const NavItem = ({
  title,
  path,
  state,
  isActive,
  notificationCount,
  onDismiss, // add onDismiss as a prop
}: MenuItem & {onDismiss?: () => void}) => {
  const handleClick = () => {
    if (onDismiss) onDismiss();
  };

  return (
    <Link
      to={path}
      state={state}
      className={cl(style.navItem, isActive && style.active)}
      onClick={handleClick}
    >
      <span>{title}</span>
      {notificationCount > 0 && <Notification />}
    </Link>
  );
};
