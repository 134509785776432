import React from 'react';
import {Box} from '../components/ui/forms/Box';
import {useNavigate} from 'react-router-dom';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import './Q7_RelationshipGoal.scss';
import {relationshipGoalValues} from '../constants';
import {QuestionContainer} from '../components/QuestionContainer';
import {QuestionHeader} from '../components/QuestionHeader';
import {ROUTES} from '../routes/AppRoutes';
import ContinueButton from '../components/ContinueButton';
import {CheckboxChip} from '../components/ui/forms/CheckboxChip';

const Q7_RelationshipGoal = () => {
  const navigate = useNavigate();

  let auth = useAuth<Person, FreeAccount>();
  let user = auth.user;

  return (
    <QuestionContainer>
      <Box>
        <QuestionHeader>What do you want from your dates?</QuestionHeader>
      </Box>
      <Box>
        <CheckboxChip
          values={relationshipGoalValues}
          currentValues={user.relationshipGoal}
          maxCount={3}
        />
      </Box>
      <ContinueButton onClick={() => navigate(ROUTES.q8_family_plans.path)} />
    </QuestionContainer>
  );
};

export default Q7_RelationshipGoal;
