import {Server} from 'lincd-server-utils/lib/utils/Server';
import {packageName} from '../package';
import {checkPermissionStatus} from '../libs/glassfy';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {PaidAccountTier1} from 'lincd-dating/lib/shapes/PaidAccountTier1';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';

export function usePurchase() {
  const auth = useAuth<Person, FreeAccount | PaidAccountTier1>();
  const userAccount = auth.userAccount;

  const downgradeAccount = async () => {
    const result = await Server.call(packageName, 'downgradeAccount');

    console.log('downgrade account result:', result);

    if (result && result.auth) {
      return auth.updateAuth(result.auth);
    } else {
      console.warn('downgrade account failed.');
      return null;
    }
  };

  // request to check is user paid account on server
  const isPaidAccount = async (): Promise<boolean> => {
    try {
      return await Server.call(packageName, 'isUserPaidAccount');
    } catch (error) {
      console.error('isUserPaidAccount:', error);
      return false;
    }
  };

  // check is paid account or not
  // criteria user is paid account if:
  // 1. user has paid account and the end date is not expired
  // 2. user has made a purchase order
  // 3. glassfy permission status is valid
  const checkIsPaidAccount = async (): Promise<boolean> => {
    // check if the user has a paid account without payment from ENV only in development
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment) {
      return await checkIsPaidAccountWithoutPayment();
    }

    // Check if the user has a paid account
    const paidAccount = await isPaidAccount();

    if (paidAccount) {
      return true;
    }

    try {
      const hasUserPurchase = await checkPermissionStatus();

      if (hasUserPurchase) {
        return true;
      }

      // check if account already expired but still PaidAccountTier1
      // so need to downgrade the account
      if (userAccount instanceof PaidAccountTier1) {
        const account = await downgradeAccount();
      } else {
        console.warn('user is FreeAccount.');
      }

      return false;
    } catch (error) {
      console.warn('Error checking user permissions:', error);
      return false;
    }
  };

  // check is paid account without payment
  const checkIsPaidAccountWithoutPayment = async (): Promise<boolean> => {
    // check if the user has a paid account
    const paidAccount =
      userAccount instanceof PaidAccountTier1 &&
      userAccount.endDate > new Date();

    console.log('purchase paidAccount: ', paidAccount);

    if (paidAccount) {
      return true;
    } else {
      if (userAccount instanceof PaidAccountTier1) {
        console.log('downgrade account');
        const account = await downgradeAccount();
      }
      console.warn('user is FreeAccount.');
      return false;
    }
  };

  return {
    checkIsPaidAccount,
    checkIsPaidAccountWithoutPayment,
  };
}
