import React, {ReactNode, useEffect} from 'react';
import './ActionSheet.scss';
import style from './ActionSheet.scss.json';
import {cl} from 'lincd/lib/utils/ClassNames';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';

type ActionSheetProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  isFullwidth?: boolean; // if true, content container will be fullwidth
};

const ActionSheet: React.FC<ActionSheetProps> = ({
  children,
  isOpen,
  onClose,
  isFullwidth = false,
}) => {
  const [animationStart, setAnimationStart] = React.useState<boolean>(false);
  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setAnimationStart(true);
    }, 100);
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div
          className={cl(
            style.action_sheet_overlay,
            animationStart && style.show,
          )}
          onClick={handleOverlayClick}
        >
          <div className={style.action_sheet}>
            <div
              className={cl(
                style.action_sheet_content,
                isFullwidth && style.isFullwidth,
              )}
            >
              {children}
            </div>
            <button className={style.action_sheet_button} onClick={onClose}>
              <img src={asset('/images/CloseIcon.png')} alt="close button" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ActionSheet;
