import {FormControlLabel} from '../components/ui/forms/FormControlLabel';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import RadioChip from '../components/RadioChip';
import {gender} from '../constants';
import './Q4_Gender.scss';
import ContinueButton from '../components/ContinueButton';
import {QuestionContainer} from '../components/QuestionContainer';
import {QuestionHeader} from '../components/QuestionHeader';
import {ROUTES} from '../routes/AppRoutes';
import {dating} from 'lincd-dating/lib/ontologies/dating';
import CustomizedSwitch from '../components/ui/forms/CustomizedSwitch';
import {QuestionSubheader} from '../components/QuestionSubheader';
import React from 'react';

const Q4_Gender = ({showPublic}) => {
  const navigate = useNavigate();
  const [isChecked, setChecked] = useState<boolean>(false);
  let auth = useAuth<Person, FreeAccount>();
  let user = auth.user;
  let userAccount = auth.userAccount;

  //temporary trick to force update based on changed internal graph state
  const [bool, setBool] = useState(true);
  const forceUpdate = () => {
    setBool(!bool);
  };

  useEffect(() => {
    //by default, when no value is set yet, we set displayGender to true
    if (!userAccount.hasProperty(dating.displayGender)) {
      userAccount.displayGender = true;
      setChecked(true);
    }
  }, []);

  return (
    <div>
      <QuestionContainer>
        <QuestionHeader>What's your gender?</QuestionHeader>
        <QuestionSubheader>
          Pick which best describes you, then add more about your gender if
          you’d like.
          {/*<a href="#">Learn what this means</a>*/}
        </QuestionSubheader>
        <RadioChip values={gender} of={user} valueProperty={'gender'} />
        {/* TODO: make a custom component that renders the control and switch and takes of/valueProperty props */}
        <FormControlLabel
          control={
            <CustomizedSwitch
              checked={isChecked}
              onChange={() => setChecked(!isChecked)}
            />
          }
          label={`Shown on my profile`}
        />
        {/* <CustomizedSwitch checked={auth.userAccount.displayGender} label={`Shown on my profile`} onChange={onDisplayGender} /> */}
      </QuestionContainer>
      <ContinueButton
        // text={'You can always update this later. We got you.'}
        onClick={() => navigate(ROUTES.q5_relationship_style.path)}
      />
    </div>
  );
};

export default Q4_Gender;
