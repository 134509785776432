import {
  beliefs,
  coreValues,
  discoveryPaths,
  drinkingFrequencies,
  familyPlans,
  fourTwentyFriendlyValues,
  gender,
  interestedInGenderValues,
  loveLanguages,
  passions,
  personalityTypes,
  relationshipGoalValues,
  relationshipStyle,
  relationshipStyleValues,
  sacredTexts,
  sexualOrientation,
  smokingHabits,
  spiritualPractice,
  systemIdeology,
  workoutFrequencies,
} from '../constants';
import React from 'react';

type ComponentAnswer =
  | 'TextField'
  | 'Textarea'
  | 'LocationInput'
  | 'Select'
  | 'DatePicker'
  | 'Switch'
  | 'Radio'
  | 'CheckboxChip'
  | 'RadioChip'
  | 'Slider'
  | 'MultiSelect'
  | 'Uploader'
  | 'MultiSearchChip'
  | 'DateTimeBirth'
  | 'RangeSlider';

type ComponentQuestion =
  | 'Avatar'
  | 'Typography'
  | 'TypographyBoolean'
  | 'TypographyRange'
  | 'List'
  | 'Chip'
  | 'Visibility'
  | 'Date'
  | 'Uploader'
  | 'SingleList';

interface Question {
  component: ComponentQuestion;
  icon?: string;
  value?: string | string[] | boolean;
}

interface Answer {
  component: ComponentAnswer | React.FunctionComponent;
  icon?: string;
  options?: any;
  value?: string;
  marks?: {value: number; label: string}[];
  hideQuestionName?: boolean;
  multiple?: boolean;
  maxCount?: number;
}

interface CategoryItem {
  name?: string;
  property?: string | string[];
  icon?: string;
  questions: Question;
  answers: Answer;
  showPublic?: any;
}

interface CategoryProps extends Map<string, CategoryItem[]> {}

/**
 * categories menu for edit profile page.
 * where or how can naming the component? currently component name based from mui component with PascalCase string.
 * how if I need to naming custom component? currently give name related with the element. Example: TypographyBoolean.
 * what the different question and answer component? question component for display data and answer for the forms element.
 * why not return component? the reason is tricky to handle the props.
 */

const categories: CategoryProps = new Map([
  [
    'Photo',
    [
      {
        property: 'profilePicture',
        questions: {
          component: 'Uploader',
        },
        answers: {
          component: 'Uploader',
        },
      },
    ],
  ],
  [
    'Basic Info',
    [
      {
        name: 'Name',
        icon: 'NameIcon',
        property: 'givenName',
        questions: {
          component: 'Typography',
        },
        answers: {
          component: 'TextField',
        },
      },
      {
        name: 'Superhero Name',
        icon: 'AlternateNameIcon',
        property: 'alternateName',
        questions: {
          component: 'Typography',
        },
        answers: {
          component: 'TextField',
        },
      },
      {
        name: 'About Me',
        icon: 'AboutMeIcon',
        property: 'description',
        questions: {
          component: 'Typography',
        },
        answers: {
          component: 'Textarea',
        },
      },
      {
        name: 'Based in',
        icon: 'LocationIcon',
        property: 'homeLocation',
        questions: {
          component: 'List',
        },
        answers: {
          component: 'LocationInput',
          maxCount: 3,
          multiple: true,
        },
      },
      {
        name: 'Occupation',
        icon: 'OccupationIcon',
        property: 'hasOccupation',
        questions: {
          component: 'Typography',
        },
        answers: {
          component: 'TextField',
        },
      },
      {
        name: 'Gender',
        icon: 'GenderIcon',
        property: 'gender',
        questions: {
          component: 'Typography',
        },
        answers: {
          component: 'RadioChip',
          options: gender,
        },
        showPublic: {
          component: 'Switch',
          property: 'displayGender',
        },
      },
    ],
  ],
  [
    'Birth',
    [
      {
        name: 'Birthdate',
        icon: 'BirthdateIcon',
        property: 'birthDate',
        questions: {
          component: 'Date',
        },
        answers: {
          component: 'DateTimeBirth',
          hideQuestionName: true,
        },
      },
      {
        name: 'Birthplace',
        icon: 'BirthplaceIcon',
        property: 'birthPlace',
        questions: {
          component: 'SingleList',
        },
        answers: {
          component: 'LocationInput',
          multiple: false,
        },
      },
    ],
  ],
  [
    'Looking For',
    [
      {
        name: 'Relationship Type',
        icon: 'InterestedInIcon',
        property: 'relationshipType',
        questions: {
          component: 'Typography',
        },
        answers: {
          component: 'RadioChip',
          options: relationshipStyle,
        },
      },
    ],
  ],
  [
    'Dating',
    [
      {
        name: 'Goal',
        icon: 'RelationshipGoalIcon',
        property: 'relationshipGoal',
        questions: {
          component: 'Chip',
        },
        answers: {
          component: 'CheckboxChip',
          options: relationshipGoalValues,
          maxCount: 3,
        },
      },
      {
        name: 'Relationship Style',
        icon: 'SearchIcon',
        property: 'relationshipStyle',
        questions: {
          component: 'Chip',
        },
        answers: {
          component: 'CheckboxChip',
          options: relationshipStyleValues,
        },
      },
    ],
  ],
  [
    'More About Me',
    [
      {
        name: 'Sexual Orientation ',
        icon: 'SexualOrientationIcon',
        property: 'sexualOrientation',
        questions: {
          component: 'Chip',
        },
        answers: {
          component: 'CheckboxChip',
          options: sexualOrientation,
          maxCount: 3,
        },
        showPublic: {
          component: 'Switch',
          property: 'displaySexualOrientation',
        },
      },
      {
        name: 'Family Plan',
        icon: 'FamilyPlanIcon',
        property: 'familyPlans',
        questions: {
          component: 'Typography',
        },
        answers: {
          component: 'RadioChip',
          options: familyPlans,
        },
      },
      {
        name: 'Passions',
        icon: 'PassionIcon',
        property: 'passions',
        questions: {
          component: 'Chip',
        },
        answers: {
          component: 'MultiSearchChip',
          options: passions,
          maxCount: 7,
        },
      },
      {
        name: 'How long have you been on your spiritual journey of growth and transformation?',
        icon: 'SpiritualJourneyIcon',
        property: 'spiritualJourney',
        questions: {
          component: 'Typography',
        },
        answers: {
          component: 'Select',
          options: discoveryPaths,
        },
      },
    ],
  ],
  [
    'Lifestyle',
    [
      {
        name: 'How often do you drink?',
        icon: 'DrinkingIcon2',
        property: 'drinkFrequency',
        questions: {
          component: 'Typography',
        },
        answers: {
          component: 'RadioChip',
          options: drinkingFrequencies,
        },
      },
      {
        name: 'How often do you smoke?',
        icon: 'SmokingIcon',
        property: 'smokingHabit',
        questions: {
          component: 'Typography',
        },
        answers: {
          component: 'RadioChip',
          options: smokingHabits,
        },
      },
      {
        name: 'Are you 420 friendly?',
        icon: 'WeedIcon',
        property: 'fourTwentyFriendly',
        questions: {
          component: 'Typography',
        },
        answers: {
          component: 'RadioChip',
          options: fourTwentyFriendlyValues,
        },
      },
      {
        name: 'Do you workout?',
        icon: 'WorkoutIcon',
        property: 'workoutFrequency',
        questions: {
          component: 'Typography',
        },
        answers: {
          component: 'RadioChip',
          options: workoutFrequencies,
        },
      },
    ],
  ],
  [
    'Values And Beliefs',
    [
      {
        name: 'Beliefs',
        icon: 'BeliefsIcon',
        property: 'beliefs', //needs to exist as a accessor (get method) of Person
        questions: {
          component: 'Chip',
        },
        answers: {
          component: 'CheckboxChip',
          options: beliefs,
          maxCount: 5,
        },
      },
      {
        name: 'System Ideology Identifications',
        icon: 'SystemIdeologyIcon',
        property: 'systemIdeologies',
        questions: {
          component: 'Chip',
        },
        answers: {
          component: 'CheckboxChip',
          options: systemIdeology,
          maxCount: 5,
        },
      },
      {
        name: 'Core or Universal Values',
        icon: 'CoreValueIcon',
        property: 'coreValues',
        questions: {
          component: 'Chip',
        },
        answers: {
          component: 'CheckboxChip',
          options: coreValues,
          maxCount: 5,
        },
      },
      {
        name: 'Spiritual Practices',
        icon: 'SpiritualPracticeIcon',
        property: 'spiritualPractices',
        questions: {
          component: 'Chip',
        },
        answers: {
          component: 'CheckboxChip',
          options: spiritualPractice,
          maxCount: 5,
        },
      },
      {
        name: 'Sacred Texts',
        icon: 'SacredTextIcon',
        property: 'sacredTexts',
        questions: {
          component: 'Chip',
        },
        answers: {
          component: 'CheckboxChip',
          options: sacredTexts,
          maxCount: 5,
        },
      },
    ],
  ],
  [
    'Astrology And Personality Types',
    [
      {
        name: 'Mayan Astrological Sign, Color, Tone',
        icon: 'MayanIcon',
        property: 'mayanAstrology',
        questions: {
          component: 'Typography',
        },
        answers: {
          component: 'TextField',
        },
      },
      {
        name: 'Chinese Zodiac Sign',
        icon: 'ChineseZodiacIcon2',
        property: 'chineseZodiac',
        questions: {
          component: 'Typography',
        },
        answers: {
          component: 'TextField',
        },
      },
      {
        name: 'Myers Briggs Personality Types',
        icon: 'PersonalityTypeIcon',
        property: 'myersBriggsPersonalityType',
        questions: {
          component: 'Typography',
        },
        answers: {
          component: 'Select',
          options: personalityTypes,
        },
      },
      {
        name: 'Love Language #1',
        icon: 'LoveLanguageIcon',
        property: 'loveLanguage1',
        questions: {
          component: 'Typography',
        },
        answers: {
          component: 'Select',
          options: loveLanguages,
        },
      },
      {
        name: 'Love Language #2',
        icon: 'LoveLanguageIcon',
        property: 'loveLanguage2',
        questions: {
          component: 'Typography',
        },
        answers: {
          component: 'Select',
          options: loveLanguages,
        },
      },
      {
        name: 'Love Language #3',
        icon: 'LoveLanguageIcon',
        property: 'loveLanguage3',
        questions: {
          component: 'Typography',
        },
        answers: {
          component: 'Select',
          options: loveLanguages,
        },
      },
      {
        name: 'Love Language #4',
        icon: 'LoveLanguageIcon',
        property: 'loveLanguage4',
        questions: {
          component: 'Typography',
        },
        answers: {
          component: 'Select',
          options: loveLanguages,
        },
      },
      {
        name: 'Love Language #5',
        icon: 'LoveLanguageIcon',
        property: 'loveLanguage5',
        questions: {
          component: 'Typography',
        },
        answers: {
          component: 'Select',
          options: loveLanguages,
        },
      },
    ],
  ],
  [
    'Permissions',
    [
      {
        name: 'Location',
        icon: 'LocationIcon',
        property: 'enabledLocationServices',
        questions: {
          component: 'TypographyBoolean',
        },
        answers: {
          component: 'Switch',
        },
      },
      {
        name: 'Notifications',
        icon: 'notification-bell',
        property: 'enabledNotifications',
        questions: {
          component: 'TypographyBoolean',
        },
        answers: {
          component: 'Switch',
        },
      },
    ],
  ],
]);

export function getCategories(): CategoryProps {
  return categories;
}
