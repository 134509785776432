import {Capacitor} from '@capacitor/core';

// convert a string to url slug
export const slugify = (str: string) => {
  return str
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '');
};

// convert slug to capitalize text
export const unslugify = (slug: string) =>
  slug
    .replace(/\-/g, ' ')
    .replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase(),
    );

export const isIOS = () => {
  return Capacitor.getPlatform() === 'ios';
};

export const isWeb = () => {
  return Capacitor.getPlatform() === 'web';
};

// currently use for generate
export const generateRandomName = (format) => {
  const timestamp = new Date().getTime();
  const randomString = Math.random().toString(15).substring(7); // Generate a random string

  return format
    ? `${timestamp}_${randomString}.${format}`
    : `${timestamp}_${randomString}`;
};

// convert miles to km
export const milesToKm = (miles) => miles * 1.609;

//convert km to miles
export const kmToMiles = (km) => km / 1.609;

// calculate birthdate for get age
export const calculateAge = (birthDate: Date) => {
  const birthYear = new Date(birthDate).getFullYear();
  const currentYear = new Date().getFullYear();
  const age = currentYear - birthYear;
  return age;
};

// get account slug from account uri
// currently use for get account slug from account uri in admin/users
// example:
// - https://app.spiritualplaymates.com/data/account/1 --> 1
// - https://app.spiritualplaymates.com/data/account/google-dev-semantu-com --> google-dev-semantu-com
export const getAccountSlug = (accountUri: string) => {
  if (!accountUri) {
    return '';
  }

  const data = process.env.DATA_ROOT;
  return accountUri.replace(`${data}/account/`, '');
};
