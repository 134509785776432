import {Capacitor} from '@capacitor/core';
import {initializeApp} from '@firebase/app';
import {getMessaging} from 'firebase/messaging';

function initializeFirebase() {
  if (Capacitor.isNativePlatform()) {
    return;
  }

  return initializeApp({
    apiKey: 'AIzaSyDXuLv8uRa7YTjzxhdV8sqnFid5q49MkLo',
    authDomain: 'spiritual-playmates-4102c.firebaseapp.com',
    projectId: 'spiritual-playmates-4102c',
    storageBucket: 'spiritual-playmates-4102c.appspot.com',
    messagingSenderId: '580968717451',
    appId: '1:580968717451:web:83ed4f1eb905bdd6ee088e',
    measurementId: 'G-33TSCJF0H0',
  });
}

export function initNotifications() {
  //initialize firebase right at the start before the component renders
  const app = initializeFirebase();

  // make sure firebase messaging only for web
  if (
    typeof window != 'undefined' &&
    window.document &&
    Capacitor.getPlatform() === 'web'
  ) {
    getMessaging(app);
  }
}
