import React, {useEffect, useState} from 'react';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {packageName} from '../package';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../routes/AppRoutes';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {ProfilePreview} from 'profile-plus/lib/components/ProfilePreview';
import {default as style} from './Admin.scss.json';
import './Admin.scss';
import {Button} from '../components/ui/forms/Button';
import ProfileDetail from './ProfileDetail';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Dislike} from 'lincd-dating/lib/shapes/Dislike';
import {Like} from 'lincd-dating/lib/shapes/Like';

export default function Admin(props) {
  let auth = useAuth();
  let [profiles, setProfiles] = useState<ShapeSet<Person>>();
  let [selectedProfile, setSelectedProfile] = useState<Person>();
  let navigate = useNavigate();
  let [likes, setLikes] = useState<ShapeSet<Like>>();
  let [dislikes, setDislikes] = useState<ShapeSet<Dislike>>();

  useEffect(() => {
    Server.call(packageName, 'adminGetAllUsers').then((results) => {
      if (results) {
        setProfiles(results.profiles);
      } else {
        navigate(ROUTES.index.path);
      }
    });
  }, []);
  useEffect(() => {
    if (selectedProfile) {
      Server.call(packageName, 'adminGetUserDetails', selectedProfile).then(
        ({likes, dislikes}) => {
          setLikes(likes);
          setDislikes(dislikes);
        },
      );
    }
  }, [selectedProfile]);

  const deleteProfile = () => {
    if (!selectedProfile) return;
    Server.call(packageName, 'adminDeleteUser', selectedProfile).then(
      (results) => {
        if (results) {
          profiles.delete(selectedProfile);
          setProfiles(profiles);
          setSelectedProfile(null);
        }
      },
    );
  };

  if (!profiles) return null;
  let selectedAccount;
  if (selectedProfile) {
    selectedAccount = FreeAccount.getAccountOf(selectedProfile as any);
  }

  let completedProfiles = profiles.filter((profile) => {
    let account = FreeAccount.getAccountOf(profile as any) as FreeAccount;
    if (!account?.profileSetupCompleted) {
      return false;
    }
    return true;
  });

  let numIncompletedAccounts = profiles.size - completedProfiles.size;

  const copyLink = (couponCode: string) => {
    navigator.clipboard.writeText(couponCode);
  };

  const printUri = (str) => {
    return str.replace(process.env.DATA_ROOT, '');
  };

  return (
    <div className={style.Admin}>
      <div>
        <h3>{completedProfiles.size} accounts:</h3>
        <div className={style.profiles}>
          {completedProfiles.map((profile) => {
            let account = FreeAccount.getAccountOf(
              profile as any,
            ) as FreeAccount;
            return (
              <div key={account.uri}>
                <ProfilePreview
                  of={profile}
                  key={profile.uri}
                  onClick={() => setSelectedProfile(profile)}
                />
                <p>{account?.email}</p>
              </div>
            );
          })}
        </div>
        <br />
        <br />
        <h3>
          {numIncompletedAccounts} accounts that have not completed the initial
          questions
        </h3>
        <div className={style.profiles}>
          {profiles
            .filter((p) => !completedProfiles.has(p))
            .map((profile) => {
              let account = FreeAccount.getAccountOf(
                profile as any,
              ) as FreeAccount;
              return (
                <div>
                  <ProfilePreview
                    of={profile}
                    key={profile.uri}
                    onClick={() => setSelectedProfile(profile)}
                  />
                  <p>{account?.email}</p>
                </div>
              );
            })}
        </div>
      </div>
      {selectedProfile && (
        <div className={style.profileDetail}>
          <pre>{selectedProfile.print()}</pre>
          <pre>{selectedAccount.print()}</pre>
          <Button variant="outlined" onClick={deleteProfile}>
            Delete Profile
          </Button>
          <hr />
          <ProfilePreview of={selectedProfile} />
          <ProfileDetail of={selectedProfile} />
          {likes && (
            <div>
              <h3>Matches</h3>
              {likes
                .filter((like) => like.isMatch)
                .map((like) => (
                  <p key={like.uri}>{printUri(like.person.uri)}</p>
                ))}
              <h3>Likes</h3>
              {likes
                .filter((like) => !like.isMatch)
                .map((like) => (
                  <p key={like.uri}>{printUri(like.person.uri)}</p>
                ))}
            </div>
          )}
          {dislikes && (
            <div>
              <h3>Dislikes</h3>
              {dislikes.map((dislike) => (
                <p key={dislike.uri}>{printUri(dislike.person.uri)}</p>
              ))}
            </div>
          )}
          <br />
        </div>
      )}
    </div>
  );
}
