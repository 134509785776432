import React, {useEffect, useRef, useState} from 'react';
import style from './UserProfileInformation.scss.json';
import './UserProfileInformation.scss';
import {cl} from 'lincd/lib/utils/ClassNames';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {PaidAccountTier1} from 'lincd-dating/lib/shapes/PaidAccountTier1';
import {PlaceChip} from './PlaceChip';
import {Place} from 'lincd-schema/lib/shapes/Place';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';
import {Tooltip} from './ui/forms/Tooltip';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {packageName} from '../package';

const zodiac = require('zodiac-signs')();

export const UserProfileInformation = ({
  data,
  accountUri,
}: {
  data?: any;
  accountUri: string;
}) => {
  const [user, setUser] = useState<any>(data || null);

  const person = user?.person as Person;
  const account = user?.account as FreeAccount | PaidAccountTier1;

  const fetchUser = async (accountUri: string) => {
    // console.log('UserProfileInformation fetching user:', accountUri);
    try {
      const response = await Server.call(packageName, 'getUser', accountUri);
      setUser(response);
    } catch (error) {
      console.error('failed to fetch user:', error);
    }
  };

  useEffect(() => {
    // fetch user data if not accountOf and personOf
    if (!data) {
      fetchUser(accountUri);
    }
  }, [accountUri]);

  // no profile data when accountOf and personOf are not available
  if (!person || !account) {
    return (
      <div>
        <p>No profile data</p>
      </div>
    );
  }

  // console.log('user', user);

  let signSymbol, signName;
  if (person.birthDate) {
    let profileBirthMonth = person.birthDate.getMonth() + 1;
    let profileBirthDate = person.birthDate.getDate();
    signName = zodiac.getSignByDate({
      day: profileBirthDate,
      month: profileBirthMonth,
    }).name;
    signSymbol = zodiac.getSignByDate({
      day: profileBirthDate,
      month: profileBirthMonth,
    }).symbol;
  }

  return (
    <div className={style.profileDetail}>
      <div className={style.profileContent}>
        <p>
          Name: {person?.givenName} {person?.familyName}
        </p>
        <p>Email: {account?.email}</p>
        <p>Account URI: {account?.uri}</p>
        <p>
          Profile Completed: {account?.profileSetupCompleted ? 'Yes' : 'No'}
        </p>
      </div>
      <div className={style.blockDetail}>
        <p>My basics</p>
        <div className={cl(style.blockDetail, style.rows)}>
          {signSymbol && (
            <Tooltip title={'Astrology sun sign'}>
              <div className={style.signContainer}>
                <span className={style.signIcon}>{signSymbol}</span>
                {signName}
              </div>
            </Tooltip>
          )}
          {person?.smokingHabit && (
            <Tooltip title={'Smoking habit'}>
              <div className={style.basicDetail}>
                <img
                  src={asset('/images/SmokingIcon.svg')}
                  alt="smoking-icon"
                />
                {person.smokingHabit}
              </div>
            </Tooltip>
          )}
          {person?.workoutFrequency && (
            <Tooltip title={'Workout frequency'}>
              <div className={style.basicDetail}>
                <img
                  src={asset('/images/WorkoutIcon.svg')}
                  alt="exercise-icon"
                />
                {person.workoutFrequency}
              </div>
            </Tooltip>
          )}
          {person?.myersBriggsPersonalityType && (
            <Tooltip title={'Meyers Briggs personality type'}>
              <div className={style.basicDetail}>
                <img
                  src={asset('/images/PersonalityTypeIcon.svg')}
                  alt="personality-type-icon"
                />
                {person.myersBriggsPersonalityType}
              </div>
            </Tooltip>
          )}
          {person?.chineseZodiac && (
            <Tooltip title={'Chinese Zodiac'}>
              <div className={style.basicDetail}>
                <img
                  src={asset('/images/ChineseZodiacIcon2.svg')}
                  alt="chinese-zodiac-icon"
                />
                {person.chineseZodiac}
              </div>
            </Tooltip>
          )}
          {person?.fourTwentyFriendly && (
            <Tooltip title={'Four-twenty friendly'}>
              <div className={style.basicDetail}>
                <img
                  src={asset('/images/WeedIcon.svg')}
                  alt="four-twenty-friendly-icon"
                />
                {person.fourTwentyFriendly}
              </div>
            </Tooltip>
          )}
          {person?.familyPlans && (
            <Tooltip title={'Family plans'}>
              <div className={style.basicDetail}>
                <img
                  src={asset('/images/FamilyPlanIcon.svg')}
                  alt="family-plan-icon"
                />
                {person.familyPlans}
              </div>
            </Tooltip>
          )}
          {person?.drinkFrequency && (
            <Tooltip title={'Drink frequency'}>
              <div className={style.basicDetail}>
                <img
                  src={asset('/images/DrinkingIcon2.svg')}
                  alt="drinking-icon"
                />
                {person.drinkFrequency}
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      <div>
        {person?.relationshipGoal && person.relationshipGoal.size > 0 && (
          <div className={style.blockDetail}>
            <p>Relationship Goal</p>{' '}
            <div>
              {person.relationshipGoal.map((item) => {
                return (
                  <span key={item.value} className={style.blockMore}>
                    {item.value}
                  </span>
                );
              })}
            </div>
          </div>
        )}
        {person?.homeLocation.size > 0 && (
          <div className={style.blockDetail}>
            <p>Based in</p>
            <div className={style.chipValues}>
              {person.homeLocation.map((location: Place) => {
                return (
                  <PlaceChip
                    of={location}
                    key={location.toString()}
                    variant={'filled'}
                  />
                );
              })}
            </div>
          </div>
        )}
        {person?.sexualOrientation.size > 0 && (
          <div className={style.blockDetail}>
            <p>Sexual Orientation</p>{' '}
            <div>
              {person.sexualOrientation.map((sexualOrientation) => {
                return (
                  <span
                    key={sexualOrientation.value}
                    className={style.blockMore}
                  >
                    {sexualOrientation.value}
                  </span>
                );
              })}
            </div>
          </div>
        )}
        {account?.displaySexualOrientation && (
          <div className={style.blockDetail}>
            <p>Display Sexual Orientation</p>{' '}
            <div className={style.blockMore}>
              {account.displaySexualOrientation ? 'Yes' : 'No'}
            </div>
          </div>
        )}
        {person?.passions.size > 0 && (
          <div className={style.blockDetail}>
            <p>Passions</p>{' '}
            <div>
              {person.passions.map((passion) => {
                return (
                  <span key={passion.value} className={style.blockMore}>
                    {passion.value}
                  </span>
                );
              })}
            </div>
          </div>
        )}
        {person?.beliefs.size > 0 && (
          <div className={style.blockDetail}>
            <p>Beliefs</p>{' '}
            <div>
              {person.beliefs.map((belief) => {
                return (
                  <span key={belief.value} className={style.blockMore}>
                    {belief.value}
                  </span>
                );
              })}
            </div>
          </div>
        )}
        {person?.systemIdeologies.size > 0 && (
          <div className={style.blockDetail}>
            <p>System Ideologies</p>{' '}
            <div>
              {person.systemIdeologies.map((systemIdeology) => {
                return (
                  <span key={systemIdeology.value} className={style.blockMore}>
                    {systemIdeology.value}
                  </span>
                );
              })}
            </div>
          </div>
        )}
        {person?.coreValues.size > 0 && (
          <div className={style.blockDetail}>
            <p>Core Values</p>{' '}
            <div>
              {person.coreValues.map((coreValue) => {
                return (
                  <span key={coreValue.value} className={style.blockMore}>
                    {coreValue.value}
                  </span>
                );
              })}
            </div>
          </div>
        )}
        {person?.spiritualPractices.size > 0 && (
          <div className={style.blockDetail}>
            <p>Spiritual Practices</p>{' '}
            <div>
              {person.spiritualPractices.map((spiritualPractice) => {
                return (
                  <span
                    key={spiritualPractice.value}
                    className={style.blockMore}
                  >
                    {spiritualPractice.value}
                  </span>
                );
              })}
            </div>
          </div>
        )}
        {person?.sacredTexts.size > 0 && (
          <div className={style.blockDetail}>
            <p>Sacred Text</p>{' '}
            <div>
              {person.sacredTexts.map((sacredTexts) => {
                return (
                  <span key={sacredTexts.value} className={style.blockMore}>
                    {sacredTexts.value}
                  </span>
                );
              })}
            </div>
          </div>
        )}
        {person?.mayanAstrology && (
          <div className={style.blockDetail}>
            <p>Mayan Astrology</p>{' '}
            <div className={style.blockMore}>{person.mayanAstrology}</div>
          </div>
        )}
        {person?.loveLanguage1 && (
          <div className={style.blockDetail}>
            <p>Love Language #1</p>{' '}
            <div className={style.blockMore}>{person.loveLanguage1}</div>
          </div>
        )}
        {person?.loveLanguage2 && (
          <div className={style.blockDetail}>
            <p>Love Language #2</p>{' '}
            <div className={style.blockMore}>{person.loveLanguage2}</div>
          </div>
        )}
        {person?.loveLanguage3 && (
          <div className={style.blockDetail}>
            <p>Love Language #3</p>{' '}
            <div className={style.blockMore}>{person.loveLanguage3}</div>
          </div>
        )}
        {person?.loveLanguage4 && (
          <div className={style.blockDetail}>
            <p>Love Language #4</p>{' '}
            <div className={style.blockMore}>{person.loveLanguage4}</div>
          </div>
        )}
        {person?.loveLanguage5 && (
          <div className={style.blockDetail}>
            <p>Love Language #5</p>{' '}
            <div className={style.blockMore}>{person.loveLanguage5}</div>
          </div>
        )}
      </div>

      <div className={style.blockDetail}>
        <p>Enabled Location Services</p>{' '}
        <div className={style.blockMore}>
          {account?.enabledLocationServices ? 'Yes' : 'No'}
        </div>
      </div>
      <div className={style.blockDetail}>
        <p>Enabled Notifications</p>{' '}
        <div className={style.blockMore}>
          {account?.enabledNotifications ? 'Yes' : 'No'}
        </div>
      </div>

      <div>
        <div className={style.blockDetail}>
          <p>Likes: {user?.likes}</p>
          <p>Dislike: {user?.dislike}</p>
          <p>Match: {user?.matches}</p>
        </div>
      </div>
    </div>
  );
};
