import React, {ReactNode} from 'react';
import './PageLayout.scss';
import * as style from './PageLayout.scss.json';
import {Typography} from '../ui/forms/Typography';
import {cl} from 'lincd/lib/utils/ClassNames';

interface PageLayoutProps {
  children: ReactNode;
  pageTitle?: string;
  className?: string;
  isFullscreen?: boolean; // reset padding 0
}

/**
 * standard page layout
 * import `PageLayout` to wrap content page
 */
const PageLayout = ({
  children,
  pageTitle,
  className,
  isFullscreen = false,
}: PageLayoutProps) => {
  return (
    <div className={cl(className, isFullscreen && style.resetPadding)}>
      {pageTitle && (
        <Typography variant="h4" component="h1" mb={6}>
          {pageTitle}
        </Typography>
      )}
      {children}
    </div>
  );
};

export default PageLayout;
