import {Glassfy, GlassfyPermission} from 'capacitor-plugin-glassfy';

const PRODUCT_PREMIUM = 'premium';
const PRODUCT_DISCOUNTED = 'discounted_product';

const GLASSFY_OFFERING = {
  premium: PRODUCT_PREMIUM,
  discount: PRODUCT_DISCOUNTED,
};

const GLASSFY_PERMISSION = {
  premium: PRODUCT_PREMIUM,
  discount: PRODUCT_DISCOUNTED,
};

type ProductType = 'premium' | 'discounted_product';

export const getOfferingByDiscountCode = async (discountCode: string) => {
  const offerings = await Glassfy.offerings();

  // find the offering based on the discount code to get the skus for make purchase.
  return offerings.all.find((offer) => {
    // check if the discount code is available
    // if not, set the default offering to premium
    return discountCode
      ? offer.offeringId === GLASSFY_OFFERING.discount
      : offer.offeringId === GLASSFY_OFFERING.premium;
  });
};

// find glassfy permission by permission id
export const getPermissionById = async (permissionId: string) => {
  const permissions = await Glassfy.permissions();

  return permissions.all.find(
    (permission) => permission.permissionId === permissionId,
  );
};

/**
 * https://docs.glassfy.io/docs/permissions#checking-permission-status
 * https://docs.glassfy.io/docs/sdk-usage#check-permissions
 *
 * @returns
 */
export const checkPermissionStatus = async () => {
  const permissions = await Glassfy.permissions();
  const validPermission = permissions.all.find((permission) => {
    return (
      (permission.permissionId === GLASSFY_PERMISSION.premium ||
        permission.permissionId === GLASSFY_PERMISSION.discount) &&
      permission.isValid
    );
  });

  return validPermission;
};

export const getProductType = (hasDiscountCode: string): ProductType => {
  return hasDiscountCode ? PRODUCT_DISCOUNTED : PRODUCT_PREMIUM;
};
