import React, {useEffect, useState} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import AppRoutes, {ROUTES} from './routes/AppRoutes';
import {ProvideAuth} from 'lincd-auth/lib/hooks/useAuth';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {initNotifications} from './libs/setup';
import {FirebaseProvider} from 'lincd-notify/lib/hooks/useFirebase';
import {StyledEngineProvider} from '@mui/material/styles';
import {SnackbarProvider} from './hooks/snackbar';
import {AppRoot} from 'lincd-server-utils/lib/components/AppRoot';
import {Head} from 'lincd-server-utils/lib/components/Head';
import {Body} from 'lincd-server-utils/lib/components/Body';
import {ContentContainer} from './components/layout/ContentContainer';
import {SafeAreaContainer} from 'lincd-capacitor/lib/components/SafeAreaContainer';
import './App.scss';
import {Glassfy} from 'capacitor-plugin-glassfy';
import {Capacitor} from '@capacitor/core';
import style from './App.scss.json';
import {FacebookLogin} from '@capacitor-community/facebook-login';

//import our variable files last, so they overwrite any components imported so far (by routes also)
import './scss/global-overwrites.scss';
import './scss/variables-theme.scss';
import './scss/variables-components.scss';
import {PaidAccountTier1} from 'lincd-dating/lib/shapes/PaidAccountTier1';
import {CapacitorProvider} from 'lincd-capacitor/lib/hooks/useCapacitor';
import {useAutomaticUpdates} from 'lincd-capacitor/lib/hooks/useAutomaticUpdates';
import {Button} from 'lincd-mui-base/lib/components/Button';
import {cl} from 'lincd/lib/utils/ClassNames';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';
import {useNavigate} from 'react-router-dom';
/**
 * init firebase on first reload
 */
initNotifications();
export default function App() {
  // Automatic Updates OTA (over the air) -> @capgo
  useAutomaticUpdates();

  /*<Suspense fallback={<Spinner />}>*/

  // initialize glasify SDK
  useEffect(() => {
    // Initialize Glasfy SDK
    async function initializeGlassfy() {
      try {
        await Glassfy.initialize({
          apiKey: process.env.GLASSIFY_API_KEY,
          watcherMode: false,
        });
      } catch (e) {
        console.log('Glassfy initialize error', e);
      }
    }

    if (Capacitor.getPlatform() !== 'web') {
      initializeGlassfy();
    }

    // initialize facbbook SDK for log event
    if (
      Capacitor.getPlatform() === 'android' ||
      Capacitor.getPlatform() === 'ios'
    ) {
      // initialize facebook SDK
      FacebookLogin.initialize({
        appId: process.env.FACEBOOK_CLIENT_ID,
      });

      // set auto log app events enabled (force to true)
      FacebookLogin.setAutoLogAppEventsEnabled({enabled: true});
    }
  }, []);

  return (
    <AppRoot>
      <Head>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&family=Quicksand&display=swap"
          rel="stylesheet"
        />
      </Head>
      <Body routes={ROUTES} pageStyles={style} className={style.App}>
        <ProvideAuth
          userType={Person}
          accountType={FreeAccount}
          availableAccountTypes={[PaidAccountTier1]}
        >
          <CapacitorProvider>
            <FirebaseProvider>
              <StyledEngineProvider injectFirst>
                <SnackbarProvider>
                  <ErrorBoundary FallbackComponent={Error}>
                    <SafeAreaContainer
                      fullScreenRoutes={[
                        ROUTES.signin.path,
                        ROUTES.welcome.path,
                        ROUTES.house_rules.path,
                      ]}
                      color={'#ffffff'}
                    >
                      <ContentContainer>
                        {/*TODO: use react router outlet*/}
                        <AppRoutes />
                      </ContentContainer>
                    </SafeAreaContainer>
                  </ErrorBoundary>
                </SnackbarProvider>
              </StyledEngineProvider>
            </FirebaseProvider>
          </CapacitorProvider>
        </ProvideAuth>
      </Body>
    </AppRoot>
  );
  {
    /*</Suspense>*/
  }
}

function Error({error, resetErrorBoundary}) {
  const navigate = useNavigate();

  const goToHomepage = () => {
    resetErrorBoundary();
    navigate(ROUTES.index.path);
  };

  const goToContact = () => {
    resetErrorBoundary();
    navigate(ROUTES.contact.path);
  };

  return (
    <div className={cl(style.error, style.pageDefault)}>
      <div className={style.content}>
        <img src={asset('/images/logo-white.png')} alt="logo" />
        <h1>Sorry. Something went wrong. </h1>
        <h3>
          We will look into this little bug right alway. If you want to know the
          status of a fix contact us.
        </h3>
        <div className={style.groupButton}>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => window.location.reload()}
          >
            Try Again
          </Button>
          <Button variant="outlined" fullWidth onClick={goToHomepage}>
            Homepage
          </Button>
          <Button variant="outlined" fullWidth onClick={goToContact}>
            Contact
          </Button>
        </div>
      </div>
    </div>
  );
}
