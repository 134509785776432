import React, {createContext, useContext, useState} from 'react';
import {AlertColor} from '@mui/material';
import SnackbarAlert from '../components/SnackbarAlert';

const DEFAULT_DURATION = 4000;
const DEFAULT_POSITION = 'bottom-right';

interface SnackbarOptions {
  message: string;
  title?: string;
  type?: AlertColor;
  position?: string;
  duration?: number;
  customStyle?: string;
}

interface SnackbarContextType {
  openSnackbar: (options: SnackbarOptions) => void;
  closeSnackbar: () => void;
}

export const SnackbarContext = createContext<SnackbarContextType | null>(null);

function useProvideSnackbar() {
  const [snackbar, setSnackbar] = useState({
    open: false,
    duration: DEFAULT_DURATION,
    type: 'success' as AlertColor,
    message: '',
    title: '',
    position: DEFAULT_POSITION,
    customStyle: '',
  });

  // create a new snackbar
  const addSnackbar = (
    message: string,
    title: string,
    type: AlertColor = 'success',
    position = DEFAULT_POSITION,
    duration = DEFAULT_DURATION,
    customStyle: string,
  ) => {
    setSnackbar({
      open: true,
      duration,
      type,
      message,
      title,
      position,
      customStyle,
    });
  };

  // handle the snackbar trigger
  const openSnackbar = (options) => {
    const {
      message,
      title,
      type = 'success',
      position = DEFAULT_POSITION,
      duration = DEFAULT_DURATION,
      customStyle = '',
    } = options;

    if (snackbar.open) {
      closeSnackbar();
      setTimeout(() => {
        addSnackbar(message, title, type, position, duration, customStyle);
      }, 400);
    } else {
      addSnackbar(message, title, type, position, duration, customStyle);
    }
  };

  // remove snackbar
  const closeSnackbar = () => {
    setSnackbar((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  return {
    snackbar,
    openSnackbar,
    closeSnackbar,
  };
}

export function SnackbarProvider({children}) {
  const {snackbar, openSnackbar, closeSnackbar} = useProvideSnackbar();

  const position: any = snackbar.position.split('-');
  const vertical: 'top' | 'bottom' = position[0];
  const horizontal: 'center' | 'left' | 'right' = position[1];

  return (
    <SnackbarContext.Provider value={{openSnackbar, closeSnackbar}}>
      {children}
      <SnackbarAlert
        snackbar={snackbar}
        onOpen={openSnackbar}
        onClose={closeSnackbar}
        vertical={vertical}
        horizontal={horizontal}
      />
    </SnackbarContext.Provider>
  );
}

export function useSnackbar() {
  const {openSnackbar, closeSnackbar} = useContext(SnackbarContext);

  return {
    openSnackbar,
    closeSnackbar,
  };
}
