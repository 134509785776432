import React from 'react';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../routes/AppRoutes';
import ContinueButton from '../components/ContinueButton';
import {QuestionContainer} from '../components/QuestionContainer';
import {QuestionHeader} from '../components/QuestionHeader';
import {QuestionSubheader} from '../components/QuestionSubheader';
import LocationInput from '../components/ui/forms/LocationInput/LocationInput';
import DateTimeBirthSelect from '../components/ui/forms/DateTimeBirthSelect';

const Q3_Birth = () => {
  const navigate = useNavigate();
  let auth = useAuth<Person, FreeAccount>();
  let user = auth.user;

  return (
    <QuestionContainer>
      <QuestionHeader>I was born</QuestionHeader>
      <DateTimeBirthSelect
        of={user}
        maxYear={new Date().getFullYear() - 18}
        property={['birthDate', 'doesNotKnowTime']}
      />
      <br />
      <p>We only show your age to potential matches, not your birthday.</p>
      <br />
      <QuestionSubheader>Place</QuestionSubheader>
      <LocationInput of={user} property={'birthPlace'} multiple={false} />
      <ContinueButton
        onClick={() => navigate(ROUTES.q4_gender.path)}
        // withBackground={true}
      />
    </QuestionContainer>
  );
};

export default Q3_Birth;
