import React, {useEffect, useRef, useState} from 'react';
import style from './Users.scss.json';
import './Users.scss';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {packageName} from '../package';
import {useParams} from 'react-router-dom';
import {UserProfileInformation} from '../components/UserProfileInformation';
import {getAccountSlug} from '../utils/helper';

const zodiac = require('zodiac-signs')();

export default function UserPage() {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const {accountUri} = useParams();

  // get teams data
  useEffect(() => {
    fetchUser();
  }, [accountUri]);

  const fetchUser = async () => {
    console.log('fetching user:', accountUri);
    setLoading(true);
    try {
      const usersData = await Server.call(packageName, 'getUser', accountUri);
      setUser(usersData);
    } catch (error) {
      console.error('failed to fetch users:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={style.root}>
      <div style={{textAlign: 'center', padding: '2rem 1rem'}}>
        <h1>User Detail</h1>
      </div>
      {loading ? (
        <div style={{textAlign: 'center', padding: '2rem 1rem'}}>
          Loading...
        </div>
      ) : (
        <>
          {user && (
            <UserProfileInformation
              data={user}
              accountUri={getAccountSlug(user?.accountUri)}
            />
          )}
        </>
      )}
    </div>
  );
}
