import './ProfileDetail.scss';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {useEffect, useState} from 'react';
import './ProfilePictureAndDetails.scss';
import {default as style} from './ProfilePictureAndDetails.scss.json';
import ProfileCard from './ProfileCard';
import ProfileDetail from './ProfileDetail';
import Spinner from '../components/Spinner';

interface ProfilePictureAndDetailsProps {
  of: Person;
  onBlock?: (person: Person) => void;
}

export const ProfilePictureAndDetails = ({
  of,
  onBlock,
}: ProfilePictureAndDetailsProps) => {
  let person: Person = of as Person;

  let [account, setAccount] = useState<FreeAccount>(null);
  useEffect(() => {
    person.getAccount().then((account: FreeAccount) => {
      if (account) {
        setAccount(account);
      }
    });
  }, [of]);

  if (!account) return <Spinner />;
  return (
    <div>
      <ProfileCard of={person} className={style.profileCardModal} />
      <ProfileDetail
        of={person}
        onBlock={onBlock}
        className={style.resetProfileDetail}
        showAction={false}
      />
    </div>
  );
};
