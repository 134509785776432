import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {useNavigate} from 'react-router-dom';
import './Welcome.scss';
import style from './Welcome.scss.json';
import {Button} from '../components/ui/forms/Button';
import PageLayout from '../components/layout/PageLayout';
import {ROUTES} from '../routes/AppRoutes';
import {QuestionContainer} from '../components/QuestionContainer';
import {QuestionHeader} from '../components/QuestionHeader';
import {QuestionSubheader} from '../components/QuestionSubheader';
import {Stack} from '../components/ui/forms/Stack';
import React from 'react';

const Welcome = () => {
  const navigate = useNavigate();
  let auth = useAuth<Person, FreeAccount>();

  const onCancel = () => {
    auth.signout();
    navigate(ROUTES.signin.path);
  };

  return (
    <PageLayout className={style.Welcome}>
      <QuestionContainer>
        <QuestionHeader>
          Welcome
          {(auth.user.name ? ' ' + auth.user.name.split(' ').shift() : '') ||
            (auth.user.givenName ? ' ' + auth.user.givenName : '')}
          !
        </QuestionHeader>
        <QuestionSubheader>
          We'd like to ask you some questions to find the best matching
          spiritual playmates for you
        </QuestionSubheader>
        <br />
        <Stack gap={1}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => navigate(ROUTES.house_rules.path)}
          >
            Great, let's go!
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={onCancel}
          >
            Back to sign in
          </Button>
        </Stack>
      </QuestionContainer>
    </PageLayout>
  );
};

export default Welcome;
