import React from 'react';
import {useNavigate} from 'react-router-dom';
import {QuestionHeader} from '../components/QuestionHeader';
import ContinueButton from '../components/ContinueButton';
import './Q2_Photos.scss';
import {ROUTES} from '../routes/AppRoutes';
import {QuestionContainer} from '../components/QuestionContainer';
import {QuestionSubheader} from '../components/QuestionSubheader';
import EditProfilePictures from '../components/EditProfilePictures';

const Q2_Photos = () => {
  const navigate = useNavigate();

  return (
    <div>
      <QuestionContainer>
        <QuestionHeader>Add more photos</QuestionHeader>
        <QuestionSubheader>
          Even though you will be spiritually connected. More photos the better.
          You can change these later.
        </QuestionSubheader>
        <EditProfilePictures />
      </QuestionContainer>
      <ContinueButton
        // text={'You can add more photos later'}
        onClick={() => navigate(ROUTES.q3_birth.path)}
      />
    </div>
  );
};

export default Q2_Photos;
