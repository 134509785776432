import {LinkedFileStorage} from 'lincd/lib/utils/LinkedFileStorage';

import {getAccessUrlLocalFileStore} from 'lincd-server/lib/utils/accessUrl';
import {getAccessURLS3FileStore} from 'lincd-s3/lib/utils/accessUrl';
import {BackendAPIStore} from 'lincd-server/lib/shapes/quadstores/BackendAPIStore';
import {LinkedStorage} from 'lincd/lib/utils/LinkedStorage';
import {LinkedErrorLogging} from 'lincd/lib/utils/LinkedErrorLogging';
import {SentryFrontendErrorLogger} from 'lincd-sentry/lib/utils/SentryFrontendErrorLogger';

// store all quads in a file on the backend named 'main'
// export const store = new BackendFileStore('main');
const store = new BackendAPIStore();
LinkedStorage.setDefaultStore(store);

// set default access URL for LinkedFileStorage
if (process.env.NODE_ENV === 'development') {
  LinkedFileStorage.setDefaultAccessURL(getAccessUrlLocalFileStore());
} else {
  LinkedFileStorage.setDefaultAccessURL(getAccessURLS3FileStore());
}

// init sentry logging before rendering the app
LinkedErrorLogging.setDefaultLogger(new SentryFrontendErrorLogger());
