import React, {useCallback} from 'react';
import {default as styles} from './ProfileDetail.scss.json';
import './ProfileDetail.scss';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {Tooltip} from '../components/ui/forms/Tooltip';
import {PlaceChip} from '../components/PlaceChip';
import {Place} from 'lincd-schema/lib/shapes/Place';
// import {Button} from '../components/ui/forms/Button';
import {Button} from 'lincd-mui-base/lib/components/Button';
import {useEffect, useState} from 'react';
import Spinner from '../components/Spinner';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useCometChat} from 'lincd-cometchat/lib/hooks/useCometChat';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
// import {CometChat} from '@cometchat/chat-sdk-javascript';
import {LinkedStorage} from 'lincd/lib/utils/LinkedStorage';
import {Dialog} from '@capacitor/dialog';
import Checkbox from '@mui/material/Checkbox';
import {packageName} from '../package';
import {BlockAction} from 'profile-plus/lib/shapes/BlockAction';
import ActionSheet from '../components/ui/forms/ActionSheet';
import {cl} from 'lincd/lib/utils/ClassNames';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';

const zodiac = require('zodiac-signs')();

interface ProfileDetailProps {
  of: Person;
  onToggleProfileDetails?: any;
  onDislike?: any;
  onLike?: any;
  pendingDislike?: any;
  pendingLike?: any;
  pendingDetails?: any;
  matchScore?: number;
  onClick?;
  onBlock?: (person: Person) => void;
  className?: string;
  showAction?: boolean; // show or hide action dislike, quantum, like
}

const ProfileDetail = ({
  of,
  matchScore,
  onToggleProfileDetails,
  onDislike,
  onLike,
  pendingDislike,
  pendingLike,
  pendingDetails,
  onBlock,
  className,
  showAction = true,
}: ProfileDetailProps) => {
  let person: Person = of as Person;
  const [toggleFab, setToggleFab] = useState(false);
  const [toggleDetails, setToggleDetails] = useState(false);
  let [account, setAccount] = useState<FreeAccount>(
    FreeAccount.getAccountOf(person as any) as FreeAccount,
  );

  const [modalIsLoading, setModelIsLoading] = useState(false);
  const [modelIsOpen, setModelIsOpen] = useState(false);
  const [UID, setUID] = useState('');
  const [userIsBlocked, setUserIsBlocked] = useState(false);
  const [alsoBlockUser, setAlsoBlockUser] = useState<boolean>(false);
  const [complaintType, setComplainType] = useState('');
  let cometChat = useCometChat();
  const [reasonReport, setReasonReport] = useState('');
  let auth = useAuth<Person>();
  let user = auth.user;

  useEffect(() => {
    if (!account) {
      person?.getAccount().then((account) => {
        setAccount(account);
      });
    }
    Server.call(packageName, 'getUserIsBlocked', person, user).then(
      (blockAction: BlockAction) => {
        if (blockAction) {
          setUserIsBlocked(true);
        }
      },
    );
  }, []);

  if (!account) return <Spinner />;

  const handleFAB = () => {
    setToggleFab(!toggleFab);
  };

  let signSymbol, signName;
  if (person.birthDate) {
    let profileBirthMonth = person.birthDate.getMonth() + 1;
    let profileBirthDate = person.birthDate.getDate();
    signName = zodiac.getSignByDate({
      day: profileBirthDate,
      month: profileBirthMonth,
    }).name;
    signSymbol = zodiac.getSignByDate({
      day: profileBirthDate,
      month: profileBirthMonth,
    }).symbol;
  }

  const onRewind = () => {
    setToggleDetails(true);
    // setPastProfile(currentProfileRef.current);
    // afterAssessment('onRewind');
  };

  const ActionSheetToggle = () => {
    setToggleDetails(!toggleDetails);
  };

  const closeModal = () => {
    setModelIsOpen(false);
  };

  useEffect(() => {
    let stateURI = person?.uri;
    if (stateURI) {
      let receiver = Person.getFromURI(stateURI, false);
      console.log('Ensuring user exists:', receiver.uri);
      cometChat.toCometChatUser(receiver).then((cometChatUser) => {
        setUID(cometChatUser.getUid());
      });
      LinkedStorage.loadShape(receiver).then((person) => {
        //TODO: we can remove this if we ensure cometchat user is created when the person signs up
        cometChat.ensureUserExists(receiver).then((receiverCometChatUser) => {
          if (!receiverCometChatUser) {
            console.log('Could not load chat user for this person:', receiver);
            return;
          }
          console.log('User exists:', receiverCometChatUser.uid);
          // setUID(receiverCometChatUser.getUid());
        });
      });
    }
  }, []);

  const blockReportSuccess = () => {
    // put the block action like user who block and user who is blocked
    const block = new BlockAction();
    block.agent = user;
    block.object = person;
    block.save();

    // sent to comet chat server API
    const uids: string[] = [UID];
    //always show success... If cometchat failed, we still blocked them in our db. possibly the user doesnt exist yet in chat
    setModelIsLoading(true);
    // CometChat.blockUsers(uids)
    //   .catch(console.log)
    //   .finally(async () => {
    //     setModelIsLoading(false);
    //     if (alsoBlockUser) {
    //       await Dialog.alert({
    //         title: 'User reported',
    //         message: `User ${person?.givenName} has been reported & blocked.`,
    //       });
    //       closeModal();
    //       //move to the next person
    //       if (onDislike) {
    //         onDislike();
    //       }
    //       if (onBlock) {
    //         onBlock(person);
    //       }
    //       setUserIsBlocked(true);
    //     } else {
    //       await Dialog.alert({
    //         title: 'User blocked',
    //         message: `User blocked. You will no longer see content from ${person?.givenName}.`,
    //       });
    //       closeModal();
    //       //move to the next person
    //       if (onDislike) {
    //         onDislike();
    //       }
    //       if (onBlock) {
    //         onBlock(person);
    //       }
    //       setUserIsBlocked(true);
    //     }
    //   });
  };

  const unblockReportSuccess = () => {
    const uids: string[] = [UID];
    // CometChat.unblockUsers(uids)
    //   .catch(console.log)
    //   .finally(async () => {
    //     await Dialog.alert({
    //       title: 'User unblocked',
    //       message: `User ${person?.givenName} has been unblocked.`,
    //     }).then(closeModal);
    //     setUserIsBlocked(false);
    //     // store unblocked user in database
    //   });
  };

  const openModal = async (type) => {
    setModelIsOpen(true);
    setComplainType(type);
  };

  const reportUserSuccess = async () => {
    if (reasonReport) {
      setModelIsLoading(true);
      //   CometChat.callExtension('report-user', 'POST', 'v1/report', {
      //     uid: UID,
      //     reason: reasonReport,
      //     // "guid": "supergroup" // Used only when reporting the user in a group
      //   })
      //     .catch(console.log)
      //     .finally(async () => {
      //       setModelIsLoading(false);
      //       //reset reason input field for next report
      //       setReasonReport('');
      //       if (!alsoBlockUser) {
      //         await Dialog.alert({
      //           title: 'Report User Success',
      //           message: `User with ID: ${person?.givenName} has been reported.`,
      //         }).then(closeModal);
      //       } else if (alsoBlockUser) {
      //         blockReportSuccess();
      //       }
      //     });
      // } else {
      //   await Dialog.alert({
      //     title: 'Report User Failed',
      //     message: `Please enter a reason.`,
      //   });
    }
  };

  const stylesModal = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #eee',
    boxShadow: 24,
    p: 4,
    pb: 10,
  };
  const label = {inputProps: {'aria-label': 'Block user as well'}};
  return (
    <div className={cl(styles.ProfileDetail, className)}>
      <div>
        {matchScore && (
          <div className={styles.matchScore}>
            <div className={styles.matchScoreIcon} />
            <span className={styles.score}>{matchScore}%</span>
            <span>Quantum Entanglement</span>
          </div>
        )}
        <div className={styles.MoreProfileDetail}>
          <div className={styles.BasicDetailContainer}>
            {onToggleProfileDetails && (
              <div
                className={styles.upArrow}
                onClick={onToggleProfileDetails}
              />
            )}
            <p>My basics</p>
            <div className={styles.BasicDetailContent}>
              {signSymbol && (
                <Tooltip title={'Astrology sun sign'}>
                  <div className={styles.SignContainer}>
                    <span className={styles.SignIcon}>{signSymbol}</span>
                    {signName}
                  </div>
                </Tooltip>
              )}
              {person.smokingHabit && (
                <Tooltip title={'Smoking habit'}>
                  <div className={styles.BasicDetail}>
                    <img
                      src={asset('/images/SmokingIcon.svg')}
                      alt="smoking-icon"
                    />
                    {person.smokingHabit}
                  </div>
                </Tooltip>
              )}
              {person.workoutFrequency && (
                <Tooltip title={'Workout frequency'}>
                  <div className={styles.BasicDetail}>
                    <img
                      src={asset('/images/WorkoutIcon.svg')}
                      alt="exercise-icon"
                    />
                    {person.workoutFrequency}
                  </div>
                </Tooltip>
              )}
              {person.myersBriggsPersonalityType && (
                <Tooltip title={'Meyers Briggs personality type'}>
                  <div className={styles.BasicDetail}>
                    <img
                      src={asset('/images/PersonalityTypeIcon.svg')}
                      alt="personality-type-icon"
                    />
                    {person.myersBriggsPersonalityType}
                  </div>
                </Tooltip>
              )}
              {person.chineseZodiac && (
                <Tooltip title={'Chinese Zodiac'}>
                  <div className={styles.BasicDetail}>
                    <img
                      src={asset('/images/ChineseZodiacIcon2.svg')}
                      alt="chinese-zodiac-icon"
                    />
                    {person.chineseZodiac}
                  </div>
                </Tooltip>
              )}
              {person.fourTwentyFriendly && (
                <Tooltip title={'Four-twenty friendly'}>
                  <div className={styles.BasicDetail}>
                    <img
                      src={asset('/images/WeedIcon.svg')}
                      alt="four-twenty-friendly-icon"
                    />
                    {person.fourTwentyFriendly}
                  </div>
                </Tooltip>
              )}
              {person.familyPlans && (
                <Tooltip title={'Family plans'}>
                  <div className={styles.BasicDetail}>
                    <img
                      src={asset('/images/FamilyPlanIcon.svg')}
                      alt="family-plan-icon"
                    />
                    {person.familyPlans}
                  </div>
                </Tooltip>
              )}
              {person.drinkFrequency && (
                <Tooltip title={'Drink frequency'}>
                  <div className={styles.BasicDetail}>
                    <img
                      src={asset('/images/DrinkingIcon2.svg')}
                      alt="drinking-icon"
                    />
                    {person.drinkFrequency}
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          <div>
            {person.relationshipGoal && person.relationshipGoal.size > 0 && (
              <div className={styles.MoreDetailContainer}>
                <p>Relationship Goal</p>{' '}
                <div>
                  {person.relationshipGoal.map((item) => {
                    return (
                      <span key={item.value} className={styles.MoreDetails}>
                        {item.value}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
            {person.homeLocation.size > 0 && (
              <div className={styles.MoreDetailContainer}>
                <p>Based in</p>
                <div className={styles.chipValues}>
                  {person.homeLocation.map((location: Place) => {
                    return (
                      <PlaceChip
                        of={location}
                        key={location.toString()}
                        variant={'filled'}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {account.displaySexualOrientation &&
              person.sexualOrientation.size > 0 && (
                <div className={styles.MoreDetailContainer}>
                  <p>Sexual Orientation</p>{' '}
                  <div>
                    {person.sexualOrientation.map((sexualOrientation) => {
                      return (
                        <span
                          key={sexualOrientation.value}
                          className={styles.MoreDetails}
                        >
                          {sexualOrientation.value}
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}
            {person.passions.size > 0 && (
              <div className={styles.MoreDetailContainer}>
                <p>Passions</p>{' '}
                <div>
                  {person.passions.map((passion) => {
                    return (
                      <span key={passion.value} className={styles.MoreDetails}>
                        {passion.value}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
            {person.beliefs.size > 0 && (
              <div className={styles.MoreDetailContainer}>
                <p>Beliefs</p>{' '}
                <div>
                  {person.beliefs.map((belief) => {
                    return (
                      <span key={belief.value} className={styles.MoreDetails}>
                        {belief.value}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
            {person.systemIdeologies.size > 0 && (
              <div className={styles.MoreDetailContainer}>
                <p>System Ideologies</p>{' '}
                <div>
                  {person.systemIdeologies.map((systemIdeology) => {
                    return (
                      <span
                        key={systemIdeology.value}
                        className={styles.MoreDetails}
                      >
                        {systemIdeology.value}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
            {person.coreValues.size > 0 && (
              <div className={styles.MoreDetailContainer}>
                <p>Core Values</p>{' '}
                <div>
                  {person.coreValues.map((coreValue) => {
                    return (
                      <span
                        key={coreValue.value}
                        className={styles.MoreDetails}
                      >
                        {coreValue.value}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
            {person.spiritualPractices.size > 0 && (
              <div className={styles.MoreDetailContainer}>
                <p>Spiritual Practices</p>{' '}
                <div>
                  {person.spiritualPractices.map((spiritualPractice) => {
                    return (
                      <span
                        key={spiritualPractice.value}
                        className={styles.MoreDetails}
                      >
                        {spiritualPractice.value}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
            {person.sacredTexts.size > 0 && (
              <div className={styles.MoreDetailContainer}>
                <p>Sacred Text</p>{' '}
                <div>
                  {person.sacredTexts.map((sacredTexts) => {
                    return (
                      <span
                        key={sacredTexts.value}
                        className={styles.MoreDetails}
                      >
                        {sacredTexts.value}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
            {person.mayanAstrology && (
              <div className={styles.MoreDetailContainer}>
                <p>Mayan Astrology</p>{' '}
                <div className={styles.MoreDetails}>
                  {person.mayanAstrology}
                </div>
              </div>
            )}
            {person.loveLanguage1 && (
              <div className={styles.MoreDetailContainer}>
                <p>Love Language #1</p>{' '}
                <div className={styles.MoreDetails}>{person.loveLanguage1}</div>
              </div>
            )}
            {person.loveLanguage2 && (
              <div className={styles.MoreDetailContainer}>
                <p>Love Language #2</p>{' '}
                <div className={styles.MoreDetails}>{person.loveLanguage2}</div>
              </div>
            )}
            {person.loveLanguage3 && (
              <div className={styles.MoreDetailContainer}>
                <p>Love Language #3</p>{' '}
                <div className={styles.MoreDetails}>{person.loveLanguage3}</div>
              </div>
            )}
            {person.loveLanguage4 && (
              <div className={styles.MoreDetailContainer}>
                <p>Love Language #4</p>{' '}
                <div className={styles.MoreDetails}>{person.loveLanguage4}</div>
              </div>
            )}
            {person.loveLanguage5 && (
              <div className={styles.MoreDetailContainer}>
                <p>Love Language #5</p>{' '}
                <div className={styles.MoreDetails}>{person.loveLanguage5}</div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.flexSide}>
          <Button
            startIcon={
              <img
                src={asset('/images/report.png')}
                className={styles.reportUser}
              />
            }
            variant={'text'}
            onClick={() => openModal('report')}
            color="primary"
          >
            Report {person?.givenName}
          </Button>
          {userIsBlocked ? (
            <Button
              variant={'text'}
              startIcon={
                <img
                  src={asset('/images/block.png')}
                  className={styles.blockUser}
                />
              }
              color="primary"
              onClick={() => openModal('unblocked')}
            >
              Unblock {person?.givenName}
            </Button>
          ) : (
            <Button
              startIcon={
                <img
                  src={asset('/images/block.png')}
                  className={styles.blockUser}
                />
              }
              variant={'text'}
              color="primary"
              onClick={() => openModal('block')}
            >
              Block {person?.givenName}
            </Button>
          )}
        </div>
      </div>
      {/*{*/}
      {/*  // in the Matches page, buttons won't render*/}
      {/*location.pathname == '/' && (*/}
      {showAction && (
        <div className={styles.FooterContainer}>
          <div className={styles.ProfileDetailsFooter}>
            <Button
              onClick={onDislike}
              className={pendingDislike ? styles.pendingDislike : ''}
            >
              <img src={asset('/images/DislikeSwipe.svg')} alt="dislike-icon" />
            </Button>
            <Button
              onClick={onToggleProfileDetails}
              className={pendingDetails ? styles.pendingDetails : ''}
            >
              <img src={asset('/images/QuantumSwipe.svg')} alt="quantum-icon" />
            </Button>
            <Button
              onClick={onLike}
              className={pendingLike ? styles.pendingLike : ''}
            >
              <img src={asset('/images/LikeSwipe.svg')} alt="like-icon" />
            </Button>
          </div>
        </div>
      )}
      <ActionSheet isOpen={toggleDetails} onClose={ActionSheetToggle}>
        <div className={styles.popupContainer}>
          <div className={styles.popupHeader}>
            <img src={asset('/images/sp_logo.svg')} alt="sp-logo-popup" />
            <h2>Upgrade to Premium</h2>
          </div>
          <p>
            Start your 2 months FREE Premium trial. Explore our premium features
            to connect with your community and find love.
          </p>
          <div className={styles.popupContent}>
            <p className={styles.smallTextHeader}>
              Included with Spiritual Playmates &trade;
            </p>
            <ul className={styles.premiumList}>
              <li>Priority Likes</li>
              <li>5 Free Quantum Likes per week</li>
              <li>
                {'  '}
                Unlimited rewinds
                <p className={styles.subtle}>Undo your last swipe</p>
              </li>
              <li>3 Profile Boosts per week</li>
            </ul>
          </div>
          <div className={styles.popupContent}>
            <p className={styles.smallHeading}>Free Trial</p>
            <h3>2 Months</h3>
            <p className={styles.subtle}>Then $24.99/mo</p>
            <div className={styles.subFlex}>
              <p>$0.00 total</p>
              <p>No credit card needed</p>
            </div>
          </div>
          <Button
            variant="filled"
            color="primary"
            onClick={() => ''}
            className={styles.popupButton}
          >
            Upgrade Now
          </Button>
        </div>
      </ActionSheet>
      <div>
        <Modal
          open={modelIsOpen}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={stylesModal}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {complaintType === 'report'
                ? 'Report'
                : complaintType === 'block'
                  ? 'Block'
                  : 'Unblock'}{' '}
              User
            </Typography>
            <Typography id="modal-modal-description" sx={{mt: 2, mb: 2}}>
              Do you want to{' '}
              {complaintType === 'report'
                ? 'report'
                : complaintType === 'block'
                  ? 'block'
                  : 'unblock'}{' '}
              {person?.givenName}?
            </Typography>

            {modalIsLoading ? (
              <Spinner />
            ) : (
              <>
                {complaintType === 'report' && (
                  <>
                    <Typography>
                      If so please enter reason for the report.
                    </Typography>
                    <Input
                      className={styles.reportedInput}
                      type="text"
                      placeholder="Reason"
                      value={reasonReport}
                      multiline={true}
                      fullWidth={true}
                      onChange={(e) => setReasonReport(e.target.value)}
                    />
                  </>
                )}
                {complaintType === 'report' && (
                  <FormControlLabel
                    className={styles.formLabel}
                    control={
                      <Checkbox
                        value={alsoBlockUser}
                        onChange={() => setAlsoBlockUser(!alsoBlockUser)}
                      />
                    }
                    label={`Also block ${person?.givenName} (optional)`}
                  />
                )}
                <div className={styles.reportUserPopup}>
                  <Button onClick={closeModal}>Cancel</Button>
                  <Button
                    onClick={
                      complaintType === 'report'
                        ? reportUserSuccess
                        : complaintType === 'block'
                          ? blockReportSuccess
                          : unblockReportSuccess
                    }
                  >
                    {complaintType === 'report'
                      ? 'Report'
                      : complaintType === 'block'
                        ? 'Block'
                        : 'Unblock'}
                  </Button>
                </div>
              </>
            )}
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default ProfileDetail;
