import React, {useEffect} from 'react';
import PageLayout from '../components/layout/PageLayout';
import Spinner from '../components/Spinner';
import {useSearchParams} from 'react-router-dom';
import {ROUTES} from '../routes/AppRoutes';

export default function MagicLinkCallback() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    // verify the url callback, if match will redirect to index path
    const verify = async () => {
      try {
        const response = await fetch(
          `/auth/magiclogin/callback?token=${token}`,
        );
        if (response.ok) {
          window.location.assign(ROUTES.index.path);
        } else {
          console.log('Authentication failed');
        }
      } catch (err) {
        console.error('Error during authentication:', err);
      }
    };

    verify();
  }, [token]);

  return (
    <PageLayout>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Spinner />
      </div>
    </PageLayout>
  );
}
