import React from 'react';
import {default as MuiSnackbar} from '@mui/material/Snackbar';
import {default as MuiAlert} from '@mui/material/Alert';
import {default as MuiAlertTitle} from '@mui/material/AlertTitle';
import {default as MuiSlide} from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import {default as MuiClose} from '@mui/icons-material/Close';

import './SnackbarAlert.scss';
import {default as style} from './SnackbarAlert.scss.json';

const SnackbarAlert = ({snackbar, vertical, horizontal, onOpen, onClose}) => {
  const {message, title, type, open, duration, customStyle} = snackbar;

  return (
    <MuiSnackbar
      className={style.SnackbarAlertRoot}
      anchorOrigin={{vertical, horizontal}}
      open={open}
      autoHideDuration={duration}
      TransitionComponent={MuiSlide}
      onClose={onClose}
    >
      <MuiAlert
        className={customStyle ? `${style[customStyle]}` : ''}
        severity={type}
        variant="filled"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <MuiClose fontSize="inherit" />
          </IconButton>
        }
      >
        {title && <MuiAlertTitle>{title}</MuiAlertTitle>}
        {message}
      </MuiAlert>
    </MuiSnackbar>
  );
};

export default SnackbarAlert;
