import React from 'react';
import {default as MuiFormControl} from '@mui/material/FormControl';
import {default as style} from './FormControl.scss.json';
import {cl} from 'lincd/lib/utils/ClassNames';

export function FormControl(props) {
  let restProps = {...props};
  let classNameProps = props.className;
  delete restProps.className;
  return (
    <MuiFormControl
      className={cl(style.FormControl, classNameProps)}
      {...restProps}
    ></MuiFormControl>
  );
}
