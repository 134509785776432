import React, {useEffect, useRef} from 'react';
import './SwipePreferenceFilter.scss';
import style from './SwipePreferenceFilter.scss.json';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import RadioChip from './RadioChip';
import {gender, interestedInGenderValues} from '../constants';
import {Slider} from 'lincd-mui-base/lib/components/Slider';
import {kmToMiles} from '../utils/helper';
import {AgeSlider} from './AgeSlider';
import {Button} from 'lincd-mui-base/lib/components/Button';
import {cl} from 'lincd/lib/utils/ClassNames';
import {useLocation} from 'react-router-dom';
import {ROUTES} from '../routes/AppRoutes';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';

type SwipePreferenceFilterProps = {
  onDismiss: () => void; // trigger close filter
  open: boolean; // state open filter
  onApply: () => void; // apply filter
};

const SwipePreferenceFilter = ({
  onDismiss,
  open,
  onApply,
}: SwipePreferenceFilterProps) => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  let auth = useAuth<Person, FreeAccount>();
  let user = auth.user;
  let userAccount = auth.userAccount;
  const location = useLocation();

  // set default value property maxReachKm when is null based on relationshipType
  useEffect(() => {
    if (user.relationshipType === 'friendship') {
      userAccount.maxReachKmFriendships =
        userAccount.maxReachKmFriendships || 100;
    } else if (user.relationshipType === 'dating') {
      userAccount.maxReachKmDating = userAccount.maxReachKmDating || 100;
    } else {
      userAccount.maxReachKmFriendships =
        userAccount.maxReachKmFriendships || 100;
      userAccount.maxReachKmDating = userAccount.maxReachKmDating || 100;
    }
  }, []);

  // save interestedInGender based from browse dates or friend page
  const interestInDatingProperty =
    location.pathname === ROUTES.browse_friends.path
      ? 'interestedInGenderFriendships'
      : 'interestedInGenderDating';

  const maxReachKmProperty =
    location.pathname === ROUTES.browse_friends.path
      ? 'maxReachKmFriendships'
      : 'maxReachKmDating';

  const mode =
    location.pathname === ROUTES.browse_friends.path ? 'friendship' : 'dating';

  // handle click button apply filter
  // and then close filter
  const onApplyFilter = () => {
    onApply();
    onDismiss();
  };

  // check user is authenticated for make sure filter already to use
  if (!user || !userAccount) {
    return null;
  }

  return (
    <div className={style.ContainerSidebar}>
      <div
        className={cl(style.filterSidebar, open && style['open'])}
        ref={sidebarRef}
      >
        <div className={style.centerSidebar}>
          <div className={style.topSidebar}>
            <Button
              className={style.closeBtn}
              onClick={onApplyFilter}
              variant="text"
            >
              <img src={asset('/images/CloseIcon.png')} alt="close button" />
            </Button>
            <h3>Date Filters</h3>
          </div>
          <div className={style.bigContainer}>
            <h4>Gender</h4>
            <RadioChip
              values={interestedInGenderValues}
              className={style.radioChip}
              of={user}
              valueProperty={interestInDatingProperty}
            />
            <h4>Age</h4>
            <AgeSlider className={style.slider} mode={mode} />
            <h4>Distance</h4>
            <Slider
              of={userAccount}
              valueProperty={maxReachKmProperty}
              min={0}
              max={100}
              defaultValue={100}
              printCurrentValueFn={(currentConvertedValue) =>
                Math.ceil(currentConvertedValue) == 100
                  ? 'Any distance'
                  : `Up to ${Math.round(
                      kmToMiles(currentConvertedValue),
                    )} miles away`
              }
              className={style.slider}
            />
            <Button
              color="primary"
              fullWidth={true}
              className={cl(style.applyBtn)}
              onClick={onApplyFilter}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwipePreferenceFilter;
