import {Typography} from '../components/ui/forms/Typography';
import {useNavigate} from 'react-router-dom';
import {passions} from '../constants';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import './Q9_Passions.scss';
import MultiSearchChip from '../components/ui/forms/MultiSearchChip';
import {QuestionContainer} from '../components/QuestionContainer';
import {QuestionHeader} from '../components/QuestionHeader';
import ContinueButton from '../components/ContinueButton';
import {ROUTES} from '../routes/AppRoutes';

const maxPassions = 7;
const Q9_Passions = () => {
  const navigate = useNavigate();
  let auth = useAuth<Person, FreeAccount>();
  let user = auth.user;

  return (
    <QuestionContainer>
      <QuestionHeader>Passions</QuestionHeader>
      <Typography mb={3}>
        Pick up to {maxPassions} things you love. It’ll help you match with
        people who love them too.
      </Typography>
      <MultiSearchChip
        values={passions}
        of={user}
        property={'passions'}
        maxCount={maxPassions}
      />
      <ContinueButton onClick={() => navigate(ROUTES.q10_based_in.path)} />
    </QuestionContainer>
  );
};

export default Q9_Passions;
