import './EditProfilePictures.scss';
import style from './EditProfilePictures.scss.json';
import React from 'react';
import {ProfilePictureUploader} from 'profile-plus/lib/components/ProfilePictureUploader';

interface EditProfilePicturesProps {}

const EditProfilePictures = ({}: EditProfilePicturesProps) => {
  return (
    <div className={style.UploaderWrap}>
      {Array.from({length: 6}, (_, index) => (
        <ProfilePictureUploader
          key={index}
          aspectRatio={3 / 5}
          thumbnailWidth={169}
          property={'profilePicture' + (index + 1 > 1 ? index + 1 : '')}
          confirmText={'save'}
        />
      ))}
    </div>
  );
};

export default EditProfilePictures;
