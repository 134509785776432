import React from 'react';
import './PlacePreview.scss';
import {default as style} from './PlacePreview.scss.json';
import {linkedComponent, registerPackageModule} from '../package';
import {Place} from 'lincd-schema/lib/shapes/Place';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {default as PlaceIcon} from '@mui/icons-material/Place';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import {useStyles} from 'lincd/lib/utils/Hooks';

interface PlacePreviewProps {
  onRemove?: (place: Place) => void;
  variant?: 'default' | 'small';
}

export const PlacePreview = linkedComponent<Place, PlacePreviewProps>(
  Place.request((place) => ({
    name: place.name,
  })),
  ({
    source,
    linkedData: {name},
    variant = 'default',
    onRemove,
    ...restProps
  }) => {
    restProps = useStyles(restProps, [style.PlacePreview, style[variant]]);
    return (
      <ListItem {...restProps}>
        <PlaceIcon fontSize="small" />
        <ListItemText className={style.placePreviewText}>{name}</ListItemText>
        {onRemove && (
          <RemoveCircleOutline
            className={style.removeIcon}
            onClick={() => onRemove(source)}
          />
        )}
      </ListItem>
    );
  },
);

//register all components in this file
registerPackageModule(module);
