import {useEffect, useState} from 'react';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';
import './UpgradeAccountSuccess.scss';
import {default as style} from './UpgradeAccountSuccess.scss.json';
import {Button} from './ui/forms/Button';
import {Typography} from './ui/forms/Typography';
import {cl} from 'lincd/lib/utils/ClassNames';

interface UpdateAccountSuccessProps {
  onAction?: () => void;
  className?: string;
}
export const UpgradeAccountSuccess = ({
  onAction,
  className,
}: UpdateAccountSuccessProps) => {
  const [count, setCount] = useState(15);
  const onActionHandle = () => {
    if (onAction) {
      onAction();
    }
  };

  // showing the countdown timer for 15 seconds
  useEffect(() => {
    let timer;

    timer = setInterval(async () => {
      setCount((prevCount) => {
        if (prevCount === 1) {
          clearInterval(timer);
          return 0;
        } else {
          return prevCount - 1;
        }
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <div className={cl(style.premiumActivate, className)}>
        <img
          src={asset('/images/congratulations.gif')}
          alt="congratulations"
          className={style.congratulations}
        />
        <Typography variant="h6" component="h3">
          Congratulations!
        </Typography>
        <Typography>
          You&apos;re now a Premium member of Spiritual Playmates
        </Typography>
        <Typography>
          Please wait a while were setting up your account with the new features
        </Typography>
        {count === 0 ? null : <Typography>{count}</Typography>}
        <Button
          variant="contained"
          color="primary"
          // disabled={count !== 0}
          className={style.btn}
          onClick={onActionHandle}
        >
          Try the new features!
        </Button>
      </div>
    </>
  );
};
