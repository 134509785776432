import {TextField} from '../components/ui/forms/TextField';
import {Typography} from '../components/ui/forms/Typography';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {useNavigate} from 'react-router-dom';
import LocationInput from '../components/ui/forms/LocationInput/LocationInput';
import './Q10_BasedIn.scss';
import ContinueButton from '../components/ContinueButton';
import {ROUTES} from '../routes/AppRoutes';
import {QuestionContainer} from '../components/QuestionContainer';
import {QuestionHeader} from '../components/QuestionHeader';

export interface selectedLocations {
  name: string;
  place_id: string;
}

const Q10_BasedIn = () => {
  const navigate = useNavigate();

  let auth = useAuth<Person, FreeAccount>();
  let user = auth.user;

  const setOccupation = (e) => {
    let occupation = e.currentTarget.value;
    user.hasOccupation = occupation;
  };

  return (
    <div>
      <QuestionContainer>
        <QuestionHeader>I'm based in</QuestionHeader>
        <Typography mb={3}>
          Please choose the location(s) where you generally spend most time
        </Typography>
        <LocationInput
          of={user}
          property={'homeLocation'}
          multiple={true}
          maxCount={3}
        />
      </QuestionContainer>
      <QuestionContainer>
        <QuestionHeader>My Occupation is</QuestionHeader>
        <TextField defaultValue={user.hasOccupation} onBlur={setOccupation} />
      </QuestionContainer>
      <ContinueButton onClick={() => navigate(ROUTES.q11_notifications.path)} />
    </div>
  );
};

export default Q10_BasedIn;
