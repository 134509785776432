import React from 'react';
import {Typography} from '@mui/material';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {useNavigate} from 'react-router-dom';
import './HouseRules.scss';
import style from './HouseRules.scss.json';
import {Button} from '../components/ui/forms/Button';
import PageLayout from '../components/layout/PageLayout';

const HouseRules = () => {
  const navigate = useNavigate();
  let auth = useAuth<Person, FreeAccount>();
  let userAccount = auth.userAccount;

  const onAgree = () => {
    userAccount.houseRulesAgreed = true;
    navigate('/name');
  };

  return (
    <PageLayout className={style.rulesbox}>
      <div className={style.splogo} />
      <Typography variant="h6" className={style.title}>
        Spiritual Playmates
      </Typography>
      <div className={style.rulesbox2}>
        {/* <Typography variant="body1">Please follow these House Rules.</Typography> */}

        <div>
          <Typography variant="subtitle1" className={style.bold}>
            Be yourself
          </Typography>
          <Typography variant="body1">
            Make sure your photos, age, and bio are true to who you are.
          </Typography>
        </div>

        <div>
          <Typography variant="subtitle1" className={style.bold}>
            Stay safe
          </Typography>
          <Typography variant="body1">
            Don't be too quick to give out personal information.
          </Typography>
        </div>

        <div>
          <Typography variant="subtitle1" className={style.bold}>
            Date Safely
          </Typography>
          <Typography variant="body1">
            Respect others and treat them as you would like to be treated.
          </Typography>
        </div>

        <div>
          <Typography variant="subtitle1" className={style.bold}>
            Be proactive
          </Typography>
          <Typography variant="body1">Always report bad behavior.</Typography>
        </div>
        {/*<ContinueButton text={'Agree to terms'} onClick={() => onAgree()} />*/}
      </div>
      <Button
        className={style.agreeButton}
        color={'secondary'}
        onClick={() => onAgree()}
      >
        Agree to terms
      </Button>
    </PageLayout>
  );
};

export default HouseRules;
