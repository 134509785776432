import React from 'react';
import {default as MuiListItemText} from '@mui/material/ListItemText';
import {default as style} from './ListItemText.scss.json';

export function ListItemText(props) {
  return (
    <MuiListItemText
      className={style.ListItemText}
      {...props}
    ></MuiListItemText>
  );
}
