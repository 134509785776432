import React, {useEffect, useState} from 'react';
import FooterMenu from '../components/FooterMenu';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {ROUTES} from '../routes/AppRoutes';
import {MatchPreview} from 'lincd-dating/lib/components/MatchPreview';
import {Like} from 'lincd-dating/lib/shapes/Like';
import './Matches.scss';
import {default as style} from './Matches.scss.json';
import PageLayout from '../components/layout/PageLayout';
import Modal from '@mui/material/Modal';
import {ProfilePictureAndDetails} from './ProfilePictureAndDetails';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import {Tab} from '@mui/base/Tab';
import {TabsList} from '@mui/base/TabsList';
import {TabPanel} from '@mui/base/TabPanel';
import {Tabs} from '@mui/base/Tabs';
import HeaderNavigation from '../components/layout/HeaderNavigation';
import {cl} from 'lincd/lib/utils/ClassNames';
import Spinner from '../components/Spinner';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';

interface MatchesProps {
  of?: any;
  mode: 'dating' | 'friendship';
}

const Matches = ({of, mode = 'dating'}: MatchesProps) => {
  let navigate = useNavigate();
  const [likes, setLikes] = useState<ShapeSet<Like>>();
  let auth = useAuth<Person, FreeAccount>();
  const [selectedMatch, setSelectedMatch] = useState<Person>();
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab: 'likes' | 'matches' =
    (searchParams.get('q') as 'likes' | 'matches') || 'likes';

  const onToggleShowChat = (person) => {
    setSelectedMatch(null);
    navigate(ROUTES.chat.path, {
      state: {uri: person.uri},
    });
  };

  useEffect(() => {
    // when no search params, add likes as a default value
    if (searchParams.size === 0 || searchParams.size === undefined) {
      setSearchParams(`q=likes`);
    }
  }, []);

  useEffect(() => {
    //TODO add pagination
    setLikes(null);
    if (activeTab === 'likes') {
      auth.user.getAllLikes(mode).then(({likes}) => {
        setLikes(likes);
      });
    } else if (activeTab === 'matches') {
      auth.user.getAllMatches(mode).then(({likes}) => {
        setLikes(likes);
      });
    }
  }, [activeTab]);

  const onBlock = (person: Person) => {
    setLikes(
      likes.filter((like) => {
        return like.person.uri != person.uri;
      }),
    );
    setSelectedMatch(null);
  };

  const onClickMatch = (matchingLike) => {
    // onToggleShowChat(matchingLike.person)
    setSelectedMatch(matchingLike.person);
  };

  const closeModal = () => {
    setSelectedMatch(null);
  };

  const renderLikes = () => {
    return (
      <div className={style.matchesContainer}>
        {likes &&
          likes.map((like) => {
            return (
              <MatchPreview
                of={like as any}
                key={like.uri}
                onClick={onClickMatch.bind(this, like)}
                className={style.matchPreview}
              />
            );
          })}
      </div>
    );
  };

  const renderSelectedMatchModal = () => {
    return (
      <Modal open={selectedMatch && true} onClose={closeModal}>
        <div className={style.modalContainer}>
          <ProfilePictureAndDetails of={selectedMatch} onBlock={onBlock} />
          <div className={style.modalFooter}>
            <div onClick={closeModal}>
              <ChevronLeft fontSize="inherit" />
            </div>
            <div onClick={() => onToggleShowChat(selectedMatch)}>
              <img src={asset('/images/ChatFooter.svg')} alt="chat-icon" />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <PageLayout className={style.root}>
      <HeaderNavigation pageTitle={activeTab} />
      <div className={style.matchesContainerBox}>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => {
            setSearchParams(`q=${newValue}`);
          }}
        >
          <TabsList slotProps={{root: {className: `${style.tabsList}`}}}>
            <Tab
              value={'likes'}
              className={cl(
                style.tabListing,
                activeTab === 'likes' && style.tabActive,
              )}
            >
              {/* <img src="images/smiling-face.svg" alt="likes" /> */}
              Likes
            </Tab>
            <Tab
              value={'matches'}
              className={cl(
                style.tabListing,
                activeTab === 'matches' && style.tabActive,
              )}
            >
              {/* <img src="images/in-love.svg" alt="matches" /> */}
              Matches
            </Tab>
          </TabsList>

          <TabPanel value={'likes'} className={style.tabContent}>
            {selectedMatch && renderSelectedMatchModal()}
            {likes && likes.size == 0 && (
              <p className={style.emptyList}>
                {activeTab === 'likes'
                  ? "You didn't like anyone yet. Feeling picky? Refresh your intentions and"
                  : "You don't have any dharmic matches yet. Call out to the universe and "}{' '}
                keep on <Link to={ROUTES.index.path}>surfing</Link>!
              </p>
            )}
            {renderLikes()}
            {!likes && <Spinner />}
          </TabPanel>
          <TabPanel value={'matches'} className={style.tabContent}>
            {selectedMatch && renderSelectedMatchModal()}
            {likes && likes.size == 0 && (
              <p className={style.emptyList}>
                You don't have any dharmic matches yet.
                <br />
                Keep on <Link to={ROUTES.index.path}>surfing</Link>!
              </p>
            )}
            {renderLikes()}
            {!likes && <Spinner />}
          </TabPanel>
        </Tabs>
      </div>

      {/* hide footer when modal match person open */}
      {!selectedMatch && <FooterMenu />}
    </PageLayout>
  );
};

export default Matches;
