import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {useNavigate} from 'react-router-dom';
import {TextField} from '../components/ui/forms/TextField';
import {QuestionHeader} from '../components/QuestionHeader';
import ContinueButton from '../components/ContinueButton';
import {default as style} from './Q1_Name.scss.json';
import './Q1_Name.scss';
import {ROUTES} from '../routes/AppRoutes';
import {QuestionContainer} from '../components/QuestionContainer';
import React, {useState} from 'react';
import {useCometChat} from 'lincd-cometchat/lib/hooks/useCometChat';
import {FacebookEvents} from 'capacitor-facebook-events';

const Q1_Name = () => {
  const navigate = useNavigate();

  let auth = useAuth<Person, FreeAccount>();
  let [error, setError] = useState<string>('');
  let user = auth.user;
  let chat = useCometChat();
  const setName = function (e) {
    let name = e.currentTarget.value;
    user.givenName = name;
    if (name) {
      setError('');
    }
  };
  const setHeroName = (e) => {
    let heroName = e.currentTarget.value;
    user.alternateName = heroName;
  };
  const onContinue = () => {
    if (!user.givenName && user.name) {
      user.givenName = user.name.split(' ').shift();
    }

    if (user.givenName) {
      // log event when user start profile setup
      FacebookEvents.logEvent({
        event: 'complete_registration',
        params: {
          profile_setup_start: 1,
        },
      });

      navigate(ROUTES.q2_photo.path);
    } else {
      setError('Your name is required');
    }
  };

  return (
    <div className={style.nameContainer}>
      <QuestionContainer>
        <QuestionHeader>
          What's your first name?<span className={style.required}>*</span>
        </QuestionHeader>
        <TextField
          error={error !== ''}
          helperText={error}
          required
          onBlur={setName}
          defaultValue={
            user.givenName || (user.name ? user.name.split(' ').shift() : '')
          }
        />
      </QuestionContainer>
      <QuestionContainer>
        <QuestionHeader>
          What's your spiritual / super-hero name?
        </QuestionHeader>
        <TextField onBlur={setHeroName} defaultValue={user.alternateName} />
        {/* <TextValueEditor of={user} valueProperty={'alternateName'} /> */}
      </QuestionContainer>
      <ContinueButton
        text={'This will be shown on your profile.'}
        onClick={onContinue}
      />
    </div>
  );
};

export default Q1_Name;
