import React, {useEffect, useState} from 'react';
import {
  CometChatConversations,
  CometChatMessages,
  CometChatUIKit,
} from '@cometchat/chat-uikit-react';
import './Chat.scss';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import {Button} from 'lincd-mui-base/lib/components/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {useLocation, useNavigate} from 'react-router-dom';
import Spinner from '../components/Spinner';
import {useCometChat} from 'lincd-cometchat/lib/hooks/useCometChat';
import {
  ConversationsStyle,
  MessageComposerConfiguration,
  MessageComposerStyle,
  MessageHeaderConfiguration,
  MessageHeaderStyle,
  UsersStyle,
  MessageListStyle,
  MessageListConfiguration,
  WithMessagesStyle,
} from '@cometchat/uikit-shared';
import FooterMenu from '../components/FooterMenu';
import {CometChat} from '@cometchat/chat-sdk-javascript';
import BackButton from '../components/BackButton';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {LinkedStorage} from 'lincd/lib/utils/LinkedStorage';
import {ProfilePictureAndDetails} from './ProfilePictureAndDetails';
import {PageHeader} from '../components/PageHeader';
import {ROUTES} from '../routes/AppRoutes';
import style from './Chat.scss.json';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {
  CometChatUIKitConstants,
  CometChatMessageTemplate,
  CometChatTheme,
  fontHelper,
  CometChatActionsIcon,
} from '@cometchat/uikit-resources';
import {Dialog} from '@capacitor/dialog';
import {ListItemStyle} from '@cometchat/uikit-elements';
import HeaderNavigation from '../components/layout/HeaderNavigation';
import PageLayout from '../components/layout/PageLayout';
import {Capacitor} from '@capacitor/core';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';

const Chat = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [msgID, setMessageID] = useState<any>('');
  const auth = useAuth<Person, any>();
  const [messageReported, setMessageReported] = useState<string>('');
  const personUser = auth.user;
  const [reasonReported, setReasonReported] = useState<string>('');
  let cometChat = useCometChat();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [templates, setTemplates] = React.useState<CometChatMessageTemplate[]>(
    [],
  );
  let [chatWith, setChatWith] = React.useState<any>();
  const [alsoBlockUser, setAlsoBlockUser] = React.useState<boolean>(false);
  let [showProfile, setShowProfile] = React.useState<boolean>();
  let [loading, setLoading] = React.useState<boolean>(false);
  let stateURI = location?.state?.uri;

  useEffect(() => {
    //if we received state from the previous page (see Matches.tsx), then we want to chat directly to that user
    if (stateURI) {
      setLoading(true);
      let receiver = Person.getFromURI(stateURI, false);
      LinkedStorage.loadShape(receiver).then((person) => {
        console.log('person:', person);
        cometChat.ensureUserExists(receiver).then((receiverCometChatUser) => {
          //let's make sure to first load the data of the person
          if (!receiverCometChatUser) {
            console.log('Could not load chat user for this person:', receiver);
            navigate(ROUTES.chat.path);
            setLoading(false);
            return;
          }
          console.log('User exists:', receiverCometChatUser.getUid());
          setChatWith(receiverCometChatUser);
          setLoading(false);
        });
      });
    }
    // add listener for deleting unappropiate messages
    // Add message listener
    const listenerID = 'comet-chat-listener';
    CometChat.addMessageListener(
      listenerID,
      new CometChat.MessageListener({
        onMessageDeleted: (message) => {
          console.log('Deleted Message', message);
        },
      }),
    );

    return () => {
      CometChat.removeMessageListener(listenerID);
    };
  }, [stateURI]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onHeaderClicked = (e: any) => {
    setShowProfile(!showProfile);
  };
  useEffect(() => {
    if (!chatWith) {
      setShowProfile(false);
    }
  }, [chatWith]);

  useEffect(() => {
    let definedTemplates =
      CometChatUIKit.getDataSource().getAllMessageTemplates();
    let textTemplate = definedTemplates.find(
      (template) =>
        template.category === CometChatUIKitConstants.MessageCategory.message &&
        template.type == CometChatUIKitConstants.MessageTypes.text,
    );
    if (textTemplate) {
      const textTemplateObjectIndex = definedTemplates.indexOf(textTemplate);
      textTemplate.options = (
        loggedInUser: CometChat.User,
        message: CometChat.BaseMessage,
        theme: CometChatTheme,
        group?: CometChat.Group | undefined,
      ) => getOptions(loggedInUser, message, theme, group);

      console.log('textTemplateObjectIndex', textTemplateObjectIndex);
      if (textTemplateObjectIndex > -1) {
        definedTemplates.splice(textTemplateObjectIndex, 1, textTemplate);
        console.log('definedTemplates', definedTemplates);
        setTemplates(definedTemplates);
      }
    }
  }, [msgID]);

  const handleChangeMsg = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.checked);
    setAlsoBlockUser(event.target.checked);
  };

  const getOptions = (
    loggedInUser: CometChat.User,
    message: CometChat.BaseMessage,
    theme: CometChatTheme,
    group?: CometChat.Group | undefined,
  ) => {
    let definedOptions = CometChatUIKit.getDataSource().getTextMessageOptions(
      loggedInUser,
      message,
      theme,
      group,
    );
    definedOptions.unshift(createOption(message, theme));
    return definedOptions;
  };
  const reportMessageModal = (msgID) => {
    setMessageID(msgID.id);
    setMessageReported(msgID.text);
    setIsModalOpen(true);
  };
  const reportMessage = () => {
    if (alsoBlockUser) {
      CometChat.deleteMessage(msgID).then(
        (message) => {
          console.log('Message deleted', message);
        },
        (error) => {
          console.log('Message delete failed with error:', error);
        },
      );
    }
    CometChat.callExtension('report-message', 'POST', 'v1/report', {
      msgId: msgID,
      reason: reasonReported,
    })
      .then(async (response) => {
        await Dialog.alert({
          title: 'Message reported',
          message: `Message has been reported to the SP police. Thanks!`,
        }).then(closeModal);
        setIsModalOpen(false);
      })
      .catch(async (error) => {
        if (error.code === 'ERR_NOT_A_RECIVER') {
          await Dialog.alert({
            title: "That's your own message!",
            message: `Want to turn yourself in to the Spiritual Playmates police? Hoping it would be kinky? Sorry, you can't`,
          }).then(closeModal);
        }
        console.log('error', error);
      });
  };

  const createOption = (
    message: CometChat.BaseMessage,
    theme: CometChatTheme,
  ) => {
    let mediaMessage = message as CometChat.MediaMessage;

    return new CometChatActionsIcon({
      id: 'report',
      title: 'Report Message',
      iconURL: asset('/images/report.png'),
      onClick: () => reportMessageModal(mediaMessage),
      iconTint: theme.palette.getAccent600(),
      backgroundColor: 'transparent',
      titleFont: fontHelper(theme.typography.subtitle1),
      titleColor: theme.palette.getAccent600(),
    });
  };
  if (!cometChat.ready || loading) {
    return <Spinner />;
  }
  console.log('comet chat user logged in:', cometChat.getLoggedInUser);

  let messagesStyle = new WithMessagesStyle({
    width: '100%',
    border: '0px',
  });

  let messageHeaderStyle = new MessageHeaderStyle({
    height: '50px',
  });

  let listItemStyle = new MessageListStyle({
    height: '50px',
  });
  let headerConfig = new MessageHeaderConfiguration({
    // messageHeaderStyle,
    // listItemStyle,
  });
  let conversationStyle = new ConversationsStyle({
    width: '100%',
    height: '100%',
    border: '0px',
    boxShadow: 'none',
    titleTextFont: 'normal 16px "Nunito", sans-serif',
    titleTextColor: '#141414',
  });

  //background is neutral-100
  let messageComposerStyle = new MessageComposerStyle({
    background: '#F8F9FB',
    inputBackground: '#ffffff',
    borderRadius: '0px',
    dividerTint: '#F8F9FB',
  });
  // let attachmentOptions = (
  //   item: CometChat.User | CometChat.Group,
  //   composerId: ComposerId,
  // ) => {
  //   return [
  //     new CometChatMessageComposerAction({
  //       background: '#F8F9FB',
  //       titleFont: 'normal 16px "Nunito", sans-serif',
  //     }),
  //   ];
  // };

  const stylesModal = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #eee',
    boxShadow: 24,
    p: 4,
    pb: 10,
  };
  let attachmentOptions = {
    background: '#F8F9FB',
  };

  let messageComposerConfig = new MessageComposerConfiguration({
    messageComposerStyle,
    attachmentIconURL: asset('/images/icon-attachment.svg'),
    // attachmentOptions,
  });
  let messageRequest: any = new CometChat.MessagesRequestBuilder();
  messageRequest.setLimit(3);

  let messageListConfiguation = new MessageListConfiguration({
    // messagesRequestBuilder: messageRequest,
    templates: templates,
    messageListStyle: new MessageListStyle({
      height:
        'calc(100vh - (222px + var(--safe-area-top) + var(--safe-area-bottom)))',
    }),
  });

  let onBack = () => {
    if (showProfile) {
      setShowProfile(false);
    } else {
      setChatWith(null);
    }
  };

  let isUser = chatWith && chatWith.hasOwnProperty('uid');
  let chatProps: any = {};
  if (chatWith) {
    if (isUser) {
      chatProps.user = chatWith;
      messageRequest.setUID(chatWith['uid']);
    } else {
      chatProps.group = chatWith;
      messageRequest.setGUID(chatWith['guid']);
    }
  }
  messageRequest = messageRequest.build();
  const conversationRequest = new CometChat.ConversationsRequestBuilder()
    .setLimit(30)
    .setConversationType('user')
    .build();

  let receiver = chatWith
    ? Person.getFromURI(chatWith.getMetadata().uri, false)
    : null;

  // Attachment Report
  const lStyle = new ListItemStyle({
    titleColor: '#38aecc',
    background: 'transparent',
  });

  const getSubtitleView = (user) => {
    return (
      <span style={{color: '#347fb9', font: '400 11px Inter, sans-serif'}}>
        {user.status}
      </span>
    );
  };

  const uStyle = new UsersStyle({
    width: '500px',
    height: '500px',
    border: '1px solid #96DEDA',
    borderRadius: '25px',
    background: 'linear-gradient(#b6eae1, #D2FBAD)',
    searchBorder: '1px solid #38aecc',
    searchTextColor: '#38aecc',
    searchPlaceholderTextColor: '#38aecc',
    searchIconTint: '#38aecc',
    loadingIconTint: '#38aecc',
    onlineStatusColor: 'yellow',
    emptyStateTextColor: '#96DEDA',
    titleTextColor: '#38aecc',
    separatorColor: '#38aecc',
  });

  const urb = new CometChat.UsersRequestBuilder().setLimit(30).sortBy('status');

  return (
    <PageLayout>
      <div className={style.ChatOverview}>
        {/*<CometChatConversationsWithMessages*/}
        {/*  isMobileView={true}*/}
        {/*  conversationsWithMessagesStyle={style}*/}
        {/*/>*/}
        {chatWith ? (
          <div className={style.ChatConversation}>
            <BackButton
              size={'small'}
              className={style.backButtonChat}
              onClick={onBack}
            />
            {showProfile ? (
              <div className={style.detailPerson}>
                <ProfilePictureAndDetails of={receiver} />
              </div>
            ) : (
              <>
                <div
                  className={style.headerOverlay}
                  onClick={onHeaderClicked}
                />
                <CometChatMessages
                  {...chatProps}
                  messagesStyle={messagesStyle}
                  messageHeaderConfiguration={headerConfig}
                  messageListConfiguration={messageListConfiguation}
                  messageComposerConfiguration={messageComposerConfig}
                />
              </>
            )}
          </div>
        ) : (
          <div className={style.chatPageRoot}>
            <HeaderNavigation pageTitle={chatWith ? '' : 'Messages'} />
            <div className={style.MessageOverview}>
              {/* <div className={style.pageHeaderChat}>Messages</div> */}
              <CometChatConversations
                conversationsStyle={conversationStyle}
                title=""
                onItemClick={(item) => {
                  //it will be a user or a group
                  let conversationWith = item.getConversationWith() as any;
                  setChatWith(conversationWith);
                }}
                listItemView={(conversation: CometChat.Conversation) => {
                  if (conversation.getConversationType() !== 'group') {
                    let user = conversation.getConversationWith() as any;
                    let lastMessage = conversation.getLastMessage() as any;
                    // console.log('lastMe', lastMessage);
                    // console.log('user', user);
                    return (
                      <>
                        <div className={style.chatListUser}>
                          <div
                            className={style.chatInner}
                            onClick={() => setChatWith(user)}
                          >
                            <img
                              src={user.avatar}
                              style={{
                                width: '36px',
                                height: '36px',
                                borderRadius: '50%',
                              }}
                            />
                            <div className={style.chatInnerFlex}>
                              <div>{user.getName()}</div>
                              <div className={style.lastMessage}>
                                {lastMessage.text}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }
                }}
              />
              <FooterMenu />
            </div>
          </div>
        )}
        <Modal
          open={isModalOpen}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={stylesModal}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Report Messages
            </Typography>
            <Typography id="modal-modal-description" sx={{mt: 2, mb: 2}}>
              Do you want to report this message? If so please enter reason for
              the report.
            </Typography>
            <Typography id="modal-modal-description" sx={{mt: 2, mb: 2}}>
              Message being reported:
            </Typography>
            <Input
              type="text"
              placeholder="Reason"
              value={messageReported}
              readOnly={true}
              multiline={true}
              fullWidth={true}
              onChange={(e) => setReasonReported(e.target.value)}
            />
            <Typography id="modal-modal-description" sx={{mt: 2, mb: 2}}>
              Reason:
            </Typography>
            <Input
              className={style.reportedInput}
              type="text"
              placeholder="Reason"
              value={reasonReported}
              multiline={true}
              fullWidth={true}
              onChange={(e) => setReasonReported(e.target.value)}
            />
            {/*<FormControlLabel*/}
            {/*  control={*/}
            {/*    <Checkbox*/}
            {/*      checked={alsoBlockUser}*/}
            {/*      onChange={handleChangeMsg}*/}
            {/*      inputProps={{'aria-label': 'controlled'}}*/}
            {/*    />*/}
            {/*  }*/}
            {/*  label={`Do not show me this again`}*/}
            {/*/>*/}
            <div className={style.reportUserPopup}>
              <Button onClick={closeModal}>Cancel</Button>
              <Button onClick={reportMessage}>Report</Button>
            </div>
          </Box>
        </Modal>
      </div>
    </PageLayout>
  );
};

export default Chat;
