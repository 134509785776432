import React from 'react';
import {default as style} from './Notification.scss.json';
import './Notification.scss';
import {cl} from 'lincd/lib/utils/ClassNames';

interface NotificationProps {
  isActive?;
  className?: string;
}

const Notification = ({isActive, className}: NotificationProps) => {
  return (
    <p
      className={cl(
        isActive ? style.bubble__active : style.bubble__inactive,
        style.bubble,
        className,
      )}
    >
      1
    </p>
  );
};

export default Notification;
