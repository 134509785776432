import React, {useEffect} from 'react';
import {
  CometChatGroupsWithMessages,
  CometChatMessages,
  MessageListConfiguration,
} from '@cometchat/chat-uikit-react';
import {useLocation, useNavigate} from 'react-router-dom';
import {AvatarStyle} from '@cometchat/uikit-elements';
import Spinner from '../components/Spinner';
import BackButton from '../components/BackButton';
import {useCometChat} from 'lincd-cometchat/lib/hooks/useCometChat';
import {
  ConversationsStyle,
  GroupsConfiguration,
  GroupsStyle,
  MessageComposerConfiguration,
  MessageComposerStyle,
  MessageHeaderConfiguration,
  MessageHeaderStyle,
  MessageListStyle,
  MessagesConfiguration,
  WithMessagesStyle,
} from '@cometchat/uikit-shared';
import FooterMenu from '../components/FooterMenu';
import {Button} from '@mui/material';
import './Community.scss';
import {CometChatMessageTemplate} from '@cometchat/uikit-resources';
import style from './Community.scss.json';
import {CometChat} from '@cometchat/chat-sdk-javascript';
import HeaderNavigation from '../components/layout/HeaderNavigation';
import PageLayout from '../components/layout/PageLayout';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';

const Chat = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [groupJoined, setGroupJoined] = React.useState<any>(null); //CometChat.Group
  const [groupMember, setGroupMember] = React.useState<any>();
  const [chatIsOpen, setChatOpen] = React.useState<boolean>(false);
  const [sourceConversation, setSourceConversation] = React.useState<any>();
  const [groupDetails, setGroupDetails] = React.useState<boolean>(false);
  let cometChat = useCometChat();
  let [chatWith, setChatWith] = React.useState<any>();
  let [showProfile, setShowProfile] = React.useState<boolean>();
  let [loading, setLoading] = React.useState<boolean>(false);
  const [templates, setTemplates] = React.useState<CometChatMessageTemplate[]>(
    [],
  );
  let stateURI = location?.state?.uri;
  let stateGroup = location?.state?.uriGroup;

  const onHeaderClicked = (e: any) => {
    setShowProfile(true);
  };

  useEffect(() => {
    const elements = document.querySelector('.cc-list__content');
    if (elements instanceof HTMLElement) {
      elements.style.padding = '5px';
    }
  }, [groupMember, groupJoined]);

  useEffect(() => {
    const fixOverheight = document.querySelector('.cc-messages-wrapper');
    if (fixOverheight instanceof HTMLElement) {
      fixOverheight.style.overflow = 'hidden';
    }

    const fixHeightHeaderMsg = document.querySelector('.cc-message-header');
    if (fixHeightHeaderMsg instanceof HTMLElement) {
      fixHeightHeaderMsg.style.margin = '10px 0px 0px 40px';
    }

    const fixMessageList = document.querySelector(
      '.cc-message-header__wrapper',
    );
    if (fixMessageList instanceof HTMLElement) {
      fixMessageList.style.background = '#FFFFFF';
      fixMessageList.style.position = 'fixed';
      fixMessageList.style.height = '80px';
      fixMessageList.style.zIndex = '1';
    }

    const fixMessageHeader = document.querySelector('.cc-messages__header');
    if (fixMessageHeader instanceof HTMLElement) {
      fixMessageHeader.style.height = '100px';
    }

    const fixComposerSinking = document.querySelector('.cc-messages');
    if (fixComposerSinking instanceof HTMLElement) {
      fixComposerSinking.style.height = '100vh';
    }

    const fixMessagingList = document.querySelector('.cc-messages__list');
    if (fixMessagingList instanceof HTMLElement) {
      fixMessagingList.style.paddingTop = '75px'; // height from header
      fixMessagingList.style.paddingBottom = '40px';
    }
    const fixComposerLayout = document.querySelector('.cc-messages__composer');
    if (fixComposerLayout instanceof HTMLElement) {
      fixComposerLayout.style.display = 'block';
      fixComposerLayout.style.zIndex = '1';
    }
  }, [groupJoined]);

  if (!cometChat.ready || loading) {
    return <Spinner />;
  }

  const onBackGroup = () => {
    setGroupDetails(false);
    setGroupJoined(false);
  };

  let messagesStyle = new WithMessagesStyle({
    width: '100%',
    border: '0px',
  });
  let messageHeaderStyle = new MessageHeaderStyle({
    height: '70px',
    width: '100%',
    background: '#FFFFFF',
  });
  let listItemStyle = new MessageListStyle({
    height: '50px',
  });
  let headerConfig = new MessageHeaderConfiguration({
    menu: [''],
    hideBackButton: true,
    onBack: onBackGroup,
    messageHeaderStyle,
  });
  let conversationStyle = new ConversationsStyle({
    width: '100%',
    height: '100%',
    border: '0px',
    boxShadow: 'none',
    titleTextFont: 'normal 16px "Nunito", sans-serif',
    titleTextColor: '#141414',
  });

  //background is neutral-100
  let messageComposerStyle = new MessageComposerStyle({
    background: '#F8F9FB',
    inputBackground: '#ffffff',
    borderRadius: '0px',
    dividerTint: '#F8F9FB',
  });
  // let attachmentOptions = (
  //   item: CometChat.User | CometChat.Group,
  //   composerId: ComposerId,
  // ) => {
  //   return [
  //     new CometChatMessageComposerAction({
  //       background: '#F8F9FB',
  //       titleFont: 'normal 16px "Nunito", sans-serif',
  //     }),
  //   ];
  // };
  let attachmentOptions = {
    background: '#F8F9FB',
  };

  let messageComposerConfig = new MessageComposerConfiguration({
    messageComposerStyle,
    attachmentIconURL: asset('/images/icon-attachment.svg'),
    // attachmentOptions,
  });

  let messageRequest: any = new CometChat.ConversationsRequestBuilder();
  messageRequest.setLimit(3);
  messageRequest.setConversationType('group');

  let onBack = () => {
    setGroupJoined(null);
  };

  const gStyle = new GroupsStyle({
    background: 'none',
    searchBorder: '1px solid #38aecc',
    searchTextColor: '#38aecc',
    searchPlaceholderTextColor: '#38aecc',
    searchIconTint: '#38aecc',
    loadingIconTint: '#38aecc',
    emptyStateTextColor: '#96DEDA',
    titleTextColor: '#222',
    separatorColor: '#38aecc',
  });

  const getSubtitleView = (group) => {
    // customize list group with adjustable members count
    setGroupMember(group);
    return (
      <>
        <span
          style={{
            color: '#A5ACBA',
            font: '400 12px Roboto, sans-serif',
          }}
        >
          {group.description}
        </span>
        <div className={style.memberGroupCount}>
          <img src={asset('/images/Man.svg')} alt="members" />
          <h4>{group.membersCount}</h4>
        </div>
      </>
    );
  };

  type CometChatOption = {
    title?: string;
    id?: string;
  };

  const groupConfiguration = new GroupsConfiguration({
    subtitleView: getSubtitleView,
    groupsStyle: gStyle,
    avatarStyle: new AvatarStyle({
      width: '30px',
      height: '50px',
      borderRadius: '50%',
    }),
    menu: [''],
    titleAlignment: 1,
    onItemClick: (conversation) => {
      if (conversation.getHasJoined() === true) {
        setGroupDetails(false);
      } else {
        setGroupDetails(true);
      }
      setGroupJoined(conversation);
      setSourceConversation(conversation);
    },
    hideSeparator: true,
    listItemStyle: new MessageListStyle({
      height: '75px',
      border: '1px solid #FFFFFF',
    }),
    hideSearch: true,
  });

  const groupStyle = new WithMessagesStyle({
    height: '100vh',
    border: '1px solid #FFFFFF',
    background: '#FFFFFF',
    messageTextColor: '#38aecc',
  });

  const messagesConfig = new MessagesConfiguration({
    hideMessageComposer: false,
    disableTyping: false,
    messageComposerView: null, // or your custom view
    messageListView: null, // or your custom view
    hideMessageHeader: false,
  });

  let messageListConfiguation = new MessageListConfiguration({
    // messagesRequestBuilder: messageRequest,
    templates: templates,
    messageListStyle: new MessageListStyle({
      height: 'calc(100vh - 215px)',
    }),
  });

  return (
    <PageLayout className={style.CommunityPage}>
      {groupDetails ? (
        <>
          <BackButton size={'small'} onClick={onBackGroup} />
          <div className={style.communityDetailsContainer}>
            <img src={groupJoined?.icon} className={style.imgCommunity} />
            <div className={style.onlineStatus}></div>
            <h3>{groupJoined?.name}</h3>
            <div className={style.flexSide}>
              <img src={asset('/images/Man.svg')} />
              <p>{groupJoined?.membersCount} Members</p>
            </div>
            <p className={style.textCommunity}>{groupJoined?.description}</p>
            {/* <div className={style.communityRules}>
                  <p className={style.headline}>Community Rules</p>
                  <div className={style.innerCommunityRules}>
                    <ul>
                      <li>Be respectful</li>
                      <li>No hate crime</li>
                      <li>Say hi!</li>
                      <li>Wear pink always</li>
                    </ul>
                  </div>
                </div> */}
            <Button
              variant="contained"
              className={style.btnJoin}
              onClick={() => {
                var password = '';
                CometChat.joinGroup(
                  groupJoined.getGuid(),
                  CometChat.GroupType.Public,
                  password,
                ).then(
                  (group) => {
                    console.log('Joined group successfully:', group);
                    setGroupDetails(false);
                    setGroupJoined(sourceConversation);
                    // Now, you are in the group chat room
                  },
                  (error) => {
                    console.error('Error joining group:', error);
                    setGroupDetails(false);
                    setGroupJoined(sourceConversation);
                  },
                );
              }}
            >
              Join
            </Button>
          </div>
        </>
      ) : !groupJoined ? (
        <div className={style.CommunityOverview}>
          <HeaderNavigation pageTitle={'Community'} />
          <div className={style.communityWrapper}>
            <CometChatGroupsWithMessages
              isMobileView={true}
              groupsConfiguration={groupConfiguration}
              groupsWithMessagesStyle={groupStyle}
              messageText="Select a group to start messaging"
              messagesConfiguration={messagesConfig}
            />
          </div>
        </div>
      ) : (
        <div className={style.SpecificGroupChat}>
          <BackButton
            size={'small'}
            onClick={onBackGroup}
            className={style.backCommunityChat}
          />
          <CometChatMessages
            group={groupJoined}
            messagesStyle={messagesStyle}
            messageHeaderConfiguration={headerConfig}
            messageListConfiguration={messageListConfiguation}
            messageComposerConfiguration={messageComposerConfig}
          />
        </div>
      )}
    </PageLayout>
  );
};

export default Chat;
