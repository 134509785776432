import './CustomizedSwitch.scss';
import * as React from 'react';
import clsx from 'clsx';
import {styled} from '@mui/system';
import {useSwitch} from '@mui/base/useSwitch';

interface CustomizedSwitchProps {
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  className?: any;
}

interface SwitchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onChange?: (e: any) => void;
}

export default function UseSwitchesBasic({onChange, ...props}: SwitchProps) {
  return (
    <div>
      <BasicSwitch onChange={onChange} {...props}></BasicSwitch>
    </div>
  );
}

function BasicSwitch(props) {
  const {getInputProps, disabled, focusVisible, checked} = useSwitch(props);

  const stateClasses = {
    'Switch-checked': checked,
    'Switch-disabled': disabled,
    'Switch-focusVisible': focusVisible,
  };

  return (
    <BasicSwitchRoot className={clsx(stateClasses)}>
      <BasicSwitchThumb className={clsx(stateClasses)} />
      <BasicSwitchInput {...getInputProps()} aria-label="Demo switch" />
    </BasicSwitchRoot>
  );
}

const BasicSwitchRoot = styled('span')(
  ({theme}) => `
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 49px;
  height: 28px;
  margin: 10px;
  cursor: pointer;
  border-radius: 16px;
  background: #BFC7CF;

  &.Switch-disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.Switch-checked {
    background: var(--brand-secondary, #4DDAD7);
  }
  `,
);

const BasicSwitchInput = styled('input')`
  cursor: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
`;

const BasicSwitchThumb = styled('span')`
  display: block;
  width: 25px;
  height: 25px;
  top: 1px;
  left: 2px;
  border-radius: 16px;
  background-color: #fff;
  position: relative;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &.Switch-focusVisible {
    background-color: #bfc7cf;
    box-shadow: 0 0 1px 8px rgb(0 0 0 / 0.25);
  }

  &.Switch-checked {
    transform: translateX(20px);
    background-color: #fff;
  }
`;
