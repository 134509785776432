import {cl} from 'lincd/lib/utils/ClassNames';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';
import React, {useEffect, useState} from 'react';
import './SwipeTourBasic.scss';
import {default as style} from './SwipeTourBasic.scss.json';

import Joyride, {
  ACTIONS,
  EVENTS,
  ORIGIN,
  STATUS,
  CallBackProps,
} from 'react-joyride';
import {Icons} from './ui/Icons';
import {Person} from 'foaf/lib/shapes/Person';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {PaidAccountTier1} from 'lincd-dating/lib/shapes/PaidAccountTier1';

interface SwipeTourProps {
  runSwipeTour: boolean;
  onRunSwipeTour?: (data: boolean) => void;
}

export const SwipeTourBasic = ({
  runSwipeTour,
  onRunSwipeTour,
}: SwipeTourProps) => {
  const auth = useAuth<Person, FreeAccount | PaidAccountTier1>();
  const userAccount = auth.userAccount;

  const steps = [
    {
      target: '.tourSwipeUp',
      content: (
        <div className={cl(style.root, style.swipeUp)}>
          <Icons.TourSwipeUp />
          <p className={style.swipeUpText}>
            Swipe up, or press the middle button to read more about this person
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: 'top' as any,
      styles: {
        options: {
          width: '300px',
        },
      },
    },
    {
      target: '.tourTapLeft',
      content: (
        <div className={cl(style.root, style.tap, style.swipeUp)}>
          <Icons.TourTapLeft />
          <p className={style.tapText}>
            Tap the left side to go back through photos
          </p>
        </div>
      ),
      disableBeacon: true,
      // placement: 'right' as any,
      styles: {
        options: {
          width: '200px',
        },
      },
    },
    {
      target: '.tourTapRight',
      content: (
        <div className={cl(style.root, style.tap, style.swipeUp)}>
          <Icons.TourTapRight />
          <p className={style.tapText}>
            Tap the right side to go to the next photo
          </p>
        </div>
      ),
      disableBeacon: true,
      // placement: 'left' as any,
      styles: {
        options: {
          width: '200px',
          // backgroundColor: 'red',
        },
      },
    },
    {
      target: '.tourSwipeLeft',
      content: (
        <div className={cl(style.root, style.swipeLeft)}>
          <Icons.TourSwipeLeft />
          <div className={cl(style.swipeText, style.swipeLeftText)}>
            <p>To skip, swipe left. </p>
            <p>Or click the X button</p>
          </div>
        </div>
      ),
      disableBeacon: true,
      placement: 'top' as any,
      styles: {
        options: {
          width: '250px',
        },
      },
    },
    {
      target: '.tourSwipeRight',
      content: (
        <div className={cl(style.root, style.swipeRight)}>
          <Icons.TourSwipeRight />
          <div className={cl(style.swipeText, style.swipeRightText)}>
            <p>If you like, swipe right.</p>
            <p>Or click the heart button</p>
          </div>
        </div>
      ),
      disableBeacon: true,
      placement: 'top' as any,
      styles: {
        options: {
          width: '250px',
        },
      },
    },
    {
      target: '.tourRewind',
      content: (
        <div className={cl(style.root, style.swipeRewind)}>
          <p className={style.swipeRewindText}>
            Use rewind to undo your last swipe
          </p>
          <Icons.TourRewind />
        </div>
      ),
      disableBeacon: true,
      placement: 'top' as any,
      styles: {
        options: {
          width: '200px',
        },
      },
    },
    {
      target: '.tourBoost',
      content: (
        <div className={cl(style.root, style.swipeBoost)}>
          <div className={style.swipeBoostText}>
            <p>Boost your ego profile for extra visibility.</p>
            <p>
              Or use a Quantum Like to increase your chances of manifesting a
              match
            </p>
          </div>
          <Icons.TourBoost />
        </div>
      ),
      disableBeacon: true,
      placement: 'top' as any,
      styles: {
        options: {
          width: '250px',
        },
      },
    },
    {
      target: '.tourSearch',
      content: (
        <div className={cl(style.root, style.swipeRight)}>
          <Icons.TourSeach />
          <p>Change your search here</p>
        </div>
      ),
      disableBeacon: true,
      placement: 'bottom' as any,
      styles: {
        options: {
          width: '250px',
        },
      },
    },
    {
      target: 'body',
      content: (
        <div className={cl(style.root, style.swipeUp)}>
          <p>Happy swiping!</p>
          <p>May you find the perfect match 🙏</p>
        </div>
      ),
      disableBeacon: true,
      placement: 'center' as any,
      styles: {
        options: {
          // width: '200px',
        },
      },
    },
  ];

  const [stepIndex, setStepIndex] = useState(0);

  const handleJoyrideCallback = (data: any) => {
    const {action, index, origin, status, type} = data;

    const hasSpotlight = document.querySelector<HTMLElement>(
      '.react-joyride__spotlight',
    );

    if (hasSpotlight) {
      const style = hasSpotlight.style;
      switch (index) {
        case 0: //swipe up
          style.clipPath =
            'polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)';
          break;
        case 1:
          style.backgroundColor = 'transparent';
          style.clipPath = 'none';
          break;
        case 3:
          style.backgroundColor = 'gray';
          style.borderRadius = '9999px';
          break;
      }
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // after tour is finished, update user's onboarded status
      userAccount.onboarded = true;

      if (onRunSwipeTour) {
        onRunSwipeTour(false);
      }
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      run={runSwipeTour}
      stepIndex={stepIndex}
      steps={steps}
      continuous
      // showProgress
      // showSkipButton
      hideCloseButton
      hideBackButton
      // spotlightClicks={true}
      // disableOverlayClose={true}
      spotlightPadding={0}
      styles={{
        options: {
          primaryColor: '#4DDAD7',
          textColor: 'white',
          arrowColor: 'transparent',
          backgroundColor: 'transparent',
          zIndex: 10000,
        },
        buttonNext: {
          display: 'none',
        },
      }}
    />
  );
};
