import './BackButton.scss';
import style from './BackButton.scss.json';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import {cl} from 'lincd/lib/utils/ClassNames';
import {useNavigate} from 'react-router-dom';

interface BackButtonProps {
  text?: any;
  onClick?: () => void;
  size?: 'small' | 'medium';
  relative?: boolean;
  className?: string;
}

const BackButton = ({
  text,
  onClick,
  className,
  size = 'medium',
  relative,
}: BackButtonProps) => {
  let navigate = useNavigate();
  return (
    <div
      className={cl(
        style.BackButton,
        style[size],
        relative && style.relative,
        className,
      )}
    >
      <IconButton
        className={style.chevronBack}
        aria-label="ChevronLeft"
        size="large"
        onClick={() => (onClick ? onClick() : navigate(-1))}
      >
        <ChevronLeft fontSize="inherit" />
      </IconButton>
      {text}
    </div>
  );
};

export default BackButton;
