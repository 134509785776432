import React from 'react';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {default as styles} from './ProfileCard.scss.json';
import './ProfileCard.scss';
import {Box} from '../components/ui/forms/Box';
import {Typography} from '../components/ui/forms/Typography';
import {cl} from 'lincd/lib/utils/ClassNames';
import {
  capitalizeEveryFirstLetter,
  capitalizeFirstLetter,
} from '../utils/LetterCase';
import {linkedComponent} from '../package';
import {ProfilePicturesClickthrough} from 'profile-pics/lib/components/ProfilePicturesClickthrough';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';

interface ProfileCardProps extends React.ComponentPropsWithRef<any> {
  pendingDetails?: boolean;
  pendingLike?: boolean;
  pendingDislike?: boolean;
  matchScore?: number;
  isDragging?: boolean;
  clickThroughPhotos?: boolean;
  hasSuperLike?: boolean;
  className?: string;
  textElevate?: boolean;
}

// const ProfileCard = React.forwardRef(
const ProfileCard = linkedComponent<Person, ProfileCardProps>(
  Person.request((person) => {
    return {
      givenName: person.givenName,
      age: person.age,
      gender: person.gender,
      hasOccupation: person.hasOccupation,
    };
  }),
  React.forwardRef(
    (
      {
        source,
        linkedData,
        pendingLike,
        pendingDislike,
        pendingDetails,
        matchScore: number = null,
        isDragging = false,
        clickThroughPhotos,
        hasSuperLike,
        className = '',
        onClick,
        textElevate = false,
      },
      ref: any,
    ) => {
      let person: Person = source;
      let personAccount: FreeAccount = FreeAccount.getAccountOf(person as any);
      let {givenName, age, gender, hasOccupation} = linkedData;

      return (
        <div className={cl(styles.ProfileCard, className)} ref={ref}>
          <Box className={styles.ProfileCardImageContainer}>
            <ProfilePicturesClickthrough
              of={person}
              clickThroughPhotos={clickThroughPhotos}
              onClick={onClick}
            />
            <div
              className={cl(
                styles.stamp,
                styles.likeStamp,
                pendingLike && styles.active,
              )}
            >
              LIKE
            </div>
            <div
              className={cl(
                styles.stamp,
                styles.dislikeStamp,
                pendingDislike && styles.active,
              )}
            >
              SKIP
            </div>
            <div
              className={cl(
                styles.stamp,
                styles.detailsStamp,
                pendingDetails && styles.active,
              )}
            >
              DETAILS
            </div>
          </Box>
          <Box
            className={cl(
              styles.ProfileCardTextContainer,
              textElevate && styles.textElevate,
            )}
          >
            <div className={styles.titleWrap}>
              {hasSuperLike && (
                <img src={asset('/images/union.svg')} alt="super like" />
              )}
              <Typography variant="h5" component="h5">
                {capitalizeEveryFirstLetter(givenName)}
                {age ? ', ' + age : ''}
              </Typography>
            </div>
            {gender && personAccount.displayGender && (
              <Box className={cl(styles.MainProfileIcon, styles.genderIcon)}>
                <img src={asset('/images/gender-intersex.svg')} alt="gender" />
                <Typography variant="body1">
                  {capitalizeFirstLetter(gender)}
                </Typography>
              </Box>
            )}
            {/*<Box className={style.MainProfileIcon}>*/}
            {/*  <img src='/images/GraduationIcon.svg' alt='graduation-icon' />*/}
            {/*  <Typography variant='body1'>MIT 2009</Typography></Box>*/}
            {hasOccupation && (
              <Box
                className={cl(styles.MainProfileIcon, styles.occupationIcon)}
              >
                <img
                  src={asset('/images/OccupationIcon.svg')}
                  alt="occupation-icon"
                />
                <Typography variant="body1">
                  {capitalizeFirstLetter(hasOccupation)}
                </Typography>
              </Box>
            )}
          </Box>
        </div>
      );
    },
  ),
);

export default ProfileCard;
