import React from 'react';
import {useNavigate} from 'react-router-dom';
import {QuestionHeader} from '../components/QuestionHeader';
import ContinueButton from '../components/ContinueButton';
import {default as style} from './Q2_Photo.scss.json';
import './Q2_Photo.scss';
import {ROUTES} from '../routes/AppRoutes';
import {QuestionContainer} from '../components/QuestionContainer';
import {ProfilePictureUploader} from 'profile-plus/lib/components/ProfilePictureUploader';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Person} from 'lincd-dating/lib/shapes/Person';
import {FreeAccount} from 'lincd-dating/lib/shapes/FreeAccount';

const Q2_Photo = () => {
  const navigate = useNavigate();
  const auth = useAuth<Person, FreeAccount>();
  const [helpertext, setHelpertext] = React.useState<string>('');
  const onContinue = () => {
    if (auth.user.profilePicture) {
      navigate(ROUTES.q2_photos.path);
    } else {
      setHelpertext('Please add your first picture to continue');
    }
  };
  return (
    <div className={style.PhotoQuestion}>
      <QuestionContainer>
        <QuestionHeader>Add your first photo</QuestionHeader>
        <div className={style.uploader}>
          <ProfilePictureUploader
            aspectRatio={3 / 5}
            thumbnailWidth={400}
            confirmText={'Set profile picture'}
            onUpdate={(image) => {
              // remove helper text if user has uploaded a photo
              if (image) {
                setHelpertext('');
              }
            }}
          />
        </div>
        {helpertext && <div className={style.helpertext}>{helpertext}</div>}
      </QuestionContainer>
      <ContinueButton
        // text={'You can add more photos later'}
        onClick={onContinue}
      />
    </div>
  );
};

export default Q2_Photo;
