import React, {useEffect, useMemo, useRef, useState} from 'react';
import style from './Users.scss.json';
import './Users.scss';
import {TableAccount} from '../components/TableAccount';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {packageName} from '../package';
import {Button} from 'lincd-mui-base/lib/components/Button';
import {cl} from 'lincd/lib/utils/ClassNames';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {UserProfileInformation} from '../components/UserProfileInformation';
import {getAccountSlug} from '../utils/helper';
import {generatePath, Link} from 'react-router-dom';
import {ROUTES} from '../routes/AppRoutes';
import {useDebounce} from 'use-debounce';

export default function UsersPage() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDuplicate, setShowDuplicate] = useState(false);

  const [userFrom, setUserFrom] = useState<any>(null);
  const [userTo, setUserTo] = useState<any>(null);

  // merge accounts
  const [mergeAccounts, setMergeAccounts] = useState<any[]>([]);
  const [mergeLoading, setMergeLoading] = useState(false);
  const [mergeAccount, setMergeAccount] = useState<any>(null);

  // filters
  const [filter, setFilter] = useState('');
  const [debouncedFilter] = useDebounce(filter, 1000);

  const mergeRef = useRef(null);

  // get teams data
  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const handleUserData = () => {
      // set userFrom from mergeAccounts
      if (mergeAccounts.length === 1) {
        const userFrom = mergeAccounts[0];
        const updateUser = formatUser(userFrom);
        setUserFrom(updateUser);
      }

      // set userTo from mergeAccounts
      if (mergeAccounts.length === 2) {
        const userTo = mergeAccounts[1];
        const updateUser = formatUser(userTo);
        setUserTo(updateUser);
      } else {
        setUserTo(null);
      }
    };

    // update userTo and userFrom when table account is updated
    // this will effect to autocomplete field
    handleUserData();
  }, [mergeAccounts]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const usersData = await Server.call(packageName, 'getUsers');
      setUsers(usersData);
    } catch (error) {
      console.error('failed to fetch users:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUser = async (accountId: string) => {
    setLoading(true);
    try {
      const response = await Server.call(packageName, 'getUser', accountId);
      return response;
    } catch (error) {
      console.error('failed to fetch user:', error);
    } finally {
      setLoading(false);
    }
  };

  // sort users by email and skip if email is empty
  // example:
  // from: rene@semantu.com, abdi@semantu.com, carlen@semantu.com, ..., abdi@semantu.com
  // to: abdi@semantu.com, abdi@semantu.com, rene@semantu.com, carlen@semantu.com,...
  const sortUsersByEmail = () => {
    const sortedUsers = users
      .filter((user) => user.email) // skip users with empty emails
      .sort((a, b) => a.email.localeCompare(b.email)); // sort by email
    setUsers(sortedUsers);
  };

  // sort users by duplicate email and skip if email is empty
  // example:
  // from: rene@semantu.com, abdi@semantu.com, carlen@semantu.com, ..., abdi@semantu.com
  // to: abdi@semantu.com, abdi@semantu.com
  const sortUsersByDuplicateEmail = () => {
    setLoading(true);
    const emailCount = new Map();

    // Count occurrences of each email
    users.forEach((user) => {
      if (user.email) {
        emailCount.set(user.email, (emailCount.get(user.email) || 0) + 1);
      }
    });

    // Filter users with duplicate emails
    const duplicateEmailUsers = users.filter(
      (user) => user.email && emailCount.get(user.email) > 1,
    );

    // Sort by email
    const sortedUsers = duplicateEmailUsers.sort((a, b) =>
      a.email.localeCompare(b.email),
    );

    setUsers(sortedUsers);
    setLoading(false);
  };

  const onGenerateIds = async () => {
    setLoading(true);
    try {
      const response = await Server.call(packageName, 'generateIds');
      if (response) {
        fetchUsers();
      }
    } catch (error) {
      console.error('failed to cleanup account:', error);
    } finally {
      setLoading(false);
    }
  };

  // scroll to merge section
  const goToMergeSection = () => {
    mergeRef.current.scrollIntoView({behavior: 'smooth', block: 'end'});
  };

  // handle merge account when user click the table row
  const onHandleMergeAccount = async (accounts: any[]) => {
    setMergeAccounts(accounts);
  };

  const formatUser = (user: any) => {
    const accountId = user?.id;

    return {
      label: `${accountId} - ${user.givenName} ${user.familyName || ''}`,
      value: user.accountUri,
    };
  };

  // mapping users data to options
  const options = useMemo(() => {
    return users.map((user) => formatUser(user));
  }, [users]);

  const onChangeUser = (
    event: any,
    value: any,
    reason: any,
    type: 'userFrom' | 'userTo',
  ) => {
    if (reason === 'clear') {
      if (type === 'userFrom') {
        setUserFrom(null);
      } else {
        setUserTo(null);
      }
      return;
    }

    // get option from value
    const getOption = options.find((option) => option.value === value.value);

    if (type === 'userFrom') {
      setUserFrom(getOption);
    } else {
      setUserTo(getOption);
    }
  };

  // toggle show duplicate users
  const toggleShowDuplicates = () => {
    // toggle show duplicate users
    if (showDuplicate) {
      fetchUsers();
    } else {
      // show only duplicate users
      sortUsersByDuplicateEmail();
    }
    setShowDuplicate(!showDuplicate);
  };

  // merge accounts into selected account
  const onMergeAccount = async (event) => {
    event.preventDefault();
    setMergeLoading(true);

    const formData = new FormData(event.target);
    const selectedAccount = formData.get('selectedAccount') as string;

    if (!selectedAccount) {
      alert('Please choose the primary account');
      setMergeLoading(false);
      return;
    }

    // get accounts uri
    const accountUris = mergeAccounts.map((account) => account.accountUri);

    try {
      const response = await Server.call(
        packageName,
        'mergeAccount',
        accountUris,
        selectedAccount,
      );

      if (response.error) {
        console.error('merge error:', response.error);
        alert(response.error);
      }

      setMergeAccount(response.data);
    } catch (error) {
      alert(`An unexpected error occurred: ${error.message}`);
    } finally {
      setMergeLoading(false);
    }
  };

  const onReload = () => {
    window.location.reload();
  };

  // handle search change
  const onHandleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value.toLowerCase());
  };

  // filter users by name, email, or id from search field
  const filteredUsers = useMemo(() => {
    return users.filter((user) => {
      const isMatched = [
        user.givenName,
        user.familyName,
        user.email,
        user.id,
      ].some((field) => field?.toLowerCase().includes(debouncedFilter));

      return isMatched;
    });
  }, [debouncedFilter, users]);

  return (
    <div className={style.root}>
      <div style={{textAlign: 'center'}}>
        <h1>User List</h1>
      </div>
      {loading ? (
        <div style={{textAlign: 'center'}}>Loading...</div>
      ) : (
        <>
          <div className={style.wrapper}>
            <div>
              <TextField
                label="Search by name, email, or ID"
                variant="outlined"
                size="small"
                fullWidth
                onChange={onHandleSearchChange}
                style={{minWidth: '300px'}}
              />
            </div>
            <div style={{display: 'flex', gap: '1rem'}}>
              <Button color="secondary" onClick={toggleShowDuplicates}>
                {showDuplicate ? `Hide` : `Show`} Duplicate Accounts
              </Button>
              <Button color="secondary" onClick={onGenerateIds}>
                Generate Ids
              </Button>
            </div>
          </div>
          <TableAccount
            data={filteredUsers}
            onSelectionChange={onHandleMergeAccount}
          />
          {options && mergeAccounts.length > 0 && (
            <>
              <div ref={mergeRef} style={{padding: '2rem'}}>
                <h4 style={{textAlign: 'center', marginBottom: '1.5rem'}}>
                  Merge Section
                </h4>
                <div className={style.selectRow}>
                  <div className={style.selectColumn}>
                    <Autocomplete
                      fullWidth
                      size="small"
                      disablePortal
                      id="userFrom"
                      options={options}
                      disableClearable
                      renderInput={(params) => (
                        <TextField {...params} label={`Account 1`} fullWidth />
                      )}
                      isOptionEqualToValue={
                        // auto select when userFrom is selected from table
                        // or select from option manually
                        (option, value) =>
                          option.value === value.value ||
                          option.value === userFrom?.value
                      }
                      value={userFrom}
                      onChange={(e, value, reason) =>
                        onChangeUser(e, value, reason, 'userFrom')
                      }
                    />
                    {userFrom && (
                      <div style={{marginTop: '1rem'}}>
                        <UserProfileInformation
                          accountUri={getAccountSlug(userFrom.value)}
                        />
                      </div>
                    )}
                  </div>
                  <div className={style.selectColumn}>
                    <Autocomplete
                      fullWidth
                      size="small"
                      disablePortal
                      id="userTo"
                      options={options}
                      disableClearable
                      renderInput={(params) => (
                        <TextField {...params} label={`Account 2`} fullWidth />
                      )}
                      isOptionEqualToValue={
                        // auto select when userTo is selected from table
                        // or select from option manually
                        (option, value) =>
                          option.value === value.value ||
                          option.value === userTo?.value
                      }
                      value={userTo}
                      onChange={(e, value, reason) =>
                        onChangeUser(e, value, reason, 'userTo')
                      }
                    />
                    {userTo && (
                      <div style={{marginTop: '1rem'}}>
                        <UserProfileInformation
                          accountUri={getAccountSlug(userTo.value)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div style={{textAlign: 'center', paddingBottom: '2rem'}}>
                <h4>Choose Primary Account</h4>
                <form onSubmit={onMergeAccount}>
                  {mergeAccounts.map((account, index) => {
                    const user = formatUser(account);
                    const value = getAccountSlug(user.value);
                    // console.log('user:', user);
                    return (
                      <p key={account.value}>
                        <label>
                          <input
                            type="radio"
                            name={'selectedAccount'}
                            value={value}
                          />
                          {user.label}
                        </label>
                      </p>
                    );
                  })}
                  <Button type="submit" disabled={mergeLoading || mergeAccount}>
                    Merge Account {mergeLoading ? '...' : ''}
                  </Button>
                </form>
              </div>
            </>
          )}

          {mergeAccount && !mergeLoading && (
            <>
              <div className={style.mergeSuccess}>
                <h4>
                  Merge account to {mergeAccount.id} -{' '}
                  {mergeAccount?.accountOf.givenName}{' '}
                  {mergeAccount?.accountOf.familyName || ''} is success
                </h4>
                <p>
                  Show detail account{' '}
                  <Link
                    target="_blank"
                    to={generatePath(ROUTES.user.path, {
                      accountUri: getAccountSlug(mergeAccount.uri),
                    })}
                  >
                    here
                  </Link>
                </p>
              </div>
              <div style={{textAlign: 'center', paddingBottom: '2rem'}}>
                <Button onClick={onReload}>Reload</Button>
              </div>
            </>
          )}
          <div
            className={cl(
              style.toMergeSection,
              mergeAccounts.length === 2 ? style.show : '',
            )}
          >
            <Button variant="outlined" onClick={goToMergeSection}>
              Go to Merge Section
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
